module.exports = {
  ADOLOPMENT_DATA: 'adolopment_data',
  COMPARISONS_QUESTION_GROUPS: 'comparisons_question_groups',
  DOCUMENT_SECTIONS: 'document_sections',
  DOCUMENT_SECTIONS_FROM_TEMPLATE: 'document_sections_from_template',
  OUTCOMES_GENERATION: 'outcomes_generation',
  PERSONAL_LICENSE_LIMITS: 'personal_license_limits',
  DBEP_PUBLICATION_STATUS: 'dbep_publication_status',
  PROJECT_CHECKLIST: 'project_checklist',
  GENERAL_INFORMATION: 'general_information',
  DOCUMENT_SECTIONS_V2_ROOT_ID: 'document_sections_v2',
  PUBLICATION_PLATFORM_PUBLICATION_STATUS: 'publication_platform_publication_status',
  ALGORITHMS: 'algorithms'
};
