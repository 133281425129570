var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.members : depth0), {"name":"if","hash":{},"fn":this.program(2, data, depths),"inverse":this.program(16, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <div class=\"coi-options\">\n        <div class=\"row\">\n          <div class=\"col-6\">\n            <div class=\"form-type\">\n                "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "form_type.select", {"name":"i18n","hash":{},"data":data})))
    + "\n            </div>\n            <div class=\"form-type\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.coiFormTypes : depth0), {"name":"each","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </div>\n          </div>\n          <div class=\"col-6\">\n            <div class=\"row\">\n              <div class=\"col-4\">\n                <a\n                    class=\"open-send-notifications-modal btn btn-block";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.sendDisabled : depth0), {"name":"if","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.sendDisabled : depth0), {"name":"if","hash":{},"fn":this.program(10, data, depths),"inverse":this.program(12, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                >\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "send", {"name":"i18n","hash":{},"data":data})))
    + "\n                </a>\n              </div>\n              <div class=\"col-4\">\n                <a\n                    class=\"send-reminder-mail btn btn-block";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.sendDisabled : depth0), {"name":"if","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.sendDisabled : depth0), {"name":"if","hash":{},"fn":this.program(10, data, depths),"inverse":this.program(14, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                >\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "send_reminder", {"name":"i18n","hash":{},"data":data})))
    + "\n                </a>\n              </div>\n              <div class=\"col-3 col-offset-1\">\n                <button\n                    class=\"refresh-statuses btn btn-block btn-refresh\"\n                    title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "refresh_desc", {"name":"i18n","hash":{},"data":data})))
    + "\"\n                >\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "refresh", {"name":"i18n","hash":{},"data":data})))
    + "\n                </button>\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class=\"tabs-container\"></div>\n";
},"3":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "                  <label\n";
  stack1 = helpers['if'].call(depth0, (depths[2] != null ? depths[2].coiFormCreated : depths[2]), {"name":"if","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n                    <input type=\"radio\" name=\"formType\" value=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\"\n";
  stack1 = helpers['if'].call(depth0, (depths[2] != null ? depths[2].coiFormCreated : depths[2]), {"name":"if","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    >\n                    <span class=\"type-name\">"
    + escapeExpression(lambda(depth0, depth0))
    + "</span>\n                  </label>\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                        title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "team:coi.form_type.sent_and_disabled", {"name":"i18n","hash":{},"data":data})))
    + "\"\n                      ";
},"6":function(depth0,helpers,partials,data) {
  return "                        disabled=\"disabled\"\n";
  },"8":function(depth0,helpers,partials,data) {
  return " disabled";
  },"10":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                    title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "send_disabled", {"name":"i18n","hash":{},"data":data})))
    + "\"\n";
},"12":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                    title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "send_desc", {"name":"i18n","hash":{},"data":data})))
    + "\"\n";
},"14":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                    title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "send_reminder_desc", {"name":"i18n","hash":{},"data":data})))
    + "\"\n";
},"16":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "no_team_members", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "team:coi", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}