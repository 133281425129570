var AssessRobView, CellFootnotesAndReferencesView, CharactersLimit, ContextMenuView, DS, DirectnessTableView, Draggable, EditView, Footnotes, HelpBoxView, Outcome, OutcomeCellEditView, OutcomeScaleTypes, QS, QUALITIES, Shortcuts, Validation, WysiwygFootnotes, formatters, mediator, obsStudiesSelection, prognosticObsStudiesSelection, robinsIToggle, statisticalUtils, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

AssessRobView = require('views/assess_rob_view');

CellFootnotesAndReferencesView = require('views/evidence_syntheses/cell_footnotes_and_references_view');

CharactersLimit = require('lib/traits/characters_limit');

ContextMenuView = require('views/evidence_syntheses/context_menu_view');

DirectnessTableView = require('views/directness_table_view');

Draggable = require('base/lib/traits/draggable');

DS = require('models/outcome/design_studies');

EditView = require('base/views/edit_view');

Footnotes = require('models/evidence_syntheses/footnotes');

formatters = require('lib/formatters');

HelpBoxView = require('views/help_box_view');

mediator = require('mediator');

obsStudiesSelection = require('views/templates/outcome_edit/partials/obs_studies_selection');

Outcome = require('models/outcome');

OutcomeScaleTypes = require('lib/traits/outcome_scale_types');

prognosticObsStudiesSelection = require('views/templates/outcome_edit/partials/obs_studies_selection');

QS = require('models/outcome/quality_scores');

QUALITIES = require('models/outcome/qualities');

robinsIToggle = require('views/templates/outcome_edit/partials/robins_i_toggle');

Shortcuts = require('base/lib/traits/shortcuts');

statisticalUtils = require('lib/statistical_utils');

utils = require('base/lib/utils');

Validation = require('base/validation');

WysiwygFootnotes = require('lib/traits/wysiwyg_footnotes');

module.exports = OutcomeCellEditView = (function(_super) {
  __extends(OutcomeCellEditView, _super);

  function OutcomeCellEditView() {
    this.beforeFocusLost = __bind(this.beforeFocusLost, this);
    this.onFocusLost = __bind(this.onFocusLost, this);
    this.toggleSMDEffectMeasure = __bind(this.toggleSMDEffectMeasure, this);
    this.toggleAbsEffectAutoCalculation = __bind(this.toggleAbsEffectAutoCalculation, this);
    this.toggleFollowUpTimeUnit = __bind(this.toggleFollowUpTimeUnit, this);
    this.toggleScoreUnitInput = __bind(this.toggleScoreUnitInput, this);
    this.toggleControlCheckbox = __bind(this.toggleControlCheckbox, this);
    this.restoreOriginalEffectValues = __bind(this.restoreOriginalEffectValues, this);
    this.toggleObservationalStudiesList = __bind(this.toggleObservationalStudiesList, this);
    this.recalculateDichotomousRelativeEffect = __bind(this.recalculateDichotomousRelativeEffect, this);
    this.compoundFormSelectionChanged = __bind(this.compoundFormSelectionChanged, this);
    this._updateEffectSign = __bind(this._updateEffectSign, this);
    this.bindModelEvents = __bind(this.bindModelEvents, this);
    this.initialize = __bind(this.initialize, this);
    return OutcomeCellEditView.__super__.constructor.apply(this, arguments);
  }

  OutcomeCellEditView.prototype._riskLevelSuffix = function() {
    var cellContent;
    cellContent = this.target.find('.cell-content');
    if (cellContent.hasClass('low-value')) {
      return 'Low';
    } else if (cellContent.hasClass('moderate-value')) {
      return 'Moderate';
    } else if (cellContent.hasClass('high-value')) {
      return 'High';
    } else {
      return '';
    }
  };

  OutcomeCellEditView.prototype._controlRiskLevel = function() {
    var cellContent;
    cellContent = this.target.find('.cell-content');
    if (cellContent.hasClass('low-value')) {
      return 'lowControl';
    } else if (cellContent.hasClass('moderate-value')) {
      return 'moderateControl';
    } else if (cellContent.hasClass('high-value')) {
      return 'highControl';
    } else {
      return 'calculatedControl';
    }
  };

  OutcomeCellEditView.prototype.bindings = function() {
    var suffix;
    suffix = this._riskLevelSuffix();
    return {
      '#controlCount': 'controlCount',
      '#controlTotal': 'controlTotal',
      'input[data-property=eventType]': 'eventType',
      'input[name=calculatedControlValue]': {
        observe: ['controlCount', 'controlTotal'],
        onGet: function(values) {
          return formatters.outcome.controlRatio({
            'controlCount': values[0],
            'controlTotal': values[1]
          });
        }
      },
      'input[name=followUpTimeUnit]': {
        observe: 'followUpTimeUnit'
      },
      'input[name=followUpToTimeUnit]': {
        observe: 'followUpToTimeUnit'
      },
      'input[name=perPatients]': {
        observe: 'perPatients'
      },
      'input[name=perTimeFrame]': {
        observe: 'perTimeFrame'
      },
      'input[name=showFinalScoreInControls]': {
        observe: 'showFinalScoreInControls'
      },
      'input#absEffectAutoCalc': {
        observe: "absEffectAutoCalc" + suffix,
        onSet: (function(_this) {
          return function(value) {
            _this.model.calculateAbsoluteEffect(value);
            return value;
          };
        })(this)
      },
      'input#absEffectSize': {
        observe: "absEffectSize" + suffix,
        onGet: function(val) {
          if (utils.isNumeric(val)) {
            return Math.abs(val);
          } else {
            return "";
          }
        }
      },
      'input#absEffectFrom': {
        observe: "absEffectFrom" + suffix,
        onGet: function(val) {
          if (utils.isNumeric(val)) {
            return Math.abs(val);
          } else {
            return "";
          }
        }
      },
      'input#absEffectTo': {
        observe: "absEffectTo" + suffix,
        onGet: function(val) {
          if (utils.isNumeric(val)) {
            return Math.abs(val);
          } else {
            return "";
          }
        }
      },
      'input#absDenominator': {
        observe: 'absDenominator',
        onGet: function(val) {
          if (utils.isNumeric(val)) {
            return val;
          } else {
            return 1000;
          }
        },
        onSet: function(value) {
          var _ref;
          this.oldAbsDenominatorVal = Number((_ref = this.oldAbsDenominatorVal) != null ? _ref : this.model.get('absDenominator'));
          return value;
        }
      },
      'input[name=effectMeasure]': 'effectMeasure',
      'input[name=unitsType]': 'unitsType',
      'input[name=effectSize]': 'effectSize',
      'input[name=effectCount]': 'effectCount',
      'input[name=effectTotal]': 'effectTotal',
      'input[name=effectRate]': 'effectRate',
      'input[name=confidenceIntervalFrom]': 'confidenceIntervalFrom',
      'input[name=confidenceIntervalTo]': 'confidenceIntervalTo',
      'input[name=effectDenominator]': 'effectDenominator',
      'input[data-property=scaleFrom]': 'scaleFrom',
      'input[data-property=scaleTo]': 'scaleTo',
      'input[data-property=scaleType]': 'scaleType',
      'input[data-property=conventionalScaleLowerLimit]': 'conventionalScaleLowerLimit',
      'input[data-property=conventionalScaleUpperLimit]': 'conventionalScaleUpperLimit'
    };
  };

  OutcomeCellEditView.prototype._getEditInputBlockProperty = function($inputBlock) {
    var _ref;
    return (_ref = $inputBlock.data('property')) != null ? _ref : $inputBlock.find('select, input, textarea, .cell-edit-editor').attr('name');
  };

  OutcomeCellEditView.prototype.footnotesBindings = function() {
    var bindings, idx, inputBlock, property, _i, _len, _ref;
    bindings = {};
    _ref = this.$el.find('.edit-input-block');
    for (idx = _i = 0, _len = _ref.length; _i < _len; idx = ++_i) {
      inputBlock = _ref[idx];
      property = this._getEditInputBlockProperty($(inputBlock));
      if (bindings[property] == null) {
        bindings[property] = [];
      }
      bindings[property].push({
        selector: ".edit-input-block:eq(" + idx + ") .sup",
        method: 'append'
      });
    }
    return bindings;
  };

  OutcomeCellEditView.prototype.initialize = function() {
    var commentsLimit;
    OutcomeCellEditView.__super__.initialize.apply(this, arguments);
    this.enable(OutcomeScaleTypes);
    this.property = this.options.property;
    this.questionModel = this.options.questionModel;
    this.delegate('change', '.compound-edit .edit-input-block input.selection-input', this.compoundFormSelectionChanged);
    this.delegate('change', '.compound-edit .edit-input-block input.control-checkbox', this.toggleControlCheckbox);
    this.delegate('change', '.compound-edit .edit-input-block input#absEffectAutoCalc', this.toggleAbsEffectAutoCalculation);
    this.delegate('change', '.compound-edit .edit-input-block input#effectSize', this.toggleUnitsTypeSelectList);
    this.delegate('contextmenu', '.edit-input-block', this.showEditInputBlockContextMenu);
    this.delegate('change', '.compound-edit .edit-input-block input#followUpType', this.toggleFollowUpTimeUnit);
    this.delegate('change', '.compound-edit .edit-input-block input#showFinalScoreInControls', this.toggleScoreUnitInput);
    this.delegate('change', '.compound-edit .edit-input-block input#effectMeasure', this.toggleSMDEffectMeasure);
    this.delegate('click', 'button.footnote-required', this.showFootnoteAndReferenceView);
    this.delegate('click', this.maybeShowHelpInfo);
    this.delegate('change', 'input.robins-i', this.confirmIfAssessmentStarted);
    if (this._isAutomaticNarrativeOn()) {
      this.delegate('change', 'input#automatic-narrative-effect-size', this.changeAutomaticNarrativeEffectSize);
      this.delegate('click', 'button.insert', this.insertAutomaticNarrativeText);
    }
    this.on('compoundSelectionIndexChanged', this.compoundSelectionIndexChanged);
    this.on('compoundItemFocused', this.compoundItemFocused);
    this.on('opened', this._opened);
    commentsLimit = this.questionModel.isManagement() ? 500 : 200;
    this.textLimits = [
      {
        selector: '#comments',
        limit: commentsLimit,
        modelAttr: 'comments'
      }, {
        selector: '#prevalence1Characteristics',
        limit: 200
      }, {
        selector: '#prevalence2Characteristics',
        limit: 200
      }, {
        selector: '#prevalence3Characteristics',
        limit: 200
      }
    ];
    this.enable(WysiwygFootnotes);
    this.enable(Draggable, {
      draggingBoundaries: 'div.outcomes',
      cancelClasses: ".gdt-editor-container"
    });
    return this.enable(CharactersLimit, {
      limits: this.textLimits
    });
  };

  OutcomeCellEditView.prototype.maybeShowHelpInfo = function(evt) {
    if (!evt.target.classList.contains('context-help-icon')) {
      return;
    }
    return this.trigger('showHelpInfo', evt);
  };

  OutcomeCellEditView.prototype._opened = function() {
    this._setUnits(this.model.get('effectMeasure'));
    this.stickFootnotes(this.questionModel);
    if (this.model.get('type') === 'cont' && _.isNumber(this.model.get('effectSize'))) {
      this.toggleUnitsTypeSelectList();
    }
    if (this.editType === EditView.TYPES.SELECTION) {
      this.publishEvent('activatedProperty', this.property, this.target, this.questionModel, this.model);
    }
    return this.makeDraggable(this.box);
  };

  OutcomeCellEditView.prototype.showFootnoteAndReferenceView = function(e) {
    var cell, property;
    e.stopPropagation();
    property = $(e.target).data().property;
    cell = this.$("[data-property=" + property + "]").closest(".edit-input-block");
    return this.parentView.showCellFootnotesView(property, {
      cell: cell
    });
  };

  OutcomeCellEditView.prototype._forceAbsEffectFootnote = function(model, val) {
    var changedAttr, message, _ref;
    if (val) {
      return this.model.unbindFootnote(Footnotes.prototype.noExplanationProvided, 'absEffectAutoCalc');
    }
    if (mediator.user.get('forceFootnotes') && !((_ref = this.model.get('footnotes').absEffectAutoCalc) != null ? _ref.length : void 0)) {
      changedAttr = 'absEffectAutoCalc';
      message = $.t('es:force_footnotes.abs_effect_manual_calc');
      this.model.bindFootnote(Footnotes.prototype.noExplanationProvided, changedAttr);
      this.model.save();
      return this.parentView.forceFootnoteDialog(changedAttr, message, {
        cell: this.$('input#absEffectAutoCalc')
      });
    }
  };

  OutcomeCellEditView.prototype.bindModelEvents = function() {
    this.modelBind('change:followUpTimeUnit', this.toggleOthersInputField('.follow-up-time-unit-input'));
    this.modelBind('change:followUpToTimeUnit', this.toggleOthersInputField('.follow-up-to-time-unit-input'));
    this.modelBind('change:effectMeasure', this.toggleOthersInputField('input[name=otherMeasure]'));
    this.modelBind('change:perPatients', this.toggleOthersInputField('input[name=perPatientsOther]'));
    this.modelBind('change:perTimeFrame', this.toggleOthersInputField('input[name=perTimeFrameOther]'));
    this.modelBind('change:effectDenominator', this.toggleOthersInputField('input[name=effectOtherDenominator]'));
    this.modelBind('change:effectDuration', this.toggleOthersInputField('input[name=effectOtherDuration]'));
    this.modelBind('change:absEffectAutoCalc', this._forceAbsEffectFootnote);
    this.modelBind('change:effectMeasure', this.recalculateDichotomousRelativeEffect);
    this.modelBind('change:eventType', this.toggleNonEventInput);
    return this.modelBind('change:scaleType', this.toggleScaleFields);
  };

  OutcomeCellEditView.prototype.displayWarning = function() {
    var addWarning;
    addWarning = (function(_this) {
      return function(text, afterSelector) {
        return $('<div></div>').insertAfter(_this.$(afterSelector)).addClass('warning').html(text);
      };
    })(this);
    switch (this.target.data('warning')) {
      case 'empty_intervention':
        return addWarning($.t('es:outcome.edit_warnings.empty_intervention'), 'legend:first');
      case 'empty_study_design':
        return addWarning($.t('es:outcome.messages.sof_quality_study_design_not_defined'), 'legend:first');
    }
  };

  OutcomeCellEditView.prototype.afterRender = function() {
    OutcomeCellEditView.__super__.afterRender.apply(this, arguments);
    this.stickit(this.model, this.bindings());
    this.toggleInterventionControlLabels();
    this._scoreUnitInput(this.model.get('showFinalScoreInControls'));
    this.displayWarning();
    return this.toggleScaleFields();
  };

  OutcomeCellEditView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.target = null;
    this.questionModel = null;
    this.unstickit(this.model);
    this.unstickFootnotes();
    return OutcomeCellEditView.__super__.dispose.apply(this, arguments);
  };

  OutcomeCellEditView.prototype.showEditInputBlockContextMenu = function(e) {
    mediator.setFocus(this, (function(_this) {
      return function() {
        var $editInputBlock, editInputBlockContextMenu;
        _this.activateEditInputBlock(e);
        $editInputBlock = $(e.target).closest('.edit-input-block');
        editInputBlockContextMenu = new ContextMenuView({
          questionModel: _this.options.questionModel,
          footnotesBindingModel: _this.model,
          property: _this.property,
          propertyView: _this,
          cell: $editInputBlock,
          abortHandler: function() {
            return mediator.setFocus(_this);
          }
        });
        _this.subview('editInputBlockContextMenu', editInputBlockContextMenu);
        return editInputBlockContextMenu.show(utils.getEventCoordinates(e));
      };
    })(this));
    return false;
  };

  OutcomeCellEditView.prototype._updateEffectSign = function(attr, fewerMoreSelector) {
    var attrValue, fewerMore;
    attrValue = Math.abs(this.model.get(attr));
    fewerMore = this.$(fewerMoreSelector).val();
    if (fewerMore === 'more') {
      return this.model.set(attr, -1 * attrValue);
    }
  };

  OutcomeCellEditView.prototype._propagateEffectSignsUpdate = function() {
    var suffix;
    if (this.editType === EditView.TYPES.COMPOUND && this.$('div.compound-edit[name="absoluteEffect"]')) {
      suffix = this._riskLevelSuffix();
      this._updateEffectSign("absEffectSize" + suffix, "#fewerMore" + suffix);
      this._updateEffectSign("absEffectFrom" + suffix, "#fewerMoreFrom" + suffix);
      return this._updateEffectSign("absEffectTo" + suffix, "#fewerMoreTo" + suffix);
    }
  };

  OutcomeCellEditView.prototype._checkQualityCalculation = function() {
    var qm, qms, qualityModifiersSet, _ref;
    if (_ref = this.options.property, __indexOf.call(_.union(Outcome.QUALITY_MODIFIERS, ['quality', 'otherConsiderations', 'designStudies']), _ref) >= 0) {
      qms = (function() {
        var _i, _len, _ref1, _results;
        _ref1 = Outcome.QUALITY_MODIFIERS;
        _results = [];
        for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
          qm = _ref1[_i];
          _results.push(this.model.get(qm));
        }
        return _results;
      }).call(this);
      qualityModifiersSet = Outcome.areQualityModifiersSet(qms);
      if (qualityModifiersSet && !this.model.get('designStudies')) {
        this.defineStudyDesignSof = true;
        this.model.save().otherwise(function(err) {
          if (!(err instanceof Exceptions.validation)) {
            throw err;
          }
        });
        if (!this.parentView.disposed) {
          return this.parentView.highlightCellsWithWarnings();
        }
      } else {
        if (!this.parentView.disposed) {
          return this.parentView.removeStudyDesignWarningHighlightForCell();
        }
      }
    }
  };

  OutcomeCellEditView.prototype._checkCompoundFormFootnotes = function() {
    var attr, attributesToCheck, attributesToHighlight, condition, editViewAttributes, _i, _len, _ref, _ref1;
    this.closeOnAccept = true;
    this.footnotesNotBounded = false;
    if (!(((_ref = this.options.type) === OutcomeCellEditView.TYPES.COMPOUND || _ref === OutcomeCellEditView.TYPES.REACT) || this.options.external)) {
      return;
    }
    editViewAttributes = _(this.$('.edit-input-block')).map((function(_this) {
      return function(ib) {
        return _this._getEditInputBlockProperty($(ib));
      };
    })(this));
    attributesToCheck = _.pick(this.model.attributes, _.intersection(Outcome.QUALITY_MODIFIERS, editViewAttributes));
    condition = (function(_this) {
      return function(attr) {
        return _this.model.isQualityDowngradedWithoutExplanation(attr, attributesToCheck);
      };
    })(this);
    attributesToHighlight = (function() {
      var _i, _len, _ref1, _results;
      _ref1 = _(attributesToCheck).keys();
      _results = [];
      for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
        attr = _ref1[_i];
        if (condition(attr)) {
          _results.push(attr);
        }
      }
      return _results;
    })();
    _ref1 = _(attributesToCheck).keys();
    for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
      attr = _ref1[_i];
      if (this.model.isQualityUpgraded(attr, attributesToCheck)) {
        this.model.unbindFootnote(Footnotes.prototype.noExplanationProvided, attr);
      }
    }
    if (attributesToHighlight.length) {
      this.closeOnAccept = false;
      this.footnotesNotBounded = true;
    }
    if (this.footnotesNotBounded) {
      return this._qualityDowngradedDialog(attributesToHighlight);
    }
  };

  OutcomeCellEditView.prototype._qualityDowngradedDialog = function(attributesToHighlight) {
    var changedAttr, _i, _len;
    for (_i = 0, _len = attributesToHighlight.length; _i < _len; _i++) {
      changedAttr = attributesToHighlight[_i];
      this.model.bindFootnote(Footnotes.prototype.noExplanationProvided, changedAttr);
    }
    return mediator.dialogs.confirm({
      message: $.t('es:force_footnotes.certainty_downgrade'),
      confirmText: $.t('es:force_footnotes.add_now'),
      declineText: $.t('es:force_footnotes.add_later')
    }, (function(_this) {
      return function(add_footnotes) {
        if (!add_footnotes) {
          return _this.hide(true);
        }
      };
    })(this));
  };

  OutcomeCellEditView.prototype.isReadyToSave = function() {
    return this.model.isValid(true) && !this.footnotesNotBounded;
  };

  OutcomeCellEditView.prototype._getAssessmentChoice = function() {
    var $listElement;
    $listElement = this.$('li.edit-option').eq(this.selectionIndex);
    if ($listElement.attr('modifier') === 'assess') {
      return $listElement.parent().attr('name');
    }
  };

  OutcomeCellEditView.prototype.accept = function() {
    var choice, view;
    if (this.editType === EditView.TYPES.SELECTION) {
      choice = this._getAssessmentChoice();
      if (choice != null) {
        view = choice === 'indirectness' ? DirectnessTableView : AssessRobView;
        this.parentView.subview('assessTable', new view({
          model: this.model,
          questionModel: this.questionModel
        }));
        return false;
      }
    }
    return OutcomeCellEditView.__super__.accept.apply(this, arguments);
  };

  OutcomeCellEditView.prototype.afterChangesPropagation = function() {
    var _base;
    if (mediator.user.get('forceFootnotes')) {
      this._checkCompoundFormFootnotes();
    }
    this._propagateEffectSignsUpdate();
    if (!(typeof (_base = this.model).isDiagnostic === "function" ? _base.isDiagnostic() : void 0)) {
      this._propagateAbsDenominatorChange();
    }
    return this._checkQualityCalculation();
  };

  OutcomeCellEditView.prototype._propagateAbsDenominatorChange = function() {
    var newDenominator, questionModel;
    newDenominator = Number(this.model.get('absDenominator'));
    questionModel = this.questionModel;
    if (this.oldAbsDenominatorVal && this.oldAbsDenominatorVal !== this.model.get('absDenominator')) {
      return mediator.dialogs.confirm({
        yesClass: 'danger',
        message: $.t('es:outcome.messages.abs_denominator_propagate_change', {
          from: _.string.numberFormat(this.oldAbsDenominatorVal),
          to: _.string.numberFormat(newDenominator)
        })
      }, function(confirmed) {
        var outcome, _i, _len, _ref;
        if (!confirmed) {
          return;
        }
        _ref = questionModel.get('outcomes').models;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          outcome = _ref[_i];
          outcome.set('absDenominator', newDenominator);
        }
        return questionModel.save().otherwise(mediator.dialogs.fatalError);
      });
    }
  };

  OutcomeCellEditView.prototype.compoundFormSelectionChanged = function(e) {
    var btn, show, _ref;
    if (_ref = this.property, __indexOf.call(Outcome.QUALITY_MODIFIERS, _ref) >= 0) {
      btn = this.$el.find("button[data-property='" + this.property + "']");
      show = QS.get($(e.target).val()).score !== 0;
      if (show) {
        return btn.show();
      } else {
        return btn.hide();
      }
    } else {
      return this.toggleObservationalStudiesList(e);
    }
  };

  OutcomeCellEditView.prototype.recalculateDichotomousRelativeEffect = function(model, val, options) {
    var fromMeasure, toMeasure, _ref;
    if (options.external) {
      return;
    }
    fromMeasure = this.model.previousAttributes()['effectMeasure'];
    toMeasure = val;
    if (((_ref = this.model.get('originalEffectMeasure')) === 'RR' || _ref === 'OR' || _ref === 'HR') && toMeasure === this.model.get('originalEffectMeasure')) {
      return mediator.dialogs.confirm($.t('es:outcome.messages.recalculation.revert_to_original_value', {
        'value_type': toMeasure
      }), (function(_this) {
        return function(confirmed) {
          if (confirmed) {
            return _this.restoreOriginalEffectValues();
          }
        };
      })(this));
    } else if ((fromMeasure === 'HR' || fromMeasure === 'OR') && toMeasure === 'RR') {
      return mediator.dialogs.confirm($.t('es:outcome.messages.recalculation.convert_to_rr', {
        'value_type': fromMeasure
      }), (function(_this) {
        return function(confirmed) {
          if (confirmed) {
            return _this.model.recalculateRatio(fromMeasure, toMeasure);
          }
        };
      })(this));
    }
  };

  OutcomeCellEditView.prototype.toggleNonEventInput = function() {
    if (this.model.get('eventType') === 'non_event') {
      return this.$('#non-event').show();
    } else {
      return this.$('#non-event').hide();
    }
  };

  OutcomeCellEditView.prototype.toggleUnitsTypeSelectList = function() {
    var $el, countable, effectSize, index, uncountable, unitTypes;
    $el = this.$el.find('.countable-uncountable-select');
    effectSize = this.model.get('effectSize');
    unitTypes = {
      countable: [$.t('es:outcome.higher'), $.t('es:outcome.lower')],
      uncountable: [$.t('es:outcome.more'), $.t('es:outcome.fewer')]
    };
    index = effectSize >= 0 ? 0 : 1;
    countable = unitTypes.countable[index];
    uncountable = unitTypes.uncountable[index];
    $el.find('.selection-list-options option[value="countable"]').html(countable);
    $el.find('.selection-list-options option[value="uncountable"]').html(uncountable);
    if (_.isEmpty(this.model.get('unitsType'))) {
      this.model.set('unitsType', 'countable');
    }
    $el.find('.selection-text').html(unitTypes[this.model.get('unitsType')][index]);
    return this.refreshInputs();
  };

  OutcomeCellEditView.prototype.toggleObservationalStudiesList = function(e) {
    var $target, data, isPrognostic, obsStudiesList, _ref, _ref1;
    $target = $(e.target);
    if (this.property !== 'designStudies') {
      return;
    }
    this.toggleInterventionControlLabels($target.val());
    if ((_ref = $target.val()) === '' || _ref === 'RandTrials') {
      this.$el.find('.observational-studies-list').empty();
      this.$el.find('.robins-i-toggle').empty();
      return this.refreshInputs();
    } else if ((_ref1 = $target.val()) === 'ObsStudies' || _ref1 === 'NonRandStudy') {
      data = this.getTemplateData();
      isPrognostic = this.questionModel.isPrognostic();
      obsStudiesList = isPrognostic ? prognosticObsStudiesSelection : obsStudiesSelection;
      if ($target.val() !== 'NonRandStudy') {
        this.$el.find('.observational-studies-list').html(obsStudiesList(data));
      }
      if (!isPrognostic) {
        this.$el.find('.robins-i-toggle').html(robinsIToggle(data));
      }
      return this.refreshInputs();
    }
  };

  OutcomeCellEditView.prototype.isCaseControlType = function(designStudies) {
    return designStudies === 'case_control_studies' || designStudies === 'case_control_and_other_combined';
  };

  OutcomeCellEditView.prototype.toggleInterventionControlLabels = function(val) {
    if (val == null) {
      val = null;
    }
    if (!val) {
      val = this.model.get('designStudies') && this.model.get('designStudies').name !== 'RandTrials' ? this.model.get('designStudies').value : void 0;
    }
    val = val != null ? val.replace('ObsStudies.', '') : void 0;
    if (this.isCaseControlType(val)) {
      this.$('.control-block').hide();
      this.$('.case-control-block').show();
      if (val === 'case_control_and_other_combined') {
        this.$('.case-control-other').show();
      } else {
        this.$('.case-control-other').hide();
      }
    } else {
      this.$('.case-control-block').hide();
      this.$('.control-block').show();
    }
    if (this.options.viewTypeName === "ACCP") {
      this.$('.case-control-block').hide();
    }
    return this.refreshInputs();
  };

  OutcomeCellEditView.prototype.toggleOthersInputField = function(selector) {
    return function(outcome, val) {
      if (val === 'others' || val === 'other') {
        this.$(selector).show();
        this.$(selector).closest('div').addClass('other');
      } else {
        this.$(selector).hide();
        this.$(selector).closest('div').removeClass('other');
      }
      return this.refreshInputs();
    };
  };

  OutcomeCellEditView.prototype.restoreOriginalEffectValues = function() {
    var originalValues;
    originalValues = {
      'effectMeasure': this.model.get('originalEffectMeasure'),
      'effectSize': this.model.get('originalEffectSize'),
      'confidenceIntervalFrom': this.model.get('originalConfidenceIntervalFrom'),
      'confidenceIntervalTo': this.model.get('originalConfidenceIntervalTo')
    };
    return this.model.set(originalValues);
  };

  OutcomeCellEditView.prototype.toggleControlCheckbox = function(e) {
    var $target, targetId;
    $target = $(e.target);
    targetId = $target.attr('id');
    this.$("input[name=" + targetId + "Label]").prop('disabled', !$target.is(':checked'));
    return this.$("input[name=" + targetId + "Risk]").prop('disabled', !$target.is(':checked'));
  };

  OutcomeCellEditView.prototype.toggleScoreUnitInput = function(e) {
    var $target;
    $target = $(e.target);
    return this._scoreUnitInput($target.is(':checked'));
  };

  OutcomeCellEditView.prototype._scoreUnitInput = function(val) {
    this.$("input[name='units']").prop('disabled', !val);
    this.$("input[name='changeScoreInControlsValue']").prop('disabled', !val);
    return this.$("span[name='changeScoreInControls']").attr('disabled', !val);
  };

  OutcomeCellEditView.prototype.toggleFollowUpTimeUnit = function(e) {
    var $target;
    $target = $(e.target);
    if ($target.val() === 'range') {
      return this.$('#range-to').show();
    } else {
      return this.$('#range-to').hide();
    }
  };

  OutcomeCellEditView.prototype.toggleAbsEffectAutoCalculation = function(e) {
    var isTargetChecked, suffix;
    suffix = this._riskLevelSuffix();
    isTargetChecked = $(e.target).is(':checked');
    this.$("#absEffectSize, #absEffectFrom, #absEffectTo").prop('disabled', isTargetChecked);
    return this.$("span[name=fewerMore" + suffix + "], span[name=fewerMoreFrom" + suffix + "], span[name=fewerMoreTo" + suffix + "]").attr('disabled', isTargetChecked);
  };

  OutcomeCellEditView.prototype.toggleSMDEffectMeasure = function(e) {
    return this._setUnits(e.target.value);
  };

  OutcomeCellEditView.prototype._setUnits = function(effectMeasure) {
    var units;
    units = $('.compound-edit .edit-input-block input[name="units"]');
    if (effectMeasure === 'SMD') {
      return units.val('SD').prop('disabled', true);
    } else {
      return units.prop('disabled', false);
    }
  };

  OutcomeCellEditView.prototype.resetInputValues = function() {
    var $input, attrName, input, _i, _len, _ref, _results;
    _ref = this.inputs;
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      input = _ref[_i];
      $input = $(input);
      attrName = $input.attr('name');
      if (attrName) {
        _results.push(this._setInputValue($input, this.model.get(attrName)));
      } else {
        _results.push(void 0);
      }
    }
    return _results;
  };

  OutcomeCellEditView.prototype.acceptChangesConfirmation = function(acceptChangesDeferred) {
    var $listElement, attrName, attrValue, optionsList, splittedValue, studyTypeLabel;
    if (this.editType === OutcomeCellEditView.TYPES.SELECTION) {
      $listElement = this.getSelectedElement();
      optionsList = $listElement.parent();
      attrName = optionsList.attr('name');
      attrValue = $listElement.attr('modifier');
      if (this.model.get('type') === 'cont' && attrName === 'designStudies' && (attrValue === 'ObsStudies.case_control_studies' || attrValue === 'ObsStudies.case_control_and_other_combined')) {
        splittedValue = attrValue.split('.');
        studyTypeLabel = DS.get(splittedValue[0], splittedValue[1]).label;
        return mediator.dialogs.confirm($.t("es:outcome.design_study_changes.to_case_control", {
          'study_type': $.t("es:outcome." + studyTypeLabel)
        }), function(confirmed) {
          return acceptChangesDeferred.resolve(confirmed);
        });
      } else {
        return acceptChangesDeferred.resolve(true);
      }
    } else {
      return acceptChangesDeferred.resolve(true);
    }
  };

  OutcomeCellEditView.prototype.conditionalAccept = function() {
    var save;
    if (this.accepting) {
      return;
    }
    if (this.editType === OutcomeCellEditView.TYPES.SELECTION) {
      save = this.subview('footnotes') && !this.subview('footnotes').disposed;
      return this.hide(save);
    } else {
      return this.accept();
    }
  };

  OutcomeCellEditView.prototype.onFocusLost = function(focusTo) {
    if (!this.changingFocusToSubview(focusTo)) {
      return this.conditionalAccept();
    }
  };

  OutcomeCellEditView.prototype.beforeFocusLost = function(deferred, focusTo) {
    if (this.changingFocusToSubview(focusTo)) {
      return deferred.resolve(true);
    } else {
      if (this.model.isValid(true) && !this.defineStudyDesignSof) {
        return deferred.resolve();
      } else {
        return deferred.reject();
      }
    }
  };

  OutcomeCellEditView.prototype._prepareTemplateDataForAbsoluteEffect = function(data) {
    var cellContent, fewerMoreFn, overrideEffectValues, riskLevelSuffix;
    if (this.property !== 'absoluteEffect') {
      return;
    }
    riskLevelSuffix = this._riskLevelSuffix();
    data.riskLevelSuffix = riskLevelSuffix;
    cellContent = this.target.find('.cell-content');
    fewerMoreFn = (function(_this) {
      return function(effectAttr, suffix) {
        if (_this.model.get("" + effectAttr + suffix) >= 0) {
          return 'fewer';
        } else {
          return 'more';
        }
      };
    })(this);
    overrideEffectValues = (function(_this) {
      return function(riskLevelSuffix) {
        data.absEffectSize = _this.model.get("absEffectSize" + riskLevelSuffix);
        data.absEffectFrom = _this.model.get("absEffectFrom" + riskLevelSuffix);
        data.absEffectTo = _this.model.get("absEffectTo" + riskLevelSuffix);
        data.absEffectAutoCalc = _this.model.get("absEffectAutoCalc" + riskLevelSuffix);
        data.fewerMore = fewerMoreFn('absEffectSize', riskLevelSuffix);
        data.fewerMoreFrom = fewerMoreFn('absEffectFrom', riskLevelSuffix);
        data.fewerMoreTo = fewerMoreFn('absEffectTo', riskLevelSuffix);
        data.fewerMoreName = "fewerMore" + riskLevelSuffix;
        data.fewerMoreFromName = "fewerMoreFrom" + riskLevelSuffix;
        return data.fewerMoreToName = "fewerMoreTo" + riskLevelSuffix;
      };
    })(this);
    return overrideEffectValues(riskLevelSuffix);
  };

  OutcomeCellEditView.prototype.showThresholdFeature = function() {
    return mediator.services.switches.isOn('thresholdFeature');
  };

  OutcomeCellEditView.prototype.getTemplateData = function() {
    var checkForNaN, data, key, _base, _i, _len;
    data = OutcomeCellEditView.__super__.getTemplateData.apply(this, arguments);
    checkForNaN = ['absEffectSize', 'absEffectFrom', 'absEffectTo'];
    for (_i = 0, _len = checkForNaN.length; _i < _len; _i++) {
      key = checkForNaN[_i];
      if (isNaN(data[key])) {
        data[key] = '';
      }
    }
    _(data).extend({
      type: this.model.get('type'),
      isCaseControl: typeof (_base = this.model).isCaseControl === "function" ? _base.isCaseControl() : void 0,
      viewTypeName: this.options.viewTypeName,
      isPopup: true,
      defineStudyDesignSof: this.defineStudyDesignSof,
      controlRiskLevel: this._controlRiskLevel(),
      isManagement: this.questionModel.isManagement(),
      isDiagnostic: this.questionModel.isDiagnostic(),
      isPrognostic: this.questionModel.isPrognostic(),
      robinsIEnabled: this.model.get('robinsIEnabled'),
      thresholdFeature: this.showThresholdFeature(),
      interpretationOfResults: this.model.get('interpretationOfResults')
    });
    this._prepareTemplateDataForAbsoluteEffect(data);
    if (this._isAutomaticNarrativeOn()) {
      data = _(data).extend(this._getAutomaticNarrativeTemplateData());
    }
    return data;
  };

  OutcomeCellEditView.prototype._isAutomaticNarrativeOn = function() {
    return this.property === 'comments' && this.questionModel.isManagement();
  };

  OutcomeCellEditView.prototype._getAutomaticNarrativeStatements = function(quality, effectSize) {
    var direction, narrativeStatements, notSelectedNarrativeStatement, qualityPart;
    direction = this._getAutomaticNarrativeDirection();
    notSelectedNarrativeStatement = {
      notSelected: $.t('es:automatic_narrative.narrative_statements.not_selected')
    };
    if (!effectSize || effectSize === 'notSelected') {
      return notSelectedNarrativeStatement;
    }
    qualityPart = QUALITIES[quality].toLowerCase().replace(' ', '_');
    narrativeStatements = $.t("es:automatic_narrative.narrative_statements." + qualityPart + "." + effectSize + "." + direction, {
      returnObjects: true,
      intervention: _.string.escapeHTML(utils.firstLetterToLowerCase(this.questionModel.get('intervention'))),
      outcome: _.string.escapeHTML(utils.firstLetterToLowerCase(this.model.get('name')))
    });
    return _.chain(narrativeStatements).map(utils.capitalizeFirstLetter).reduce(function(acc, item) {
      acc[item] = item;
      return acc;
    }, notSelectedNarrativeStatement).value();
  };

  OutcomeCellEditView.prototype._prepareAutomaticNarrativeStatementsForTemplateData = function(quality) {
    var narrativeStatements;
    narrativeStatements = this._getAutomaticNarrativeStatements(quality, false);
    return _.chain(narrativeStatements).pairs().map(function(_arg) {
      var text, value;
      value = _arg[0], text = _arg[1];
      return "" + value + ":" + text;
    }).value().join(',');
  };

  OutcomeCellEditView.prototype._getAutomaticNarrativeDirection = function() {
    var calculatedCer, controlCount, controlTotal, effectMeasure, effectSize, effectSizes, eventType, highCer, lowCer, moderateCer, rrs;
    switch (this.model.get('type')) {
      case 'cont':
        effectSize = this.model.get('effectSize');
        if ((effectSize == null) || typeof effectSize !== 'number' || isNaN(effectSize)) {
          return 'either';
        } else if (effectSize < 0) {
          return 'decrease';
        } else if (effectSize > 0) {
          return 'increase';
        } else {
          return 'either';
        }
        break;
      case 'dich':
      case 'time_to_event':
        controlCount = this.model.get('controlCount');
        controlTotal = this.model.get('controlTotal');
        calculatedCer = controlCount / controlTotal;
        lowCer = 0.01 * this.model.get('lowControlRisk');
        moderateCer = 0.01 * this.model.get('moderateControlRisk');
        highCer = 0.01 * this.model.get('highControlRisk');
        effectMeasure = this.model.get('effectMeasure');
        eventType = this.model.get('eventType');
        effectSizes = [this.model.get('metaType') === 'RANGE_OF_EFFECTS' ? this.model.get('confidenceIntervalFrom') : void 0, this.model.get('metaType') === 'RANGE_OF_EFFECTS' ? this.model.get('confidenceIntervalTo') : void 0, this.model.get('metaType') !== 'RANGE_OF_EFFECTS' ? this.model.get('effectSize') : void 0];
        rrs = _.chain([calculatedCer, lowCer, moderateCer, highCer]).compact().reject(function(rr) {
          return typeof rr !== 'number' || isNaN(rr);
        }).map(function(cer) {
          return _.chain(effectSizes).compact().reject(function(es) {
            return typeof es !== 'number' || isNaN(es);
          }).map(function(es) {
            return statisticalUtils.getRREffectSize(cer, es, effectMeasure, eventType);
          }).reject(isNaN).value();
        }).flatten().value();
        if (rrs.length === 0) {
          return 'either';
        } else if (_.every(rrs, function(rr) {
          return rr < 1;
        })) {
          return 'decrease';
        } else if (_.every(rrs, function(rr) {
          return rr > 1;
        })) {
          return 'increase';
        } else {
          return 'either';
        }
        break;
      default:
        return 'either';
    }
  };

  OutcomeCellEditView.prototype._getAutomaticNarrativeTemplateData = function() {
    var quality, selectableAttrOptions;
    quality = this.model.get('quality');
    selectableAttrOptions = {
      clearOption: false,
      noTextTransform: true,
      wrapLine: true,
      disabled: quality == null
    };
    return {
      anEffectSizes: quality === 1 ? 'notSelected:not_selected,effect:effect' : 'notSelected:not_selected,large:large,moderate:moderate,small_important:small_important, small_not_important:small_not_important,no_effect:no_effect',
      anEffectSizesOptions: selectableAttrOptions,
      anNarrativeStatements: this._prepareAutomaticNarrativeStatementsForTemplateData(quality),
      anNarrativeStatementsOptions: _.extend({}, selectableAttrOptions, {
        useI18n: false
      }),
      anDisabled: quality == null
    };
  };

  OutcomeCellEditView.prototype.changeAutomaticNarrativeEffectSize = function() {
    var $list, $listInput, effectSize, narrativeStatements, notSelectedText, quality;
    quality = this.model.get('quality');
    effectSize = this.$('input#automatic-narrative-effect-size').val();
    narrativeStatements = _.chain(this._getAutomaticNarrativeStatements(quality, effectSize)).pairs().reduce(function(acc, _arg) {
      var text, value;
      value = _arg[0], text = _arg[1];
      return "" + acc + "<option value='" + (_.string.escapeHTML(value)) + "'>" + (_.string.escapeHTML(text)) + "</option>";
    }, '').value();
    notSelectedText = $.t('es:automatic_narrative.narrative_statements.not_selected');
    $listInput = this.$('input#automatic-narrative-narrative-statements');
    $list = $listInput.parent();
    $list.find('.selection-list-options').html(narrativeStatements);
    $listInput.val('notSelected');
    return $list.find('.selection-text').text(notSelectedText);
  };

  OutcomeCellEditView.prototype.insertAutomaticNarrativeText = function() {
    var $comments, newText, text, textToInsert;
    textToInsert = this.$('input#automatic-narrative-narrative-statements').val();
    if (textToInsert === 'notSelected') {
      return;
    }
    $comments = this.$('textarea#comments');
    text = $comments.val();
    newText = ("" + textToInsert + "\n\n" + text).trim();
    $comments.val(_.string.unescapeHTML(newText));
    return this.refreshCharactersLimits();
  };

  OutcomeCellEditView.prototype.compoundItemFocused = function(inputElement) {
    var editInputBlock, editView, _ref;
    editInputBlock = inputElement.closest('.edit-input-block');
    this.property = (_ref = editInputBlock.data('property')) != null ? _ref : editInputBlock.find('input, textarea').attr('name');
    return this.publishEvent('activatedProperty', this.property, inputElement, this.questionModel, this.model, editView = true);
  };

  OutcomeCellEditView.prototype.compoundSelectionIndexChanged = function(index) {
    var editInputBlock;
    editInputBlock = this.inputs.eq(index).closest('.edit-input-block');
    if (editInputBlock.find(this.inputsSelector).length > 1 && !editInputBlock.data('property')) {
      throw new Error('No property set for input block with more then one input');
    }
  };

  OutcomeCellEditView.prototype.confirmIfAssessmentStarted = function(evt) {
    var checked, confirmationText, hasAssessmentStarted;
    hasAssessmentStarted = Outcome.QUALITY_MODIFIERS.some((function(_this) {
      return function(modifier) {
        return _this.model.get(modifier) != null;
      };
    })(this));
    if (!hasAssessmentStarted) {
      return;
    }
    confirmationText = $.t('es:outcome.robins_i_change_confirmation');
    checked = evt.target.checked;
    return mediator.dialogs.confirm(confirmationText, (function(_this) {
      return function(confirmed) {
        if (confirmed) {
          return Outcome.QUALITY_MODIFIERS.forEach(function(modifier) {
            return _this.model.set(modifier, null);
          });
        } else {
          return evt.target.checked = !checked;
        }
      };
    })(this));
  };

  return OutcomeCellEditView;

})(EditView);
