AdolopedEtdStore = require 'stores/adoloped_etd_store'
AdolopmentDataStore = require 'stores/adolopment_data_store'
Assessment = require 'components/etd/assessment'
Conclusions = require 'components/etd/conclusions'
ConfirmationModal = require 'components/common/confirmation_modal'
ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdActions = require 'actions/etd_actions'
EtdStore = require 'stores/etd_store'
EtDVotingActions = require 'actions/etd_voting_actions'
EtdVotingStore = require 'stores/etd_voting_store'
Header = require 'components/etd/etd_header'
KeyMessagesStore = require 'stores/key_messages_store'
mediator = require 'mediator'
OrganizationsStore = require 'stores/organizations_store'
OverarchingQuestionsStore = require 'stores/overarching_questions_store'
QualityIndicatorsStore = require 'stores/quality_indicators_store'
QuestionsStatusesStore = require 'stores/questions_statuses_store'
QuestionsStore = require 'stores/questions_store'
Recommendations = require 'components/etd/recommendations_part'
ReferencesStore = require 'stores/references_store'
Router = require 'router'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'
TYPE_OF_RECOMMENDATION_SECTIONS = require('lib/etd_helper').getRecommendationTypeSections()
UserProjectDataStore = require 'stores/user_project_data_store'

{ getKeyValObject, fill } = require 'base/lib/utils'
{ getFirstKey } = require 'lib/immutable_utils'
{ getCurrentTORSectionId  } = require 'lib/etd_helper'
{ bool, instanceOf, string, oneOf } = PropTypes

storeConnectors =
  AdolopedEtdStore: (Store) ->
    adolopments: Store.getAdolopments()
    isFetchingAdolopment: Store.isFetching()
  AdolopmentDataStore: (Store) ->
    adolopmentData: Store.getAdolopmentData()
  EtdStore: (Store) ->
    additionalConsiderations: Store.getAdditionalConsiderations()
    assessmentSections: Store.getAssessmentSections()
    attachments: Store.getAttachments()
    conclusionsSections: Store.getConclusionsSections()
    criterions: Store.getCriterions()
    currentEditable: Store.getActiveContentEditable()
    etdId: Store.getEtdId()
    headerSections: Store.getHeaderSections()
    isFetchingEtd: Store.isFetching()
    isHeaderEditing: Store.isHeaderEditing()
    isHeaderExpanded: Store.isHeaderExpanded()
    researchEvidences: Store.getResearchEvidences()
    sojStatus: Store.getSojStatus()
    template: Store.getTemplate()
    templateUndefined: Store.isTemplateUndefined()
    showDefaultTemplateDialog: Store.shouldShowDefaultTemplateDialog()
  KeyMessagesStore: (Store) ->
    isFetchingKeyMessages: Store.isFetching()
    keyMessagesData: Store.getCurrentQuestion()
  OrganizationsStore: (Store) ->
    projectFromOrganization: Store.isCurrentProjectFromOrganization()
  QualityIndicatorsStore: (Store, props) ->
    isFetchingQualityIndicators: Store.isFetching()
    qualityIndicators: Store.getQualityIndicators()
  QuestionsStore: (Store, props) ->
    isFetchingQuestions: Store.isFetching()
    questionData: Store.getQuestion(props.questionId)
    questions: Store.getQuestions()
    outcomes: Store.getAllOutcomes(props.questionId)
    criticalOutcomes: Store.getCriticalOutcomes(props.questionId)
  OverarchingQuestionsStore: (Store) ->
    isFetchingOverarchingQuestion: Store.isFetching()
    overarchingQuestionData: Store.getCurrentQuestion()
  QuestionsStatusesStore: (Store, props) ->
    questionStatuses: Store.getQuestionStatuses props.questionId
  EtdVotingStore: (Store) ->
    activeVotingTabs: Store.getActiveVotingTabs()
    isFetchingEtdVoting: Store.isFetching()
    votingData: Store.getVotingData()
  ReferencesStore: (Store) ->
    isFetchingReferences: Store.isFetching()
  UserProjectDataStore: (Store, props) ->
    collapsedAssessmentSections: Store.getCollapsedAssessmentSections()
    etdViewSettings: Store.getEtdViewSettings()
    hiddenSojSections: Store.getHiddenSojSections()
    isFetchingUserProjectData: Store.isFetching()

Etd = createReactClass
  displayName: 'Etd'

  mixins: [
    Translation('es:recommendations.table')
    CustomRenderMixin
  ]

  propTypes:
    activeVotingTabs: instanceOf(Immutable.Map)
    additionalConsiderations: instanceOf(Immutable.Map)
    adolopmentData: instanceOf(Immutable.Map)
    adolopments: instanceOf(Immutable.Map)
    assessmentSections: instanceOf(Immutable.Map)
    attachments: instanceOf(Immutable.Map)
    comparison: string
    conclusionsSections: instanceOf(Immutable.Map)
    etdId: string.isRequired,
    etdViewSettings: instanceOf(Immutable.Map)
    headerSections: instanceOf(Immutable.Map)
    hiddenSojSections: instanceOf(Immutable.Map)
    intervention: string
    isFetchingKeyMessages: bool.isRequired
    keyMessagesData: instanceOf(Immutable.Map).isRequired
    outcomes: instanceOf(Immutable.List)
    overarching: bool
    overarchingQuestionData: instanceOf(Immutable.Map)
    qualityIndicators: instanceOf(Immutable.List)
    questionData: instanceOf(Immutable.Map)
    questionId: string.isRequired,
    renderMode: oneOf(['regular', 'printout', 'mcSource']).isRequired
    researchEvidences: instanceOf(Immutable.Map)
    showDefaultTemplateDialog: bool
    showVotingResults: bool
    type: oneOf(['overarching', 'keyMessages', 'regular', 'qualityIndicators', 'mdgRecommendation'])
    votingData: instanceOf(Immutable.Map)

  getDefaultProps: ->
    adolopmentData: Immutable.Map()
    adolopments: Immutable.Map()
    comparison: null
    etdViewSettings: Immutable.Map()
    intervention: null
    overarching: false
    overarchingQuestionData: Immutable.Map()
    qualityIndicators: Immutable.List()
    renderMode: 'regular'
    showVotingResults: true
    type: 'regular'

  _getVotingDataForEtdPart: (partName) ->
    return Immutable.Map() unless @props.showVotingResults
    switch partName
      when 'assessment' then @props.votingData.get 'judgements', Immutable.Map()
      when 'conclusions' then @props.votingData.get 'conclusions', Immutable.Map()
      when 'recommendation' then @props.votingData.get 'recommendation', Immutable.Map()
      else Immutable.Map()

  _getInterventionComparison: ->
    if @props.intervention and @props.comparison
      [ @props.intervention, @props.comparison ]
    else if not @props.questionData.isEmpty()
      if @props.questionData.get('type') is 'diagnostic'
        [ @props.questionData.get('indexTest'), @props.questionData.get('comparatorTest') ]
      else
        [ @props.questionData.get('intervention'), @props.questionData.get('comparison') ]
    else
      [ null, null ]

  _getActiveVotingTab: (etdPart) ->
    @props.activeVotingTabs.get etdPart

  _getQuestionType: ->
    if @props.questionData.get('type') is 'diagnostic' then 'dx' else 'tx'

  routeToAdministrationModule: (e) ->
    e.preventDefault()
    mediator.publish '!router:route', Router::getProjectRelativeUrl("/administration")

  keepSojStatus: (status) ->
    EtdActions.keepSojStatus status

  switchVotingTab: (etdParts...) -> (tabName) ->
    data = if etdParts.length is 1
      [ etdPart ] = etdParts
      getKeyValObject etdPart, tabName
    else
      _.object etdParts, _.fill etdParts, tabName
    EtDVotingActions.setActiveVotingTab data

  _renderConclusions: ->
    {
      adolopmentData
      adolopments
      assessmentSections
      conclusionsSections
      currentEditable
      etdId
      etdViewSettings
      renderMode
      renderOptions
    } = @props

    conclusionsSections = if getCurrentTORSectionId conclusionsSections
      conclusionsSections.rest()
    else
      conclusionsSections

    questionName = @props.questionData.get 'question'
    baseProps =
      adolopmentData: adolopmentData.getIn [etdId, 'conclusions'], Immutable.Map()
      adolopments: adolopments
      assessmentSections: assessmentSections
      conclusionsSections: conclusionsSections
      currentEditable: currentEditable
      etdId: etdId
      templateId: @props.template.getIn(['templateDef', 'id'])
      etdViewSettings: etdViewSettings.get(etdId, Immutable.Map())
      renderMode: renderMode
      questionType: @props.questionData.get 'type'
    conclusionsVotingData = @_getVotingDataForEtdPart 'conclusions'

    if _.isEmpty renderOptions
      <Conclusions {...baseProps}
        activeTab={@_getActiveVotingTab 'conclusions'}
        questionName={questionName}
        switchVotingTab={@switchVotingTab 'conclusions'}
        votingData={conclusionsVotingData}
      />
    else
      {conclusion} = renderOptions
      return null if (conclusion.checked is false)
      if conclusion.judgment.checked is false
        conclusionsSections = conclusionsSections.map (section) -> section.set 'content', ""
      <Conclusions {...baseProps}
        activeTab={@_getActiveVotingTab('conclusions')}
        attachments={@props.attachments}
        conclusionsSections={conclusionsSections}
        etdViewSettings={etdViewSettings}
        titleKey={'conclusions'}
        votingData={conclusionsVotingData}
      />

  _renderRecommendation: ->
    {
      adolopmentData
      adolopments
      conclusionsSections
      currentEditable
      etdId
      etdViewSettings
      renderMode
      renderOptions
      template
      overarchingQuestionData
    } = @props

    templateId = template.getIn(['templateDef', 'id'])
    sectionId = getCurrentTORSectionId conclusionsSections

    return null if not sectionId or ( _.string.startsWith(templateId, 'overarching') and
      templateId isnt 'overarchingTx-v2')
    section = conclusionsSections.get sectionId

    baseProps =
      adolopmentData: adolopmentData.getIn [etdId, 'conclusions'], Immutable.Map()
      adolopments: adolopments
      currentEditable: currentEditable
      etdId: etdId
      etdViewSettings: etdViewSettings
      overarchingQuestionData: overarchingQuestionData
      renderMode: renderMode
      section: section
      sectionId: sectionId
    votingData = @_getVotingDataForEtdPart 'recommendation'

    if _.isEmpty renderOptions
      <React.Fragment>
        <Recommendations
          {...baseProps}
          votingData={votingData}
          activeTab={@_getActiveVotingTab('recommendation')}
          switchVotingTab={@switchVotingTab 'recommendation'}
        />
        <br/>
      </React.Fragment>
    else
      { recommendation } = renderOptions
      return null if recommendation.checked is null
      # clear recommendation if needed
      if recommendation and recommendation.judgment.checked is false
        section = section.set('selectedOption', '')
        baseProps = _.extend baseProps, section: section
      <React.Fragment>
        <Recommendations
          activeTab={@_getActiveVotingTab('recommendation')}
          {...baseProps}
          votingData={votingData}
          switchVotingTab={@switchVotingTab 'recommendation'}
          etdViewSettings={etdViewSettings.get(etdId, Immutable.Map())}
        />
        <br/>
      </React.Fragment>

  _renderAssessment: ->
    {
      additionalConsiderations
      adolopmentData
      adolopments
      assessmentSections
      attachments
      collapsedAssessmentSections
      conclusionsSections
      criterions
      criticalOutcomes
      currentEditable
      etdId
      etdViewSettings
      hiddenSojSections
      outcomes
      overarchingQuestionData
      qualityIndicators
      renderMode
      renderOptions
      researchEvidences
      sojStatus
      template
      type
    } = @props
    return null if assessmentSections.isEmpty()
    # when export old voting renderOptions are empty {}
    unless _.isEmpty renderOptions
      # this means that user doesn't want to export assessment
      return null if renderOptions.assessment and renderOptions.assessment.checked is false
      # this means that user doesn't want to export preselected judgment
      if renderOptions.assessment and renderOptions.assessment.judgment.checked is false
        criterions = criterions.map (criterion) -> criterion.set 'selectedOption', ""

    [ intervention, comparison ] = @_getInterventionComparison()

    templateId = template.getIn(['templateDef', 'id'])
    additionalConsiderationsVisible = etdViewSettings.getIn(
      [etdId, 'showAdditionalConsiderations'], true)

    <React.Fragment>
      <Assessment
        activeTab={@_getActiveVotingTab 'judgements'}
        additionalConsiderations={additionalConsiderations}
        additionalConsiderationsVisible={additionalConsiderationsVisible}
        adolopmentData={adolopmentData.getIn([etdId, 'assessment'], Immutable.Map())}
        adolopments={adolopments}
        assessmentSections={assessmentSections}
        attachments={attachments}
        collapsedAssessmentSections={collapsedAssessmentSections.get(etdId)}
        comparison={comparison}
        conclusionsSections={conclusionsSections}
        criterions={criterions}
        criticalOutcomes={criticalOutcomes}
        currentEditable={currentEditable}
        etdId={etdId}
        etdViewSettings={etdViewSettings.get(etdId, Immutable.Map())}
        hiddenSojSections={hiddenSojSections.get(etdId)}
        intervention={intervention}
        keepSojStatus={@keepSojStatus}
        outcomes={outcomes}
        overarchingQuestionData={overarchingQuestionData}
        qualityIndicators={qualityIndicators}
        questionType={@_getQuestionType()}
        renderMode={renderMode}
        researchEvidences={researchEvidences}
        sojStatus={sojStatus}
        switchVotingTab={@switchVotingTab 'judgements'}
        templateId={templateId}
        type={type}
        votingData={@_getVotingDataForEtdPart 'assessment'}
      />
      <br />
    </React.Fragment>

  _isFetchingData: ->
    @props.isFetchingEtd or
      @props.isFetchingEtdVoting or
      @props.isFetchingReferences or
      @props.isFetchingQuestions or
      @props.isFetchingUserProjectData or
      @props.isFetchingAdolopment or
      @props.isFetchingOverarchingQuestion or
      @props.isFetchingQualityIndicators

  templateUndefined: ->
    <div>
      {@i18n 'template_undefined', {},
        link: <a href='#' onClick={@routeToAdministrationModule}>{@i18n 'etd_templates'}</a>
      }
    </div>

  getTemplateDialogMessage: ->
    @i18n '../default_template_message', templateName:
      @i18n '/settings:etd_templates.names.tx-crPp'

  shouldRenderHeader: ->
    { template, renderMode } = @props
    templateId = template.getIn ['templateDef', 'id'], ''
    return false if renderMode is 'mcSource'
    return false if templateId is 'mdg' and renderMode is 'printout'
    true

  render: ->
    {
      headerSections
      isHeaderEditing
      isHeaderExpanded
      keyMessagesData
      overarchingQuestionData
      projectFromOrganization
      questionData
      questions
      questionStatuses
      renderMode
      template
      templateUndefined
      type
      votingData
    } = @props

    templateId = template.getIn ['templateDef', 'id'], ''

    return @templateUndefined() if templateUndefined and not @_isFetchingData()
    return <Spinner /> if @_isFetchingData() or template.isEmpty()

    containerClasses = classNames
      'etd-only': votingData.isEmpty(),
      'etd-with-voting': not votingData.isEmpty(),

    <div className={containerClasses}>
      {if @shouldRenderHeader()
        <div>
          <Header
            headerSections={headerSections}
            isHeaderEditing={isHeaderEditing}
            isHeaderExpanded={isHeaderExpanded}
            keyMessagesData={keyMessagesData}
            overarchingQuestionData={overarchingQuestionData}
            projectFromOrganization={projectFromOrganization}
            question={questionData}
            questions={questions}
            questionStatuses={questionStatuses}
            questionType={@_getQuestionType()}
            renderMode={renderMode}
            type={type}
          />
        </div>
      }
      {templateId is 'overarchingTx-v2' and renderMode is 'printout' and
        <h2>{overarchingQuestionData.get('question')}</h2>
      }
      <div>
        {@_renderAssessment()}
        {@_renderRecommendation()}
        {@_renderConclusions()}
      </div>
      {if @props.showDefaultTemplateDialog
        <ConfirmationModal
          confirmLabel={@i18n '/actions.ok'}
          cancelLabel={@i18n '/actions.change_template'}
          isOpen
          question=''
          message={@getTemplateDialogMessage()}
          modalSize='standard'
          onCancel={@routeToAdministrationModule}
          onConfirm={EtdActions.hideDefaultTemplateDialog}
          title={@i18n '../default_template_dialog_title'}
          confirmClass=''
          cancelClass='btn-alternative'
        />
      }
    </div>

module.exports = ConnectStore(
  Etd
  [
    AdolopedEtdStore,
    AdolopmentDataStore,
    EtdStore,
    EtdVotingStore,
    KeyMessagesStore,
    OrganizationsStore,
    OverarchingQuestionsStore
    QualityIndicatorsStore
    QuestionsStatusesStore,
    QuestionsStore,
    ReferencesStore,
    UserProjectDataStore,
  ],
  storeConnectors
)
