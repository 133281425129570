var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, options, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, blockHelperMissing=helpers.blockHelperMissing, buffer = "<h2 class=\"title\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.displayOutcomes : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div class=\"choose-format\">\n  <label class=\"choose-format-title\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_export_format", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n  <div class=\"formats-list\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.displayOutcomes : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <label>\n      <input type=\"radio\" name=\"formatType\" value=\"doc\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.doc", {"name":"i18n","hash":{},"data":data})))
    + "\n    </label>\n    <label>\n      <input type=\"radio\" name=\"formatType\" value=\"html\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.html", {"name":"i18n","hash":{},"data":data})))
    + "\n    </label>\n    <label>\n";
  stack1 = ((helper = (helper = helpers.isConnected || (depth0 != null ? depth0.isConnected : depth0)) != null ? helper : helperMissing),(options={"name":"isConnected","hash":{},"fn":this.program(11, data),"inverse":this.program(13, data),"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.isConnected) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </label>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.jsonLdEnabled : depth0), {"name":"if","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </div>\n</div>\n<div class=\"choose-orientation\">\n  <label class=\"choose-orientation-title\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_page_orientation", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n  <div class=\"orientations-list\">\n    <label>\n      <input type=\"radio\" name=\"orientation\" value=\"landscape\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "orientations.landscape", {"name":"i18n","hash":{},"data":data})))
    + "\n      <span class=\"icon-orientation-landscape\"></span>\n    </label>\n    <label>\n      <input type=\"radio\" name=\"orientation\" value=\"portrait\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "orientations.portrait", {"name":"i18n","hash":{},"data":data})))
    + "\n      <span class=\"icon-orientation-portrait\"></span>\n    </label>\n  </div>\n</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.etdViewOptions : depth0), {"name":"if","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.appendices : depth0), {"name":"if","hash":{},"fn":this.program(23, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<div class=\"buttons\">\n  <div class=\"row mt-10\">\n    <div class=\"col-6\">\n      <button class=\"btn btn-block btn-cancel cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    </div>\n    <div class=\"col-6\">\n      <button class=\"btn btn-block btn-export download finish\" disabled>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "button_download", {"name":"i18n","hash":{},"data":data})))
    + "\n      </button>\n    </div>\n  </div>\n</div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<label class=\"choose-outcomes-title\">\n  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_up_to_outcomes", {"name":"i18n","hash":{
    'outcomesExportSize': ((depth0 != null ? depth0.outcomesExportSize : depth0))
  },"data":data})))
    + "\n</label>\n<div class=\"outcomes-to-export\">\n  <ul>\n      <li class=\"select-all\">\n          <input type=\"checkbox\" id=\"all\">\n          <label for=\"all\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "select_all", {"name":"i18n","hash":{},"data":data})))
    + "\n          </label>\n          <hr>\n      </li>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.outcomes : depth0), {"name":"each","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </ul>\n</div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <li>\n        <input name=\"exportOutcomes\" type=\"checkbox\" id=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n        <label for=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n          "
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.importanceLabel : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        </label>\n      </li>\n";
},"4":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "("
    + escapeExpression(((helper = (helper = helpers.importanceLabel || (depth0 != null ? depth0.importanceLabel : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"importanceLabel","hash":{},"data":data}) : helper)))
    + ")";
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "      <label>\n";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "SOF, SOF_ACCP, SOF_v3", (depth0 != null ? depth0.viewTypeName : depth0), {"name":"inStringArray","hash":{},"fn":this.program(7, data),"inverse":this.program(9, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </label>\n";
},"7":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"formatType\" value=\"rm5\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.rm5", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"9":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"formatType\" value=\"rm5\" disabled>\n        <span class=\"label-disabled\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.rm5", {"name":"i18n","hash":{},"data":data})))
    + "\n        </span>\n        <span class=\"question-mark-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.not_sof", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n";
},"11":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"formatType\" value=\"pdf\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.pdf", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"13":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"formatType\" value=\"pdf\" disabled>\n        <span class=\"label-disabled-disconnected\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.pdf", {"name":"i18n","hash":{},"data":data})))
    + "\n        </span>\n        <span class=\"question-mark-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.pdf_offline", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n";
},"15":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <label>\n        <input type=\"radio\" name=\"formatType\" value=\"jsonld\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.jsonld", {"name":"i18n","hash":{},"data":data})))
    + "\n      </label>\n";
},"17":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <label class=\"choose-etd-view-title\">\n    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_etd_view", {"name":"i18n","hash":{},"data":data})))
    + "\n  </label>\n  <div class=\"view-options\">\n    <label>\n      <span>\n        <input type=\"radio\" name=\"etdViewType\" value=\"blank\">\n      </span>\n      <span>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "blank_etd", {"name":"i18n","hash":{},"data":data})))
    + "\n      </span>\n    </label>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.etdViewOptions : depth0), {"name":"each","hash":{},"fn":this.program(18, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n";
},"18":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <label>\n        <span>\n          <input\n            type=\"radio\"\n            name=\"etdViewType\"\n            value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\"\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.checked : depth0), {"name":"if","hash":{},"fn":this.program(19, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.disabled : depth0), {"name":"if","hash":{},"fn":this.program(21, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n          >\n        </span>\n        <span>\n          "
    + escapeExpression(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"label","hash":{},"data":data}) : helper)))
    + "\n        </span>\n      </label>\n";
},"19":function(depth0,helpers,partials,data) {
  return "checked";
  },"21":function(depth0,helpers,partials,data) {
  return "disabled";
  },"23":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <label class=\"choose-appendices-title\">\n    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "export_as_appendix", {"name":"i18n","hash":{},"data":data})))
    + "\n  </label>\n  <div class=\"appendices-to-export\">\n    <ul>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.appendices : depth0), {"name":"each","hash":{},"fn":this.program(24, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </ul>\n  </div>\n";
},"24":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        <li>\n          <input name=\"exportAppendix\" type=\"checkbox\" id=\""
    + escapeExpression(((helper = (helper = helpers.appendixId || (depth0 != null ? depth0.appendixId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"appendixId","hash":{},"data":data}) : helper)))
    + "\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:recommendations.insert_modal", {"name":"i18nNamespace","hash":{},"fn":this.program(25, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </li>\n";
},"25":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "          <label for=\""
    + escapeExpression(((helper = (helper = helpers.appendixId || (depth0 != null ? depth0.appendixId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"appendixId","hash":{},"data":data}) : helper)))
    + "\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isImage : depth0), {"name":"if","hash":{},"fn":this.program(26, data),"inverse":this.program(28, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "          </label>\n";
},"26":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + " <b>("
    + escapeExpression(((helper = (helper = helpers.section || (depth0 != null ? depth0.section : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"section","hash":{},"data":data}) : helper)))
    + ")</b>\n";
},"28":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, (depth0 != null ? depth0.appendixId : depth0), {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "projects:export_dialog", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}