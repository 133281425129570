{ useState, Fragment } = React
{ useCoffeeCallback, useCoffeeEffect, useI18n } = require 'lib/react_utils'
Button = require 'components/common/button'

SidebarEdgeEditor = ({ currentElement, onElementRemove, onEdgeDataUpdate }) ->
  i18n = useI18n('algorithms:sidebar')

  arrowTypes = [
    {
      label: i18n('line'),
      arrowHeadType: null,
      iconSrc: 'images/svg/diagram_line.svg'
    },
    {
      label: i18n('double_sided_arrow'),
      arrowHeadType: 'doublearrow',
      iconSrc: 'images/svg/diagram_arrow_double.svg'
    },
    {
      label: i18n('target_arrow'),
      arrowHeadType: 'arrowtarget',
      iconSrc: 'images/svg/diagram_arrow_right.svg'
    },
    {
      label: i18n('source_arrow'),
      arrowHeadType: 'arrowsource',
      iconSrc: 'images/svg/diagram_arrow_left.svg'
    }
  ]

  [label, setLabel] = useState(currentElement.label ? '')
  [labelXYOffset, setLabelXYOffset] = useState(currentElement.labelXYOffset ? { x: 0, y: 0 })
  [arrowType, setArrowType] = useState(currentElement.arrowHeadType)

  handleLabelUpdate = useCoffeeCallback [onEdgeDataUpdate, setLabel], (evt) ->
    newLabel = evt.target.value
    onEdgeDataUpdate({ label: newLabel })
    setLabel(newLabel)

  handleArrowTypeUpdate =
    useCoffeeCallback [onEdgeDataUpdate, setArrowType], ({ arrowHeadType }) ->
      onEdgeDataUpdate({ arrowHeadType })
      setArrowType(arrowHeadType)

  handleLabelOffsetUpdate = (evt) ->
    labelOffsetKey = evt.target.name
    parsedLabelOffsetValue = parseInt(evt.target.value)
    labelOffsetValue = if isNaN(parsedLabelOffsetValue) then 0 else parsedLabelOffsetValue
    updatedLabelOffset = _.defaults({}, { "#{labelOffsetKey}": labelOffsetValue }, labelXYOffset)
    onEdgeDataUpdate({ labelXYOffset: updatedLabelOffset })
    setLabelXYOffset(updatedLabelOffset)

  useCoffeeEffect [currentElement, setLabel, setArrowType, setLabelXYOffset], () ->
    setLabel(currentElement.label ? '')
    setArrowType(currentElement.arrowHeadType)
    setLabelXYOffset(currentElement.labelXYOffset ? { x: 0, y: 0 })
  
  <Fragment>
    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('shape')}</label>
      <ul className='algorithms-sidebar__edge-editor-shapes'>
        {_.map arrowTypes, ({ label, arrowHeadType, iconSrc }) ->
          arrowTypeClass = classNames 'algorithms-sidebar__edge-editor-shape',
            'algorithms-sidebar__edge-editor-shape-selected': arrowType == arrowHeadType
          <li
            key={label}
            onClick={() -> handleArrowTypeUpdate({ arrowHeadType })}
            className={arrowTypeClass}
          >
            <img src={iconSrc} />
          </li>
        }
      </ul>
    </div>

    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('text')}</label>
      <input placeholder='Type here' value={label} onChange={handleLabelUpdate} />
    </div>

    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('label_offset')}</label>
      <div className='algorithms-sidebar__label-offset'>
        <div className='algorithms-sidebar__label-offset-field'>
          <label className='algorithms-sidebar__label'>x:</label>
          <input
            name='x'
            value={labelXYOffset.x}
            onChange={handleLabelOffsetUpdate}
            className='algorithms-sidebar__label-input'
          />
        </div>
        <div className='algorithms-sidebar__label-offset-field'>
          <label className='algorithms-sidebar__label'>y:</label>
          <input
            name='y'
            value={labelXYOffset.y}
            onChange={handleLabelOffsetUpdate}
            className='algorithms-sidebar__label-input'
          />
        </div>
      </div>
    </div>

    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('delete')}</label>
      <Button
        className='btn-cancel'
        label={i18n('delete_object')}
        onClick={onElementRemove}
      />
    </div>
  </Fragment>

SidebarEdgeEditor.propTypes =
  currentElement: PropTypes.object.isRequired
  onElementRemove: PropTypes.func.isRequired
  onEdgeDataUpdate: PropTypes.func.isRequired

module.exports = SidebarEdgeEditor
