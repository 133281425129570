var authorStringToObject, getAbstractNote, getAuthors, getDOI, getDate, getPages, getPublicationTitle, getRecordType, getTags, getTitle, getVolume, getXMLNodesTree, parse, recordNodeToObject, _getTextContentForSelector, _trimContent;

authorStringToObject = require('lib/references_utils').authorStringToObject;

_trimContent = function(text) {
  var _ref;
  return text != null ? (_ref = text.replace(/\s+/g, ' ')) != null ? _ref.trim() : void 0 : void 0;
};

_getTextContentForSelector = _.curry(function(selector, node) {
  var _ref;
  return _trimContent((_ref = node.querySelector(selector)) != null ? _ref.textContent : void 0);
});

getTitle = _getTextContentForSelector('titles title');

getVolume = _getTextContentForSelector('volume');

getPages = _getTextContentForSelector('pages');

getAbstractNote = _getTextContentForSelector('abstract');

getDOI = _getTextContentForSelector('electronic-resource-num');

getPublicationTitle = function(recordNode) {
  return _.chain(['titles secondary-title', 'periodical full-title']).map(function(selector) {
    return _getTextContentForSelector(selector)(recordNode);
  }).compact().first().value();
};

getRecordType = function(recordNode) {
  var _ref;
  return _trimContent((_ref = recordNode.querySelector('ref-type')[0]) != null ? _ref.getAttribute('name') : void 0);
};

getAuthors = function(recordNode) {
  var authorNodes;
  authorNodes = recordNode.querySelectorAll('contributors authors author');
  return _.chain(authorNodes).map(function(node) {
    var _ref, _ref1;
    return (_ref = (_ref1 = _trimContent(node.textContent)) != null ? _ref1.split(/; ?/) : void 0) != null ? _ref : [];
  }).flatten().map(authorStringToObject).value();
};

getDate = function(recordNode) {
  var date, dateNode, year, yearNode, _ref;
  dateNode = recordNode.querySelector('pub-dates');
  yearNode = recordNode.querySelector('dates year');
  if (dateNode && yearNode) {
    date = (_ref = dateNode.firstChild) != null ? _ref.textContent : void 0;
    year = yearNode.textContent;
    if (date.search(/\d{4}/) !== -1) {
      return date;
    }
    return _trimContent("" + date + " " + year);
  } else if (dateNode) {
    return _trimContent(dateNode.firstChild.textContent);
  } else if (yearNode) {
    return _trimContent(yearNode.textContent);
  }
};

getTags = function(recordNode) {
  var keywordNodes;
  keywordNodes = recordNode.querySelectorAll('keywords keyword');
  return _.map(keywordNodes, function(node) {
    return _trimContent(node.textContent);
  });
};

recordNodeToObject = function(recordNode) {
  return {
    itemType: getRecordType(recordNode),
    authors: getAuthors(recordNode),
    title: getTitle(recordNode),
    year: getDate(recordNode),
    publishedIn: getPublicationTitle(recordNode),
    volume: getVolume(recordNode),
    pages: getPages(recordNode),
    abstract: getAbstractNote(recordNode),
    tags: getTags(recordNode),
    DOI: getDOI(recordNode),
    recNumber: _getTextContentForSelector('rec-number', recordNode)
  };
};

getXMLNodesTree = function(XMLString) {
  return new DOMParser().parseFromString(XMLString, "text/xml");
};

parse = function(XMLString) {
  var doc, recordNodes;
  doc = getXMLNodesTree(XMLString);
  recordNodes = doc.querySelectorAll('record');
  return _.map(recordNodes, recordNodeToObject);
};

module.exports = parse;
