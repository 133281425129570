var OutcomeSchema, Rm5ExportTableCell, buildAbsoluteDifferencesRow, buildPrevalencesCells, buildRows, calculateRowSpan, formatters, outcomeName, rm5ExportConfig, rm5TbodyCells, suffixGetter, _getAbsoluteDifference, _getCellFootnotes,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

rm5ExportConfig = require('lib/rm5_export_config');

suffixGetter = require('lib/view_helper_utils').controlRiskFormatterSuffix;

formatters = require('lib/formatters');

rm5TbodyCells = rm5ExportConfig.tbodyCells;

Rm5ExportTableCell = rm5ExportConfig.Rm5ExportTableCell;

OutcomeSchema = {
  'outcomes': {
    'evidence_short': ['TP', 'TN', 'FP', 'FN'],
    'l1_sof': ['TP', 'TN', 'FP', 'FN'],
    'l2_sof': ['TP', 'TN', 'FP', 'FN', 'complications', 'inconclusive']
  },
  'cellsWithRowspan': ['quality', 'testAccuracy', 'designStudies', 'noOfParticipantsStudiesDiagnostic']
};

_getCellFootnotes = function(cell, footnotes) {
  var attribute, cellAttrsMapKey, cellFootnotes, cellPossibleAttributes, _i, _len;
  cellAttrsMapKey = cell === 'quality' ? 'qualityDx' : cell;
  cellPossibleAttributes = rm5ExportConfig.attributesMap[cellAttrsMapKey];
  cellFootnotes = [];
  if (!cellPossibleAttributes) {
    return '';
  }
  for (_i = 0, _len = cellPossibleAttributes.length; _i < _len; _i++) {
    attribute = cellPossibleAttributes[_i];
    if (attribute in footnotes) {
      cellFootnotes = cellFootnotes.concat(footnotes[attribute]);
    }
  }
  cellFootnotes.sort(function(a, b) {
    return a - b;
  });
  if (!_(cellFootnotes).isEmpty()) {
    return _(cellFootnotes).unique().join(' ');
  } else {
    return '';
  }
};

outcomeName = function(name) {
  if (name === 'Complications' || name === 'Inconclusive') {
    return name.toLowerCase();
  } else {
    return name;
  }
};

buildPrevalencesCells = function(cellName, attributes, question, row, tableView) {
  var cellNotes, cells, comparatorTestPresent, outcomeHasFootnotes, outcomeLabel, prevalences;
  comparatorTestPresent = question.get('comparatorTestPresent');
  prevalences = question.get('prevalences');
  outcomeLabel = outcomeName(attributes.name);
  cells = [];
  outcomeHasFootnotes = !_(attributes.footnotes).isEmpty();
  if (outcomeHasFootnotes) {
    cellNotes = _getCellFootnotes(cellName, attributes.footnotes);
  }
  if (outcomeLabel === 'inconclusive' || outcomeLabel === 'complications') {
    row.cells.push(new Rm5ExportTableCell({
      textContent: attributes["" + outcomeLabel + "PrevalencesDesc"],
      colspan: (comparatorTestPresent ? 2 : 1) * prevalences.length,
      name: "tdPrevalenceTextCell",
      sup: cellNotes || ''
    }));
  } else {
    prevalences.map(function(_arg, idx) {
      var cellData, num;
      num = _arg.num;
      cellData = {
        textContent: formatters.outcome.diagnosticEffect(question.toJS(), outcomeLabel, num),
        rowspan: 1,
        name: "tdPrevalence"
      };
      row.cells.push(new Rm5ExportTableCell(cellData));
      if (comparatorTestPresent) {
        cellData = {
          textContent: formatters.outcome.diagnosticEffect(question.toJS(), outcomeLabel, num, true),
          rowspan: 1,
          name: "tdPrevalence"
        };
        return row.cells.push(new Rm5ExportTableCell(cellData));
      }
    });
  }
  return row;
};

_getAbsoluteDifference = function(question, name, prevalenceNum) {
  return formatters.outcome.diagnosticAbsoluteDifference(question, name, prevalenceNum);
};

buildAbsoluteDifferencesRow = function(outcomeName, question, row, tableView) {
  if (tableView !== 'l1_sof' && tableView !== 'l2_sof') {
    row.cells.push(new Rm5ExportTableCell({
      textContent: "" + outcomeName + " " + ($.t('es:outcome.diagnostic.absolute_difference')),
      rowspan: 1,
      name: 'diagOutcomeAD'
    }));
  }
  question.get('prevalences').map(function(_arg, idx) {
    var cellData, num;
    num = _arg.num;
    cellData = {
      textContent: _getAbsoluteDifference(question.toJS(), outcomeName, num),
      rowspan: 1,
      colspan: 2,
      name: "prevalencesDesc"
    };
    return row.cells.push(new Rm5ExportTableCell(cellData));
  });
  if (tableView !== 'l1_sof' && tableView !== 'l2_sof') {
    row.cells.push(new Rm5ExportTableCell({
      textContent: '',
      rowspan: 1,
      name: "disabledImportance"
    }));
  }
  return row;
};

calculateRowSpan = function(question, outcomeName, cellName, tableView) {
  var comparatorTestPresent, getRowspan, multiplier;
  comparatorTestPresent = question.get('comparatorTestPresent');
  multiplier = comparatorTestPresent ? 2 : 1;
  getRowspan = function(cellName, multiplier) {
    if (__indexOf.call(OutcomeSchema.cellsWithRowspan, cellName) >= 0) {
      return 2 * multiplier;
    } else {
      return 1;
    }
  };
  switch (tableView) {
    case 'l1_sof':
      if (cellName === 'testResult') {
        return multiplier;
      }
      return getRowspan(cellName, multiplier);
    case 'l2_sof':
      if (outcomeName === 'inconclusive' || outcomeName === 'complications') {
        return 1;
      }
      if (cellName === 'testResult') {
        return multiplier;
      }
      return getRowspan(cellName, multiplier);
    case 'evidence_short':
      if (cellName === 'relativeImportance') {
        return 1;
      }
      return getRowspan(cellName, multiplier);
    default:
      return 1;
  }
};

buildRows = function(question, attributes) {
  var cellData, cellName, cellNotes, dichRows, firstRow, firstRowSchema, outcomeHasFootnotes, outcomeLabel, rowspan, secondRow, tableType, tableView, textContent;
  dichRows = [];
  tableView = attributes.tableView;
  tableType = "" + tableView + "_regular";
  firstRowSchema = rm5ExportConfig.theadSchemas[tableType][0];
  firstRow = {
    cells: []
  };
  outcomeLabel = outcomeName(attributes.name);
  outcomeHasFootnotes = !_(attributes.footnotes).isEmpty();
  if (__indexOf.call(OutcomeSchema['outcomes'][tableView], outcomeLabel) < 0) {
    return [];
  }
  for (cellName in firstRowSchema) {
    if (cellName === 'prevalences' || cellName === 'resultsPer1000') {
      firstRow = buildPrevalencesCells(cellName, attributes, question, firstRow, tableView);
    } else {
      textContent = cellName === 'testResult' ? rm5TbodyCells[cellName](attributes, {
        condition: question.get('targetCondition')
      }) : rm5TbodyCells[cellName](attributes);
      rowspan = calculateRowSpan(question, outcomeLabel, cellName, tableView);
      if (outcomeHasFootnotes) {
        cellNotes = _getCellFootnotes(cellName, attributes.footnotes);
      }
      cellData = {
        textContent: textContent,
        sup: cellNotes || '',
        rowspan: rowspan,
        name: cellName
      };
      if (outcomeLabel === 'TP' || outcomeLabel === 'TN' || outcomeLabel === 'complications' || outcomeLabel === 'inconclusive') {
        firstRow.cells.push(new Rm5ExportTableCell(cellData));
      } else {
        if (__indexOf.call(OutcomeSchema.cellsWithRowspan, cellName) < 0) {
          firstRow.cells.push(new Rm5ExportTableCell(cellData));
        }
      }
    }
  }
  dichRows.push(firstRow);
  if (question.get('comparatorTestPresent') && (outcomeLabel !== 'complications' && outcomeLabel !== 'inconclusive')) {
    secondRow = {
      cells: []
    };
    secondRow = buildAbsoluteDifferencesRow(outcomeLabel, question, secondRow, tableView);
    dichRows.push(secondRow);
  }
  return dichRows;
};

module.exports = function(question, attributes) {
  return buildRows(question, attributes);
};
