Tooltip = require 'components/common/tooltip'

Button = createReactClass
  displayName: 'Button'

  propTypes:
    className: PropTypes.string
    label: PropTypes.node
    shape: PropTypes.oneOf ['default', 'pill']

  getDefaultProps: ->
    shape: 'default'
    className: ''
    label: ''

  render: ->
    { shape, label, className } = @props
    classes = classNames className, 'btn-pill': shape is 'pill'

    restProps = _(@props).omit 'shape', 'label', 'className'

    <Tooltip>
      <button {...restProps} className={classes}>{label}</button>
    </Tooltip>

module.exports = Button
