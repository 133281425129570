Translation = require 'components/mixins/translation'
ModalVisibility = require 'components/mixins/modal_visibility'
IconButton = require 'components/common/icon_button'
Popover = require 'components/common/popover'
PopoverContent = require 'components/common/popover_content'
SubchapterTitleEditModal = require 'components/decision_aid/subchapter_title_edit_modal'
NewSubchapterModal = require 'components/decision_aid/new_subchapter_modal'
SubchapterTable = require 'components/decision_aid/subchapter_table'


controlNameProvider = R.compose $.t, R.concat 'da:details-screen.subchapter_controls.'

SUBCHAPTER_CONTROLS = [
  [ 'editTitle', controlNameProvider 'edit_title' ]
  [ 'manageOutcomes', controlNameProvider 'manage_outcomes']
  [ 'addNewSubchapter', controlNameProvider 'add_new_subchapter' ]
  [ 'duplicateSubchapter', controlNameProvider 'duplicate_subchapter' ]
  [ 'addIllustration', controlNameProvider 'add_illustration' ]
  [ 'addTable', controlNameProvider 'add_table' ]
  [ 'deleteSubchapter', controlNameProvider 'delete_subchapter' ]
]

SubchapterControls = createReactClass
  displayName: 'SubchapterControls'

  mixins: [
    Translation('da:details-screen'),
    ModalVisibility # provides @showModal, @hideModal, @isModalVisible methods
  ]

  propTypes:
    deletable: PropTypes.bool.isRequired
    subchapterTitle: PropTypes.string.isRequired
    subchapterTitleHidden: PropTypes.bool.isRequired
    onAddIllustration: PropTypes.func.isRequired
    handleAction: PropTypes.func.isRequired
    subchapterType: PropTypes.oneOf(['editor', 'table', 'outcomesTable']).isRequired
    onManageOutcomes: PropTypes.func.isRequired

  getSubchapterControls: ->
    { deletable, subchapterType } = @props
    forbiddenControls = switch subchapterType
      when 'outcomesTable' then ['duplicateSubchapter', 'addIllustration', 'deleteSubchapter']
      when 'table' then ['addIllustration', 'manageOutcomes']
      else
        ['manageOutcomes']

    forbiddenControls.push 'deleteSubchapter' unless deletable

    R.reject(
      R.compose(R.contains(R.__, forbiddenControls), R.head)
      SUBCHAPTER_CONTROLS
    )

  handleTitleApply: (newData) ->
    @handleTitleUpdate newData
    @hideModal 'SubchapterTitleEditModal'

  handleSubchapterControl: (evt) ->
    { controlName } = evt.target.dataset

    switch controlName
      when 'editTitle'
        @showModal 'SubchapterTitleEditModal'
      when 'addIllustration'
        @props.onAddIllustration()
      when 'duplicateSubchapter'
        @handleSubchapterDuplicate()
      when 'addNewSubchapter', 'addTable'
        @showModal 'NewSubchapterModal',
          newSubchapterType: if controlName is 'addTable' then 'table' else 'editor'
      when 'deleteSubchapter'
        @handleSubchapterDelete()
      when 'manageOutcomes'
        @props.onManageOutcomes()

    @hideModal 'SubchapterControls'

  handleTitleUpdate: (newData) ->
    @props.handleAction 'updateDecisionAidSubchapter', subchapterData: newData

  handleAddNewSubchapter: (payload) ->
    { newSubchapterType } = @state

    @props.handleAction 'addNewSubchapter', R.assoc 'type', newSubchapterType, payload
    @hideModal 'NewSubchapterModal', newSubchapterType: null

  handleSubchapterDelete: ->
    @props.handleAction 'deleteSubchapter'

  handleSubchapterDuplicate: ->
    @props.handleAction 'duplicateSubchapter'

  render: ->
    <div className='subchapter-controls'>
      <Popover
        visible={@isModalVisible 'SubchapterControls'}
        onRequestClose={=> @hideModal 'SubchapterControls'}
        positionParams={position: 'right', alignment: 'start'}
      >
        <IconButton iconName='gear' onClick={=> @showModal 'SubchapterControls'} />
        <PopoverContent>
          <div className='subchapter-controls-list'>
            {@getSubchapterControls().map ([ name, label ]) =>
              control =
                <div
                  key={name}
                  onClick={@handleSubchapterControl}
                  data-control-name={name}
                  className='subchapter-control'
                >
                  {label}
                </div>
              if name is 'deleteSubchapter'
                <div key={name}>
                  <hr />
                  {control}
                </div>
              else
                control
            }
          </div>
        </PopoverContent>
      </Popover>
      <SubchapterTitleEditModal
        isOpen={@isModalVisible 'SubchapterTitleEditModal'}
        titleHidden={@props.subchapterTitleHidden}
        onApply={@handleTitleApply}
        onClose={=> @hideModal 'SubchapterTitleEditModal'}
        title={@props.subchapterTitle}
      />
      <NewSubchapterModal
        isOpen={@isModalVisible 'NewSubchapterModal'}
        onApply={@handleAddNewSubchapter}
        onClose={=> @hideModal 'NewSubchapterModal'}
      />
    </div>

module.exports = SubchapterControls
