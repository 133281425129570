var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<div class=\"sof-outcome-label-edit\">\n  <div class=\"edit-input-block form-group row mt-10\">\n    <div>\n      <label for=\"outcome-name\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "name", {"name":"i18n","hash":{},"data":data})))
    + "\n        <span class=\"context-help-icon\" data-property=\"name\"></span>\n      </label>\n      <input id=\"outcome-name\" name=\"name\" type=\"text\" class=\"long\" value=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n      <div class=\"sup\"></div>\n    </div>\n  </div>\n  <div class=\"edit-input-block form-group row mt-10\">\n    <div>\n      <label for=\"outcome-short-name\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "short_name", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      <input id=\"outcome-short-name\" class=\"long\"\n        name=\"shortName\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.shortName || (depth0 != null ? depth0.shortName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"shortName","hash":{},"data":data}) : helper)))
    + "\">\n      <div class=\"sup\"></div>\n    </div>\n  </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.thresholdFeature : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <div class=\"edit-input-block form-group row mt-10\">\n    <div>\n      <label for=\"measured-with\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "measured_with", {"name":"i18n","hash":{},"data":data})))
    + "\n        <span class=\"context-help-icon\" data-property=\"measuredWith\"></span>\n      </label>\n      <input id=\"measured-with\" class=\"long\"\n        name=\"measuredWith\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.measuredWith || (depth0 != null ? depth0.measuredWith : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"measuredWith","hash":{},"data":data}) : helper)))
    + "\">\n      <div class=\"sup\"></div>\n    </div>\n  </div>\n\n";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "SOF, SOF_ACCP, SOF_v3", (depth0 != null ? depth0.viewTypeName : depth0), {"name":"inStringArray","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "cont", {"name":"is","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.sof3Table : depth0), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "    <div class=\"edit-input-block form-group row mt-10\">\n      <div>\n        <label for=\"utilityValue\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "utility_value", {"name":"i18n","hash":{},"data":data})))
    + "\n        </label>\n        <input id=\"utilityValue\" class=\"long\"\n          name=\"utilityValue\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.utilityValue || (depth0 != null ? depth0.utilityValue : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"utilityValue","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"sup\"></div>\n      </div>\n    </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.followUpLength, '    ', 'followUpLength', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <div class=\"edit-input-block form-group row mt-10 scale-type-select\">\n      <label>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_scale_type", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "scaleType", (depth0 != null ? depth0.scaleType : depth0), "ordinal: ordinal_measurement_scale, ratioOrInterval: ratio_interval_scale", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"edit-input-block form-group row mt-10 range-of-scores\" data-property=\"scaleTo\">\n";
  stack1 = this.invokePartial(partials.rangeOfScores, '      ', 'rangeOfScores', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </div>\n    <div class=\"edit-input-block form-group row mt-10 conventional-scale-limits\"\n      data-property=\"conventionalScaleLowerLimit\">\n";
  stack1 = this.invokePartial(partials.conventionalScaleLimits, '      ', 'conventionalScaleLimits', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n";
},"9":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.noOfParticipantsStudiesEmbedded, '  ', 'noOfParticipantsStudiesEmbedded', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "outcomeLabel", "outcome", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}