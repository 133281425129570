AddSectionCommentButton = ({ onClick }) ->
  <div className="add-section-comment-button" onMouseDown={onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="31.894"
      height="30.962"
      viewBox="0 0 31.894 30.962"
    >
      <defs>
        <filter id="a" x="0" y="0" width="31.894" height="30.962" filterUnits="userSpaceOnUse">
          <feOffset dy="3" in="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="b" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g filter="url(#a)" transform="matrix(1, 0, 0, 1, 0, 0)">
        <path
          fill="#fff"
          d="M-1337.709-25.038h0l-3.673-2.938h-7.882a.735.735,0,0,1-.734-.734v-8.55a.736.736,0,0,1,.734-.735h12.358c.022,0,.045,0,.067,0a.732.732,0,0,1,.5.193.737.737,0,0,1,.239.545v8.55a.736.736,0,0,1-.735.734h-.868v2.939Zm-8.147-8.685v1.32h1.944v2.052h1.4V-32.4h1.956v-1.32h-1.956v-2.028h-1.4v2.028Z"
          transform="translate(1359 44)"
        />
      </g>
    </svg>
  </div>

AddSectionCommentButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

module.exports = AddSectionCommentButton
