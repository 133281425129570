var cellToFormatterMap, defaultContent, outcomeFormatters, provider;

provider = require('lib/gdt_tables/cell_props_provider').provider;

outcomeFormatters = require('lib/formatters').outcome;

cellToFormatterMap = {
  importance: 'importanceLabel',
  riskOfBias: 'qualityModifier',
  inconsistency: 'qualityModifier',
  indirectness: 'qualityModifier',
  imprecision: 'qualityModifier',
  calculatedControlRisk: 'control',
  calculatedControlAbsoluteEffect: 'absoluteEffect',
  lowControlAbsoluteEffect: 'absoluteEffectLow',
  moderateControlAbsoluteEffect: 'absoluteEffectModerate',
  highControlAbsoluteEffect: 'absoluteEffectHigh',
  sofOutcomeLabel: 'sofLabel',
  sofV3OutcomeLabel: 'sofV3Label',
  oneRowOutcomeLabel: 'oneRowLabel',
  diagnosticOutcomeLabel: 'diagnosticLabel',
  calculatedControlCorrespondingRisk: 'correspondingRisk',
  lowControlCorrespondingRisk: 'correspondingRiskLow',
  moderateControlCorrespondingRisk: 'correspondingRiskModerate',
  highControlCorrespondingRisk: 'correspondingRiskHigh',
  riskDifferenceWithIntervention: 'riskDifference',
  lowControlRiskDifferenceWithIntervention: 'riskDifferenceLow',
  moderatewControlRiskDifferenceWithIntervention: 'riskDifferenceModerate',
  highControlRiskDifferenceWithIntervention: 'riskDifferenceHigh',
  events: 'effectCount',
  individuals: 'effectTotal',
  rate: 'effectRate'
};

defaultContent = function(cellId, outcomeAttrs) {
  var formatterArg, formatterFn, formatterName, _ref, _ref1;
  formatterName = (_ref = cellToFormatterMap[cellId]) != null ? _ref : cellId;
  formatterFn = outcomeFormatters[formatterName];
  if (formatterFn != null) {
    formatterArg = formatterName === 'qualityModifier' ? (_ref1 = outcomeAttrs[cellId]) != null ? _ref1 : {} : outcomeAttrs;
    return formatterFn.call(outcomeFormatters, formatterArg);
  } else {
    return outcomeAttrs[cellId];
  }
};

module.exports = provider('content', defaultContent);
