var GdtDocFieldType, ShapeType, TypeValidationError, object, parsingFail, shape, shapeCalculatedFieldsCalculator, shapeInvalidValueCheck, shapeMissinngKeysCheck, shapeUnkownKeyCheck, typeUtils, _ref,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __slice = [].slice;

GdtDocFieldType = require('lib/db_docs/field_types/FieldType');

TypeValidationError = require('lib/db_docs/field_types/type_validation_error');

_ref = require('lib/db_docs/field_types/built_in_types'), object = _ref.object, typeUtils = _ref.typeUtils;

ShapeType = (function(_super) {
  __extends(ShapeType, _super);

  function ShapeType() {
    return ShapeType.__super__.constructor.apply(this, arguments);
  }

  ShapeType.prototype.defaults = function(providedFields) {
    var fieldName, fieldType, fieldValue, isHigherOrderedType, providedFieldsNestedDefaults;
    if (this.is('optional') && (providedFields == null)) {
      return null;
    }
    if (providedFields == null) {
      providedFields = {};
    }
    if (_.isEmpty(providedFields)) {
      return this.parse(_.defaults(providedFields, this.typeDefault));
    } else {
      isHigherOrderedType = require('lib/db_docs/field_types/utils').isHigherOrderedType;
      providedFieldsNestedDefaults = {};
      for (fieldName in providedFields) {
        fieldValue = providedFields[fieldName];
        fieldType = this['_typeMeta'].valuesSpec[fieldName];
        if (fieldType == null) {
          continue;
        }
        providedFieldsNestedDefaults[fieldName] = isHigherOrderedType(fieldType) ? fieldType.defaults(fieldValue) : fieldValue;
      }
      return this.parse(_.defaults(providedFieldsNestedDefaults, this.typeDefault));
    }
  };

  return ShapeType;

})(GdtDocFieldType);

shapeMissinngKeysCheck = function(shapeSpec) {
  return function(shapeValue) {
    var Type, key, missingKeys;
    missingKeys = (function() {
      var _results;
      _results = [];
      for (key in shapeSpec) {
        Type = shapeSpec[key];
        if (!(key in shapeValue) && !(Type.is('optional') || Type.is('virtual'))) {
          _results.push(key);
        }
      }
      return _results;
    })();
    if (_.isEmpty(missingKeys)) {
      return shapeValue;
    } else {
      return new TypeValidationError({
        message: "Not all keys provided for `ShapeType`. Missing keys: " + (missingKeys.join(', ')),
        validation: 'ShapeType validation - missing keys',
        _meta: {
          missingKeys: missingKeys
        }
      });
    }
  };
};

shapeUnkownKeyCheck = function(shapeSpec) {
  return function(shapeValue) {
    var key, unknownKeys;
    unknownKeys = (function() {
      var _results;
      _results = [];
      for (key in shapeValue) {
        if (!(key in shapeSpec) || shapeSpec[key].is('virtual')) {
          _results.push(key);
        }
      }
      return _results;
    })();
    if (_.isEmpty(unknownKeys)) {
      return shapeValue;
    } else {
      return new TypeValidationError({
        message: "Unknown keys: [" + (unknownKeys.toString()) + "] provided for `ShapeType` type.",
        validation: 'ShapeType validation - unknown key',
        _meta: {
          unknownKeys: unknownKeys
        }
      });
    }
  };
};

shapeInvalidValueCheck = function(shapeSpec) {
  return function(shapeValue) {
    var fails, key, messageString, val, valueValidation;
    fails = [];
    for (key in shapeValue) {
      val = shapeValue[key];
      if (key in shapeSpec) {
        valueValidation = shapeSpec[key].check(val);
        if (valueValidation === val) {
          continue;
        }
        fails.push({
          key: key,
          message: valueValidation.message,
          expectedType: shapeSpec[key].name,
          providedType: typeUtils.getValueType(val)
        });
      }
    }
    if (_.isEmpty(fails)) {
      return shapeValue;
    } else {
      messageString = fails.map(function(_arg) {
        var key, message;
        key = _arg.key, message = _arg.message;
        return "Wrong value for key `" + key + "`. " + message;
      }).join(', ');
      return new TypeValidationError({
        message: messageString,
        validation: 'ShapeType validation - wrong value type',
        _meta: {
          fails: fails
        }
      });
    }
  };
};

shapeCalculatedFieldsCalculator = function(valuesSpec) {
  return function(data) {
    var calculatedFields, calculatedVal, fieldName, fieldType;
    calculatedFields = {};
    for (fieldName in valuesSpec) {
      fieldType = valuesSpec[fieldName];
      if (fieldType.is('calculated')) {
        calculatedVal = fieldType['_typeMeta'].valueFn(data);
        calculatedFields[fieldName] = fieldType.check(calculatedVal) instanceof TypeValidationError ? fieldType.typeDefault : calculatedVal;
      }
    }
    return _.extend(data, calculatedFields);
  };
};

parsingFail = function(keyName, initialFail, parsedValFail) {
  return new TypeValidationError({
    message: "Failed to parse `" + keyName + "` value. Message: " + parsedValFail.message,
    validation: 'ShapeType validation - wrong value type parse',
    _meta: {
      initialFails: initialFail['_meta'].fails,
      parseValueFailedValidation: parsedValFail.validation
    }
  });
};

shape = function(valuesSpec) {
  var _computeCalculatedFields;
  _computeCalculatedFields = shapeCalculatedFieldsCalculator(valuesSpec);
  return new ShapeType({
    name: 'ShapeType',
    check: typeUtils.checksProduct(object.check, shapeUnkownKeyCheck(valuesSpec), shapeInvalidValueCheck(valuesSpec), shapeMissinngKeysCheck(valuesSpec)),
    parse: function(shapeValue) {
      var checkResult, hasMissingKeys, hasUnknownKeys, key, missingKeysError, parsed, parsedVal, val, validation, valueType;
      checkResult = this.check(shapeValue);
      if (!(checkResult instanceof TypeValidationError)) {
        return this.check(_computeCalculatedFields(shapeValue));
      }
      if (checkResult instanceof TypeValidationError) {
        validation = checkResult.validation;
        if (validation === 'Type validation') {
          return checkResult;
        }
        hasUnknownKeys = validation === 'ShapeType validation - unknown key';
        if (hasUnknownKeys) {
          return checkResult;
        }
        missingKeysError = validation === 'ShapeType validation - missing keys';
        hasMissingKeys = missingKeysError && !checkResult['_meta'].missingKeys.some(function(key) {
          var KeyType;
          KeyType = valuesSpec[key];
          return KeyType.is('calculated') || KeyType.is('virtual');
        });
        if (hasMissingKeys) {
          return checkResult;
        }
      }
      parsed = {};
      for (key in shapeValue) {
        val = shapeValue[key];
        if (val instanceof TypeValidationError) {
          return val;
        }
        valueType = valuesSpec[key];
        if (valueType.is('calculated') || valueType.is('virtual')) {
          continue;
        }
        parsedVal = valueType.parse(val);
        if (parsedVal instanceof TypeValidationError) {
          return parsingFail(key, checkResult, parsedVal);
        }
        parsed[key] = parsedVal;
      }
      return this.check(_computeCalculatedFields(parsed));
    },
    typeDefault: (function() {
      var KeyType, key, result;
      result = {};
      for (key in valuesSpec) {
        KeyType = valuesSpec[key];
        if (!(KeyType.is('optional') || KeyType.is('virtual'))) {
          result[key] = KeyType.typeDefault;
        }
      }
      return _computeCalculatedFields(result);
    })(),
    _typeMeta: {
      valuesSpec: valuesSpec
    }
  });
};

shape.typeDecorators = {
  calculated: function(valueFn) {
    return function(typeRecord) {
      return typeRecord.update('_typeMeta', R.merge(R.__, {
        valueFn: valueFn,
        calculated: true
      }));
    };
  },
  optional: function(typeRecord) {
    var typeCheck;
    typeCheck = typeRecord.check;
    return typeRecord.merge({
      name: "Optional(" + (typeRecord.get('name')) + ")",
      check: function(val) {
        if (val != null) {
          return typeCheck(val);
        } else {
          return val;
        }
      },
      typeDefault: null
    }).update('_typeMeta', R.merge(R.__, {
      optional: true
    }));
  },
  virtual: function(valueFn) {
    return function(typeRecord) {
      return typeRecord.update('_typeMeta', R.merge(R.__, {
        valueFn: valueFn,
        virtual: true
      }));
    };
  }
};

shape.utils = {
  extend: function() {
    var combinedSpec, shapes, shapesProvided;
    shapes = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
    shapesProvided = shapes.every(function(shape) {
      return shape instanceof ShapeType;
    });
    if (!shapesProvided) {
      throw new Error('Wrong argument supplied: not an instance of `ShapeType`.');
    }
    combinedSpec = _.reduce(shapes, function(acc, shape) {
      return _.extend(acc, shape['_typeMeta'].valuesSpec);
    }, {});
    return shape(combinedSpec);
  }
};

shape.typeConstructor = ShapeType;

module.exports = shape;
