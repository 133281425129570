var ALGORITHM_PREFIX, AlgorithmComponent, AlgorithmSidebarView, AlgorithmToolbar, AlgorithmView, AlgorithmsActions, ReactComponent, ReactToolbarView, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

AlgorithmsActions = require('actions/algorithms_actions');

ALGORITHM_PREFIX = require('lib/doc_prefixes').algorithms;

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

AlgorithmToolbar = require('components/algorithms/algorithm_toolbar');

AlgorithmSidebarView = require('views/algorithms/algorithm_sidebar_view');

AlgorithmComponent = require('components/algorithms/algorithm');

mediator = require('mediator');

module.exports = AlgorithmView = (function(_super) {
  var algorithmId;

  __extends(AlgorithmView, _super);

  function AlgorithmView() {
    return AlgorithmView.__super__.constructor.apply(this, arguments);
  }

  AlgorithmView.prototype.container = '#page-container';

  AlgorithmView.prototype.className = 'algorithms-view';

  AlgorithmView.prototype.autoRender = true;

  algorithmId = null;

  AlgorithmView.prototype.initialize = function(options) {
    AlgorithmView.__super__.initialize.apply(this, arguments);
    this.algorithmId = options.algorithmId;
    this._stopInputsPropagation = false;
    return this.enable(ReactComponent);
  };

  AlgorithmView.prototype.afterRender = function() {
    AlgorithmView.__super__.afterRender.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: AlgorithmToolbar,
      props: {
        algorithmId: this.algorithmId
      }
    }));
    return this.renderComponent(AlgorithmComponent, AlgorithmsActions, null, {
      fetchAndListen: {
        dbId: mediator.project.id,
        opts: {
          filter: function(doc) {
            return doc._id.indexOf(ALGORITHM_PREFIX) === 0;
          }
        }
      },
      props: {
        algorithmId: this.algorithmId
      }
    });
  };

  AlgorithmView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    return AlgorithmView.__super__.dispose.apply(this, arguments);
  };

  return AlgorithmView;

})(View);
