var Rm5ExportTableCell, rm5ExportConfig, rm5TbodyCells, _getCellFootnotes;

rm5ExportConfig = require('lib/rm5_export_config');

rm5TbodyCells = rm5ExportConfig.tbodyCells;

Rm5ExportTableCell = rm5ExportConfig.Rm5ExportTableCell;

_getCellFootnotes = function(cell, footnotes) {
  var attribute, cellFootnotes, cellPossibleAttributes, _i, _len;
  cellPossibleAttributes = rm5ExportConfig.attributesMap[cell];
  cellFootnotes = [];
  for (_i = 0, _len = cellPossibleAttributes.length; _i < _len; _i++) {
    attribute = cellPossibleAttributes[_i];
    if (attribute in footnotes) {
      cellFootnotes = cellFootnotes.concat(footnotes[attribute]);
    }
  }
  cellFootnotes.sort(function(a, b) {
    return a - b;
  });
  if (!_(cellFootnotes).isEmpty()) {
    return _(cellFootnotes).unique().join(' ');
  } else {
    return '';
  }
};

module.exports = function(attributes, tableMode) {
  var calculateColspan, cellData, cellName, cellNotes, colspan, outcomeHasFootnotes, outcomeRow, placeholder, rowSchema, tableType, tableView, _ref;
  if (tableMode == null) {
    tableMode = 'regular';
  }
  tableView = attributes.tableView;
  tableType = "" + tableView + "_" + tableMode;
  rowSchema = rm5ExportConfig.theadSchemas[tableType][0];
  outcomeRow = {
    cells: []
  };
  outcomeHasFootnotes = !_(attributes.footnotes).isEmpty();
  for (cellName in rowSchema) {
    placeholder = (_ref = cellName.match(/\[[a-z]+\]/)) != null ? _ref[0] : void 0;
    cellName = cellName.replace(placeholder, '');
    if (cellName === 'absoluteEffect') {
      colspan = tableView === 'sof_v3' ? 4 : 2;
      if (outcomeHasFootnotes) {
        cellNotes = _getCellFootnotes('impact', attributes.footnotes);
      }
      cellData = {
        textContent: _.unescape(rm5TbodyCells['impact'](attributes)),
        colspan: colspan,
        name: 'impact'
      };
      if (cellNotes) {
        cellData.sup = cellNotes;
      }
      outcomeRow.cells.push(new Rm5ExportTableCell(cellData));
    } else if (cellName === 'relativeEffect') {
      if (tableView === 'sof_v3') {
        continue;
      }
      calculateColspan = function(cellName, tableView, tableMode) {
        if (tableMode === 'narrative') {
          return 1;
        }
        if (tableView === 'sof_short' && cellName === 'impact') {
          return 4;
        } else {
          return 1;
        }
      };
      cellName = tableView === 'sof_short' ? 'impact' : cellName;
      colspan = calculateColspan(cellName, tableView, tableMode);
      if (outcomeHasFootnotes) {
        cellNotes = _getCellFootnotes(cellName, attributes.footnotes);
      }
      outcomeRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells[cellName](attributes),
        sup: cellNotes || '',
        name: cellName,
        colspan: colspan
      }));
    } else {
      if (tableView === 'sof_short' && (cellName === 'sofControl' || cellName === 'correspondingRisk' || cellName === 'riskDifference')) {
        continue;
      }
      if (outcomeHasFootnotes) {
        cellNotes = _getCellFootnotes(cellName, attributes.footnotes);
      }
      outcomeRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells[cellName](attributes),
        sup: cellNotes || '',
        name: cellName
      }));
    }
  }
  return outcomeRow;
};
