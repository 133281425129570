mediator = require 'mediator'
Button = require 'components/common/button'
AnimatedCollectionItem = require 'components/enhancers/animated_collection_item'
WorkspaceSelectModal = require 'components/projects/workspace_select_modal'
Translation = require 'components/mixins/translation'


ProjectInvitation = createReactClass
  displayName: 'ProjectInvitation'

  propTypes:
    invitation: PropTypes.instanceOf(Immutable.Map).isRequired
    onAccept: PropTypes.func.isRequired
    onReject: PropTypes.func.isRequired

  mixins: [Translation('projects:welcome')]

  getInitialState: ->
    showWorkspaceSelectModal: false

  toggleWorkspaceSelectModal: ->
    @setState showWorkspaceSelectModal: not @state.showWorkspaceSelectModal

  handleAccept: (workspaceId = null) ->
    @props.onAccept @props.invitation, workspaceId

  handleReject: ->
    @props.onReject @props.invitation

  _getInvitationType: ->
    status = @props.invitation.get 'status'

    if status is 'invalid'
      @i18n('invalid_invitaion').toUpperCase()
    else
      createCopy = @props.invitation.get 'createCopy'
      @i18n("#{if createCopy then '../new_project' else 'invitation'}").toUpperCase()

  _getInvitationDetailsText: ->
    createCopy = @props.invitation.get 'createCopy'
    invitationDetailsKey = if createCopy then 'project_copy' else 'project_invitation'
    senderDetails = @props.invitation.get 'from'

    if senderDetails.get('firstName')? and senderDetails.get('lastName')?
      @i18n "#{invitationDetailsKey}_details_with_sender_name",
        name: @props.invitation.get 'name'
        fromName: senderDetails.get('firstName')
        fromLastName: senderDetails.get('lastName')
    else
      @i18n "#{invitationDetailsKey}_details",
        name: @props.invitation.get 'name'
        fromEmail: senderDetails.get 'email'

  _getRejectLabel: ->
    invitationStatus = @props.invitation.get 'status'
    createCopy = @props.invitation.get 'createCopy'

    @i18n if (createCopy or invitationStatus is 'invalid') then 'remove' else 'reject_invitation'

  _getAcceptHandler: ->
    createCopy = @props.invitation.get 'createCopy'

    if createCopy and not R.isEmpty(mediator.userOrganizations)
      @toggleWorkspaceSelectModal
    else
      @handleAccept.bind(null, 'personal')

  render: ->
    createCopy = @props.invitation.get 'createCopy'
    btnAcceptCls = classNames 'invitation-action',
      'invitation-accept': not createCopy
      'invitation-project-add': createCopy
    invitationStatus = @props.invitation.get 'status'
    cls = classNames 'projects-list__project--inner', 'project-invitation',
      invalid: invitationStatus is 'invalid'

    <div className={cls}>
      <div className='project-label' />
      <div className='project-invitation-details'>
        <div className='invitation-type'>
          {@_getInvitationType()}
        </div>
        <div className='invitation-details'>
          <span dangerouslySetInnerHTML={__html: @_getInvitationDetailsText()} />
        </div>
      </div>
      <div className='project-invitation-actions'>
        {unless invitationStatus is 'invalid'
          <Button
            className={btnAcceptCls}
            shape='pill'
            label={@i18n if createCopy then 'add_to_projects_list' else 'accept_invitation'}
            onClick={@_getAcceptHandler()}
            disabled={invitationStatus is 'accepted'}
          />
        }
        <Button
          className='invitation-action reject'
          shape='pill'
          label={@_getRejectLabel()}
          onClick={@handleReject}
          disabled={invitationStatus is 'accepted'}
        />
      </div>
      {if @state.showWorkspaceSelectModal
        <WorkspaceSelectModal
          onSelect={@handleAccept}
          onClose={@toggleWorkspaceSelectModal}
        />
      }
    </div>

module.exports = AnimatedCollectionItem ProjectInvitation, animationsDuration: 300
