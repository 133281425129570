Modal = require 'components/common/modal'
PersonalInformation = require './personal_information'
SubscriptionInformation = require './subscription_information'
LinkedAccounts = require './linked_accounts'
Translation = require 'components/mixins/translation'
mediator = require 'mediator'

AccountScreen = createReactClass
  displayName: 'AccountScreen'

  mixins: [Translation('account')]

  propTypes:
    authenticationService: PropTypes.shape
      changePersonalData: PropTypes.func.isRequired
    subscriptionService: PropTypes.shape
      info: PropTypes.func.isRequired
      checkoutAcademic: PropTypes.func.isRequired
      cancelAcademic: PropTypes.func.isRequired
      manage: PropTypes.func.isRequired
    externalAccountsService: PropTypes.shape
      getConnectionStatus: PropTypes.func.isRequired
      connect: PropTypes.func.isRequired
      disconnect: PropTypes.func.isRequired

  render: ->
    <Modal
      isOpen={true}
      onClose={@props.onClose}
      closeButton={true}
      title={@i18n('title')}
      className="account-view"
      style={content: {
        position: 'absolute'
        top: '50%'
        bottom: 'auto'
        left: '50%'
        right: 'auto'
        transform: 'translate(-50%, -50%)'
        minWidth: '680px'
      }}
    >
      <PersonalInformation user={mediator.user} service={@props.authenticationService} />
      <SubscriptionInformation service={@props.subscriptionService} />
      <LinkedAccounts service={@props.externalAccountsService} />
    </Modal>

module.exports = AccountScreen
