var DbDoc, decisionAidDocPrefix, decisionAidDocShape, docInstGetAt, docInstUpdateAt, findOutcomeIndex, generateDecisionAidDocId, generateGUID, mergeNewData, removeItemById, whereId, _ref;

DbDoc = require('lib/db_docs/db_doc');

generateGUID = require('base/lib/utils').generateGUID;

decisionAidDocShape = require('lib/db_docs/doc_shapes/decision_aid_doc_shape');

whereId = require('lib/lens_utils').whereId;

decisionAidDocPrefix = require('lib/doc_prefixes').decisionAid;

_ref = require('lib/db_docs/document_instance_helpers'), removeItemById = _ref.removeItemById, mergeNewData = _ref.mergeNewData, docInstUpdateAt = _ref.docInstUpdateAt, docInstGetAt = _ref.docInstGetAt;

generateDecisionAidDocId = function() {
  return "" + decisionAidDocPrefix + (generateGUID());
};

findOutcomeIndex = function(outcomeId, outcomes) {
  return R.findIndex(R.propEq('_id', outcomeId), outcomes);
};

module.exports = DbDoc(decisionAidDocShape, {
  dbSetup: {
    idPrefix: decisionAidDocPrefix
  },
  methods: {
    getQuestionField: function(fieldName) {
      return this.getAt('question', fieldName);
    },
    deleteChapter: function(chapterId) {
      return R.compose(R.ifElse(R.compose(R.isEmpty, docInstGetAt('chaptersOrder')), R.identity, docInstUpdateAt('chaptersOrder')(R.without([chapterId]))), docInstUpdateAt('chapters')(removeItemById(chapterId)))(this);
    },
    updateChapter: function(chapterId, newData) {
      return this.updateAt('chapters', chapterId)(mergeNewData(newData));
    },
    createChapter: function(newChapterData) {
      var chapterId;
      chapterId = _.str.underscored(newChapterData.title);
      return R.compose(R.ifElse(R.compose(R.isEmpty, docInstGetAt('chaptersOrder')), R.identity, docInstUpdateAt('chaptersOrder')(R.append(chapterId))), docInstUpdateAt('chapters')(function(chapters, chaptersCollectionType) {
        var chapter;
        chapter = chaptersCollectionType.getItemType().defaults(R.merge(newChapterData, {
          subchapters: [
            {
              title: ''
            }
          ]
        }));
        return R.assoc(chapterId, chapter, chapters);
      }))(this);
    },
    saveDecisionAidChaptersOrder: function(chaptersOrder) {
      return this.setAt('chaptersOrder')(chaptersOrder);
    },
    updateSubchapterIllustration: function(chapterId, subchapterIdx, illustrationData) {
      return this.updateAt('chapters', chapterId, 'subchapters', subchapterIdx, 'illustration')(mergeNewData(illustrationData));
    },
    deleteSubchapterIllustration: function(chapterId, subchapterIdx) {
      return this.updateAt('chapters', chapterId, 'subchapters', subchapterIdx)(R.dissoc('illustration'));
    },
    updateSubchapterContent: function(chapterId, subchapterIdx, content) {
      return this.setAt('chapters', chapterId, 'subchapters', subchapterIdx, 'content')(content);
    },
    updateSubchapter: function(chapterId, subchapterIdx, subchapterData) {
      return this.updateAt('chapters', chapterId, 'subchapters', subchapterIdx)(mergeNewData(subchapterData));
    },
    duplicateSubchapter: function(chapterId, subchapterIdx) {
      return this.updateAt('chapters', chapterId, 'subchapters')(function(subchapters, subchaptersCollectionType) {
        var duplicate;
        duplicate = subchaptersCollectionType.getItemType().defaults(R.omit(['_id'], subchapters[subchapterIdx]));
        return R.insert(subchapterIdx + 1, duplicate, subchapters);
      });
    },
    deleteSubchapter: function(chapterId, subchapterIdx) {
      return this.updateAt('chapters', chapterId, 'subchapters')(R.remove(subchapterIdx, 1));
    },
    addNewSubchapter: function(chapterId, subchapterIdx, position, newSubchapterData) {
      return this.updateAt('chapters', chapterId, 'subchapters')(function(subchapters, subchaptersCollectionType) {
        var newSubchapter, newSubchapterIdx;
        newSubchapterIdx = position === 'above' ? subchapterIdx : subchapterIdx + 1;
        newSubchapter = subchaptersCollectionType.getItemType().defaults(newSubchapterData);
        return R.insert(newSubchapterIdx, newSubchapter, subchapters);
      });
    },
    saveSubchapterTableContent: function(chapterId, subchapterIdx, tableContent) {
      return this.setAt('chapters', chapterId, 'subchapters', subchapterIdx, 'tableContent')(tableContent);
    },
    updateOutcome: function(outcomeId, payload, propagateAbsDenominatorChange) {
      var outcomeIdx;
      outcomeIdx = findOutcomeIndex(outcomeId, this.getQuestionField('outcomes'));
      return this.updateAt('question', 'outcomes')(function(outcomes) {
        outcomes = propagateAbsDenominatorChange && _.has(payload, 'absDenominator') ? outcomes.slice().map(function(outcome) {
          return _.extend(outcome, {
            absDenominator: parseInt(payload.absDenominator)
          });
        }) : outcomes;
        return R.update(outcomeIdx, R.merge(outcomes[outcomeIdx], payload), outcomes);
      });
    },
    updateOutcomeComment: function(outcomeId, comments) {
      return this.setAt('question', 'outcomes', whereId(outcomeId), 'comments')(comments);
    },
    updateQuestion: function(key, value) {
      return this.setAt('question', key)(value);
    },
    markRiskValuePreferred: function(outcomeId, riskKey, cellValue) {
      return this.updateAt('question', 'outcomes', whereId(outcomeId), "" + riskKey + "ControlFavours")(function(val) {
        if (val === cellValue) {
          return null;
        } else {
          return cellValue;
        }
      });
    }
  },
  middlewares: {
    create: function(doc) {
      doc._id = generateDecisionAidDocId();
      return doc;
    }
  }
});
