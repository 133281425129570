CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdActions = require 'actions/etd_actions'
Rating = require 'components/common/rating'
RankingDescription = require 'components/etd/judgements/ranking_description'
Select = require 'components/common/select_custom'
SummaryOfJudgementSectionNameCell = require 'components/etd/soj_section_name_cell'
Translation = require 'components/mixins/translation'
{ getTagsByKeys } = require 'lib/etd_helper'

RatingCellV2 = ({ tags, criterion, i18n, sectionHidden, optionsKey, valueKey }) ->
  <React.Fragment>
    {tags.map (tag) ->
      valuePath = if valueKey
        [optionsKey, tag.get('id'), valueKey]
      else
        [optionsKey, tag.get('id')]
      value = criterion.getIn valuePath
      options = criterion.get('options').concat(criterion.get('additionalOptions'), Immutable.List())
      selectedOption = options.find((option) -> option.get('value') is value)
      <td key={tag.get('id')}>
        {not sectionHidden and selectedOption and selectedOption.get('text')}
      </td>
    .toList()}
  </React.Fragment>

RatingCell = ({ tags, criterion, i18n, sectionHidden }) ->
  <React.Fragment>
    {if criterion.getIn(['rating', 'all'])
      <td colSpan={tags.size} className="dark-gray-cell bold">
        {not sectionHidden and i18n("ranking_judgement.#{criterion.getIn(['rating', 'all'])}")}
      </td>
    else
      tags.map (tag) ->
        <td key={tag.get('id')}>
          {not sectionHidden and
            <Rating
              readOnly
              rating={criterion.getIn(['rating', tag.get('id')], 0)}
              maxRate={3}
            />
          }
        </td>
      .toList()
    }
    </React.Fragment>

OverarchingSummaryOfJudgements = createReactClass
  displayName: "OverarchingSummaryOfJudgements"

  propTypes:
    templateId: PropTypes.string.isRequired
    overarchingQuestionData: PropTypes.instanceOf(Immutable.Map)

  getDefaultProps: ->
    overarchingQuestionData: Immutable.Map()

  mixins: [Translation('es:recommendations.table'), CustomRenderMixin]

  _getSelectedOption: (criterion) ->
    options = criterion.get('options').concat(criterion.get('additionalOptions'))
    selectedOption = options.find (option) -> option.get('value') is criterion.get('selectedOption')
    if selectedOption then selectedOption.get('text') else ''

  getImportancesList: ->
    _.map ['low', 'moderate', 'high'], (value) =>
      value: value
      text: @i18n "importance_value_#{value}"

  updateSectionImportance: (sectionId) -> (importance) ->
    EtdActions.changeImportance sectionId, importance, false

  getTags: ->
    { overarchingQuestionData } = @props
    getTagsByKeys overarchingQuestionData.get('includedTags'), ['intervention', 'indexTest']

  render: ->
    {
      criterions
      editable
      hiddenSections
      renderMode
      sections
      templateId
      toggleSection
      overarchingQuestionData
    } = @props

    tags = @getTags()
    tagsCount = tags.size

    isPrintout = renderMode is 'printout'
    isStartRating = templateId in ['overarchingDx-testCrPp', 'overarchingTx-crPp', 'qualityIndicators']
    importancesList = @getImportancesList()

    <div>
      <table className="summary-of-judgements interventions-rating">
        <thead className="header">
          <tr>
            {if isPrintout
              <React.Fragment>
                <th className="criteria" key="1" rowSpan="2" />
                {tags.map (tag) ->
                  <th className="blue-cell" key={tag.get('id')}>{tag.get('name')}</th>
                .toList()}
              </React.Fragment>
            else
              <React.Fragment>
                <th className="criteria blank" key="1">{@i18n 'criteria'}</th>
                {tags.map (tag) ->
                  <th className="blue-cell" key={tag.get('id')}>{tag.get('name')}</th>
                .toList()}
                <th className="importance short" key={tagsCount + 2}>
                  {@i18n 'importance_for_decision'}
                </th>
              </React.Fragment>
            }
          </tr>
        </thead>
        <tbody>
          {sections.map (section, key) =>
            criterionId = section.get 'criterionId'
            criterion = criterions.get criterionId, Immutable.Map()
            sectionHidden = hiddenSections.get(criterionId, false)
            rowClassNames = classNames "#{criterionId}", 'hidden-section': sectionHidden
            isRatingCell = section.get('rating') or section.get('perOutcomes') or
              section.get('parentSections') or section.get('perIntervention')

            [optionsKey, valueKey] = if section.get('perOutcomes') or section.get('parentSections')
              ['selectedOptions', 'overall']
            else
              ['rating', null]

            <tr key={key} className={rowClassNames}>
              <SummaryOfJudgementSectionNameCell
                displayCellContent={not sectionHidden or isPrintout}
                renderMode={renderMode}
                sectionHidden={sectionHidden}
                sectionName={section.get('name')}
                onHideSection={toggleSection criterionId}
              />
              {if isRatingCell and isStartRating
                <RatingCell
                  sectionHidden={sectionHidden}
                  tags={tags}
                  i18n={@i18n}
                  criterion={criterion}
                />
              else if isRatingCell
                <RatingCellV2
                  sectionHidden={sectionHidden}
                  tags={tags}
                  i18n={@i18n}
                  criterion={criterion}
                  optionsKey={optionsKey}
                  valueKey={valueKey}
                />
              else
                <td colSpan={tagsCount} className="dark-gray-cell bold">
                  {not sectionHidden and @_getSelectedOption criterion}
                </td>
              }
              {unless isPrintout
                <td>
                  {not sectionHidden and
                    <Select
                      className="review-table__importance-for-decision-dropdown"
                      options={importancesList}
                      selected={section.get('importance', '')}
                      onChange={@updateSectionImportance criterionId}
                    />
                  }
                </td>
              }
            </tr>
          .toList()}
        </tbody>
      </table>
      {isStartRating and <RankingDescription type="none" i18n={@i18n} />}
    </div>

module.exports = OverarchingSummaryOfJudgements
