var DashboardController, DashboardView, GdtRouter, MDG_WORKSPACE, ModuleController, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

DashboardView = require('views/dashboard/dashboard_view');

GdtRouter = require('router');

mediator = require('mediator');

MDG_WORKSPACE = require('lib/mdg_helper').MDG_WORKSPACE;

module.exports = DashboardController = (function(_super) {
  __extends(DashboardController, _super);

  function DashboardController() {
    return DashboardController.__super__.constructor.apply(this, arguments);
  }

  DashboardController.prototype.index = function() {
    if (!(mediator.services.switches.isServerSwitchOn('mdgFeatures') && mediator.user.isAdmin())) {
      this.redirectTo(GdtRouter.prototype.routeForModule('evidence-syntheses', {
        projectId: mediator.project.id
      }));
      return;
    }
    return this.view = new DashboardView;
  };

  return DashboardController;

})(ModuleController);
