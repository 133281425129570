var OverarchingQuestionDoc, W, isImmutable, mediator, templateHelpers, templateLanguages, templatesDefinitions, utils;

templatesDefinitions = require('lib/etd_templates/templates_definitions');

templateLanguages = require('lib/etd_templates/templates_languages');

templateHelpers = require('stores/utils/template_helpers');

mediator = require('mediator');

W = require('when/when');

utils = require('base/lib/utils');

OverarchingQuestionDoc = require('lib/db_docs/overarching_question_doc');

isImmutable = require('lib/immutable_utils').isImmutable;

_.namespace(module, function(require) {
  var helpers;
  helpers = templateHelpers(templateLanguages);
  return {
    getEtdTemplateType: function(template) {
      var templateDefId;
      templateDefId = isImmutable(template) ? template.getIn(['templateDef', 'id']) : template.templateDef.id;
      if (_.string.startsWith(templateDefId, 'tx')) {
        return 'tx';
      } else if (_.string.startsWith(templateDefId, 'dx')) {
        return 'dx';
      } else if (_.string.startsWith(templateDefId, 'keyMessage')) {
        return 'keyMessage';
      } else if (_.string.startsWith(templateDefId, 'qualityIndicators')) {
        return 'qualityIndicators';
      } else if (_.string.startsWith(templateDefId, 'overarchingTx')) {
        return 'overarchingTx';
      } else {
        return 'overarchingDx';
      }
    },
    getEtdTemplateDocId: function(typeCode) {
      return "etd-" + typeCode + "-template";
    },
    isAcpFeaturesOn: function() {
      return mediator.services.switches.isServerSwitchOn('acpFeatures');
    },
    getBaseTemplateIdForType: function(type) {
      switch (type) {
        case 'tx':
          return 'tx-crPp';
        case 'dx':
          return 'dx-testCrPp';
        case 'overarchingTx':
          if (this.isAcpFeaturesOn()) {
            return 'overarchingTx-acp';
          } else {
            return 'overarchingTx-v2';
          }
          break;
        case 'overarchingDx':
          return 'overarchingDx-acp';
        case 'keyMessage':
          return 'keyMessage';
        case 'goodPracticeStatement':
          return "good-practice-statement";
        case 'mdg':
          return 'mdg';
        case 'qualityIndicators':
          return 'qualityIndicators';
      }
    },
    prepareBaseTemplate: function(templateId, language) {
      if (language == null) {
        language = templateLanguages.defaultLanguage;
      }
      return this.prepareTemplate(Immutable.fromJS(_(templatesDefinitions()).findWhere({
        id: templateId
      })).set('lang', language).set('name', i18next.t("settings:etd_templates.names." + templateId)), Immutable.fromJS({}), true);
    },
    prepareTemplate: function(templateDef, templateData, forCustomization, preserveCurrentSections) {
      if (forCustomization == null) {
        forCustomization = false;
      }
      return helpers.prepareTemplate(templateDef, templateData, forCustomization, preserveCurrentSections, ['question', 'assessment', 'conclusions', 'presentations']);
    },
    updateTemplate: function(currentTemplate, newTemplate) {
      return helpers.updateTemplate(currentTemplate, newTemplate, ['question', 'assessment', 'conclusions', 'presentations']);
    },
    updateTemplatesInQuestions: function(dbName, newTemplate, templateType) {
      var filteredQuestions, recIds;
      if (templateType.indexOf('overarching') === -1) {
        filteredQuestions = mediator.questions.filter(function(q) {
          var _ref, _ref1;
          if (templateType === 'keyMessage') {
            return q.get('type') === 'keyMessage';
          } else if (templateType === 'dx') {
            return q.isDiagnostic() && ((_ref = q.get('type')) !== 'keyMessage' && _ref !== 'good-practice-statement');
          } else {
            return !q.isDiagnostic() && ((_ref1 = q.get('type')) !== 'keyMessage' && _ref1 !== 'good-practice-statement');
          }
        });
        recIds = _(filteredQuestions).chain().pluck('attributes').pluck('recommendationIds').flatten().uniq().value();
        return this.updateTemplatesInRecommendations(dbName, recIds, newTemplate);
      } else {
        return OverarchingQuestionDoc.at(mediator.project.id).fetchMany().then(function(docs) {
          return _.chain(docs).compact().filter(function(doc) {
            var docType;
            docType = templateType.indexOf('Tx') !== -1 ? 'overarching:management' : "overarching:diagnostic";
            return doc.type === docType;
          }).map(function(doc) {
            return doc.recommendationIds;
          }).flatten().uniq().value();
        }).then((function(_this) {
          return function(recIds) {
            return _this.updateTemplatesInRecommendations(dbName, recIds, newTemplate);
          };
        })(this));
      }
    },
    updateTemplatesInRecommendations: function(dbName, recIds, newTemplate) {
      return mediator.services.storePersistenceAdapter.fetch(dbName, recIds).then((function(_this) {
        return function(res) {
          var etdDoc, etdDocs, saveToDb, updatedDocs;
          etdDocs = _(res.rows).pluck('doc');
          updatedDocs = Immutable.List((function() {
            var _i, _len, _results;
            _results = [];
            for (_i = 0, _len = etdDocs.length; _i < _len; _i++) {
              etdDoc = etdDocs[_i];
              _results.push(this.updateTemplate(Immutable.fromJS(etdDoc), newTemplate));
            }
            return _results;
          }).call(_this));
          saveToDb = mediator.services.storePersistenceAdapter.save;
          return W.all((updatedDocs.map(function(doc) {
            return saveToDb(dbName, doc.toJS());
          })).toJS())["catch"](function(e) {
            return utils.reportRavenError(e);
          });
        };
      })(this));
    },
    setBaseTemplates: function(projectId, notSetQuestionType) {
      var anotherQuestionType, anotherTemplateDocId, questionTypesToSetTemplate;
      questionTypesToSetTemplate = [notSetQuestionType];
      anotherQuestionType = notSetQuestionType === 'tx' ? 'dx' : 'tx';
      anotherTemplateDocId = this.getEtdTemplateDocId(anotherQuestionType);
      return mediator.services.storePersistenceAdapter.exists(projectId, anotherTemplateDocId).then((function(_this) {
        return function(anotherTypeIsSet) {
          if (!anotherTypeIsSet) {
            questionTypesToSetTemplate.push(anotherQuestionType);
          }
          return mediator.services.storePersistenceAdapter.bulkDocs(projectId, questionTypesToSetTemplate.map(function(questionTypeCode) {
            var baseTemplateId, templateDoc;
            baseTemplateId = _this.getBaseTemplateIdForType(questionTypeCode);
            templateDoc = _this.prepareBaseTemplate(baseTemplateId).toJS();
            return R.assoc('_id', _this.getEtdTemplateDocId(questionTypeCode), templateDoc);
          }));
        };
      })(this));
    }
  };
});
