AdolopmentDataActions = require 'actions/adolopment_data_actions'
AdolopmentConclusionsSection = require 'components/etd/adolopment_conclusions_section'
AdolopmentSectionSeparator = require 'components/etd/adolopment_section_separator'
AdolopmentRecommendationDecisionSection =
  require 'components/etd/adolopment_recommendation_decision_section'
ConclusionsSection = require 'components/etd/conclusions_section'
ConclusionsSectionWithVoting = require 'components/etd/conclusions_section_with_voting'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdActions = require 'actions/etd_actions'
EtdPart = require 'components/etd/etd_part'
EtdSectionTitle = require 'components/etd/etd_section_title'
EtdVotingMixin = require 'components/mixins/etd_voting_mixin'

RecommendationDecisionSection = require 'components/etd/recommendation_decision_section'
RecommendationDecisionSectionWithVoting =
  require 'components/etd/recommendation_decision_section_with_voting'
Switcher = require 'components/common/switcher'
Translation = require 'components/mixins/translation'
VotingTabs = require 'components/etd/voting_tabs'
{ getOrderedSections } = require 'lib/etd_helper'
{ Card, CardContent } = ReactComponents
UserProjectDataActions = require 'actions/user_project_data_actions'

sectionIsCollapsed = (collapsedSections) -> (sectionKey) ->
  collapsedSections.get(sectionKey) is true

Conclusions = createReactClass
  displayName: 'ConclusionsSectionsContainer'
  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
    EtdVotingMixin
  ]

  propTypes:
    adolopmentData: PropTypes.instanceOf(Immutable.Map).isRequired
    adolopments: PropTypes.instanceOf(Immutable.Map).isRequired
    etdId: PropTypes.string.isRequired
    etdViewSettings: PropTypes.instanceOf(Immutable.Map).isRequired

  getDefaultProps: ->
    titleKey: 'conclusions'

  _isConsensus: ->
    @props.activeTab? and @props.activeTab isnt 'draftJudgement'

  _getSectionProps: (sectionData, sectionId) ->
    adolopmentData: @props.adolopmentData
    adolopments: @props.adolopments
    assessmentSections: @props.assessmentSections
    attachments: @props.attachments
    editable: if @props.editable? then @props.editable else @_inEditMode()
    etdId: @props.etdId
    etdViewSettings: @props.etdViewSettings
    isConsensus: @_isConsensus()
    renderMode: @props.renderMode
    section: sectionData
    sectionId: sectionId

  toggleAdolopmentForSection: (sectionId) -> =>
    AdolopmentDataActions.toggleAdolopmentForSection @props.etdId, 'conclusions', sectionId

  toggleAdolopmentForSections: (sectionIds) -> =>
    AdolopmentDataActions.toggleAdolopmentForSections @props.etdId, 'conclusions', sectionIds

  toggleCustomSections: (originalOrAdolopedSections, collapse) -> =>
    recAndJustIds = @props.conclusionsSections.filter (s, sectionId) ->
      sectionId in ['recommendation', 'decision', 'justification']
    .keySeq().toJS()

    if (originalOrAdolopedSections is 'original')
      UserProjectDataActions.toggleOriginalDataSections @props.etdId, recAndJustIds, collapse
    else
      UserProjectDataActions.toggleAdolopmentDataSections @props.etdId, recAndJustIds, collapse

  sectionMapper: (sectionData, sectionId) ->
    sectionProps = @_getSectionProps sectionData, sectionId
    SectionComponent = if sectionId in ['recommendation', 'decision']
      RecommendationDecisionSection
    else
      ConclusionsSection

    withAdolopment = @props.adolopmentData.getIn([sectionId, 'withAdolopment'], false)

    <div key={sectionId}>
      {not @props.adolopments.isEmpty() and @props.renderMode isnt 'printout' and
        <div className="conclusions__adolopment-switcher">
          <Switcher
            buttonText={@i18n '/es:recommendations.table-view-options.toggle_adolopment'}
            checked={withAdolopment}
            onChange={@toggleAdolopmentForSection(sectionId)}
          />
        </div>
      }
      <SectionComponent
        contentColSpan={if @props.adolopments.isEmpty() then 1 else 2}
        withAdolopment={withAdolopment}
        {...sectionProps}
      />
    </div>

  recommendationAndJustificationAdolopment: (sectionData, sectionId) ->
    {
      adolopmentData
      adolopments
      assessmentSections
      assessmentSections
      conclusionsSections
      etdViewSettings
      renderMode
      etdId
    } = @props

    recAndJustIds = conclusionsSections.filter (s, sectionId) ->
      sectionId in ['recommendation', 'decision', 'justification']

    withAdolopment = recAndJustIds.every (section, sectionId) ->
      adolopmentData.getIn [sectionId, 'withAdolopment'], false

    conclusionsSectionsToBeDisplayedAsCurrent = if not withAdolopment
      adolopedData = adolopments.last()
      getOrderedSections(adolopedData.getIn(['templateData', 'conclusions'], Immutable.Map()))
    else
      conclusionsSections

    recAndJust = conclusionsSectionsToBeDisplayedAsCurrent.filter (s, sectionId) ->
      sectionId in ['recommendation', 'decision', 'justification']

    contentColSpan = 2

    collapsedOriginalSections = etdViewSettings.get('collapsedOriginalSections', Immutable.Map())
    collapsedAdolopmentSections = etdViewSettings.get('collapsedAdolopmentSections',
      Immutable.Map())

    originalRAndJCollapsed = recAndJustIds.keySeq().every sectionIsCollapsed(
      collapsedOriginalSections)
    adolopedRAndJCollapsed = recAndJustIds.keySeq().every sectionIsCollapsed(
      collapsedAdolopmentSections)

    adolopedDataComponents = recAndJust.map (section, sectionId) =>
      return null if etdViewSettings.getIn(['collapsedOriginalSections', sectionId], false)
      if sectionId in ['recommendation', 'decision']
        contentCellClass = classNames
          content: section.get('content')? and not section.get 'options'
        <AdolopmentRecommendationDecisionSection
          adolopments={adolopments}
          attachments={@props.attachments}
          contentCellClass={contentCellClass}
          i18n={@i18n}
          isConsensus={@_isConsensus()}
          key={"#{sectionId}-adolopments"}
          section={section}
          sectionId={sectionId}
        />
      else
        <AdolopmentConclusionsSection
          adolopments={adolopments}
          assessmentSections={assessmentSections}
          attachments={@props.attachments}
          contentCellClass={contentCellClass}
          contentColSpan={contentColSpan}
          isConsensus={@_isConsensus()}
          key={"#{sectionId}-adolopments"}
          renderMode={renderMode}
          section={section}
          sectionId={sectionId}
          withSectionTitle={true}
        />

    sectionsComponents = recAndJust
      .map (sectionData, sectionId) =>
        return null if etdViewSettings.getIn(['collapsedAdolopmentSections', sectionId], false)
        sectionProps = _.extend {},
          @_getSectionProps(sectionData, sectionId),
          adolopments: Immutable.Map()
          editable: adolopments.isEmpty() or withAdolopment
        SectionComponent = if sectionId in ['recommendation', 'decision']
          RecommendationDecisionSection
        else
          ConclusionsSection
        <SectionComponent
          contentColSpan={contentColSpan}
          key={sectionId}
          withAdolopment={withAdolopment}
          {...sectionProps}
        />

    <div>
      {renderMode isnt 'printout' and
        <div className="conclusions_rec_just__adolopment-switcher">
          <Switcher
            buttonText={@i18n '/es:recommendations.table-view-options.toggle_adolopment'}
            checked={withAdolopment}
            onChange={@toggleAdolopmentForSections(recAndJust.keySeq().toJS())}
          />
        </div>
      }
      {[
        withAdolopment && <table
            key="adolopment"
            className="standard-table recommendation-table-static"
          >
          <tbody>
            <AdolopmentSectionSeparator
              i18n={@i18n}
              section="original"
              type="conclusions"
              onClick={@toggleCustomSections('original', not originalRAndJCollapsed)}
            />
          </tbody>
          {adolopedDataComponents.toList()}
          <tbody>
            <AdolopmentSectionSeparator
              i18n={@i18n}
              section="adolopment"
              type="conclusions"
              onClick={@toggleCustomSections('adolopment', not adolopedRAndJCollapsed)}
            />
          </tbody>
        </table>
        ,
        sectionsComponents.toList()
      ]}
    </div>

  sectionWithVoting: (sectionData, sectionId) ->
    sectionProps = @_getSectionProps sectionData, sectionId
    votingProps = if @_hasVotingStarted() then @_getVotingPropsForSection(sectionId) else {}

    SectionComponent = if sectionId in ['recommendation', 'decision']
      RecommendationDecisionSectionWithVoting
    else
      ConclusionsSectionWithVoting

    <SectionComponent
      {...sectionProps}
      {...votingProps}
    />

  render: ->
    {
      activeTab,
      adolopments,
      conclusionsSections,
      questionName,
      renderMode,
      titleKey,
      templateId
    } = @props

    if conclusionsSections and not conclusionsSections.isEmpty()

      <EtdPart
        titleKey={if templateId in ['good-practice-statement', 'mdg'] then null else titleKey}
        activeTab={activeTab}
        className="conclusions-section"
        onTabClick={@switchVotingTab}
        renderMode={renderMode}
      >
        {if (@_hasVotingStarted() and activeTab is 'votingResults')
          conclusionsSections.map((sectionData, sectionId) =>
            <Card key={sectionId}>
              <CardContent>
               {@sectionWithVoting(sectionData, sectionId)}
              </CardContent>
            </Card>
          ).toList()
        else if not adolopments.isEmpty()
          <Card>
            <CardContent>
              {@recommendationAndJustificationAdolopment()}
              {conclusionsSections.filterNot (s, sectionId) ->
                sectionId in ['recommendation', 'decision', 'justification']
              .map(@sectionMapper).toList()}
            </CardContent>
          </Card>
        else
          <Card>
            <CardContent>
              {conclusionsSections.map(@sectionMapper).toList()}
            </CardContent>
          </Card>
        }
      </EtdPart>
    else
      null

module.exports = Conclusions
