EtdActions = require 'actions/etd_actions'
EtdStore = require 'stores/etd_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
ContentEditable = require 'components/common//content_editable'
ButtonsRow = require 'components/common/buttons_row'
Button = require 'components/common/button'
HorizontalRadios = require './horizontal_radios'
GeneralQuestions = require './general_questions'
OutcomesQuestions = require './outcomes_questions'
ResourcesQuestions = require './resources_questions'

DetailedJudgements = createReactClass
  displayName: 'DetailedJudgements'
  mixins: [CustomRenderMixin, Translation('es:recommendations.table.detailed_judgements')]

  propTypes:
    sectionId: PropTypes.string.isRequired
    sectionName: PropTypes.string.isRequired
    criterionDescription: PropTypes.string.isRequired
    details: PropTypes.object.isRequired
    finalJudgementOptions: PropTypes.object.isRequired
    finalJudgementAdditionalOptions: PropTypes.object.isRequired
    finalJudgementSelectedOption: PropTypes.string.isRequired
    onApply: PropTypes.func.isRequired
    onCancel: PropTypes.func.isRequired

  updatePanelDiscussion: (e) ->
    @_debouncedUpdatePanelDiscussion ?= _.debounce(EtdActions.updatePanelDiscussion, 200)
    @_debouncedUpdatePanelDiscussion @props.sectionId, e.currentTarget.innerHTML,
      @props.isConsensus

  getCriterionDescription: (criterionAnswer) ->
    EtdStore.getCriterionDescription criterionAnswer.get 'criterionId'

  getSelectedOptionText: (criterionAnswer) ->
    EtdStore.getSelectedOptionText criterionAnswer.get 'criterionId'

  _getPanelDiscussion: ->
    if @props.isConsensus
      @props.details.get('panelDiscussionConsensus') or @props.details.get 'panelDiscussion'
    else
      @props.details.get 'panelDiscussion'

  render: ->
    <div>
      <div>
        <span className="section-name">{@props.sectionName}</span>:
        <span className="criterion-description">{@props.criterionDescription}</span>
      </div>
      {if @props.details.get 'additionalInfos'
        <div className="additional-infos">
          {@props.details.get('additionalInfos').map (additionalInfo, idx) =>
            <div key={idx} className="additional-info">
              <div>{additionalInfo.get 'description'}</div>
              {
                if additionalInfo.get 'criteriaAnswers'
                  additionalInfo.get('criteriaAnswers').map (criterionAnswer, idx) =>
                    <div key={idx}>
                      <span>{@getCriterionDescription criterionAnswer}</span>:
                      <span>{@getSelectedOptionText criterionAnswer}</span>
                    </div>
                else if additionalInfo.get 'additions'
                  additionalInfo.get('additions').map (addition, idx) ->
                    <div key={idx}>{addition}</div>
              }
            </div>
          }
        </div>
      }
      <div className="panel-discussion">
        {@i18n 'panel_discussion'}
        <ContentEditable
          contentEditable={@props.editable}
          className="discussion-content"
          onInput={@updatePanelDiscussion}
          html={@_getPanelDiscussion()} />
      </div>
      {if @props.details.get 'questions'
        <div className="details">
          <span className="inner-label">{@i18n 'detailed_questions'}</span>
          {switch "#{@props.details.get('questionsType')}-#{@props.questionType}"
            when 'general-tx', 'general-dx'
              <GeneralQuestions
                editable={@props.editable}
                sectionId={@props.sectionId}
                isConsensus={@props.isConsensus}
                groupPrefix='g1'
                questions={@props.details.get 'questions'}/>
            when 'outcomes-tx'
              <OutcomesQuestions
                editable={@props.editable}
                sectionId={@props.sectionId}
                isConsensus={@props.isConsensus}
                groupPrefix='o1'
                questions={@props.details.get 'questions'}
                criticalOutcomes={@props.criticalOutcomes}
              />
            # treat 'outcomes-dx' as resources as they can be added, removed and edited
            when 'resources-tx', 'resources-dx', 'outcomes-dx'
              <ResourcesQuestions
                editable={@props.editable}
                sectionId={@props.sectionId}
                isConsensus={@props.isConsensus}
                groupPrefix='r1'
                questionType={@props.questionType}
                anticipatedOutcomes={@props.details.get('questionsType') is 'outcomes'}
                questions={@props.details.get 'questions'}
              />
          }
        </div>
      }
      <div className="final-judgement">
        <span className="inner-label">{@i18n 'final_judgement'}</span>
        <div dangerouslySetInnerHTML={{__html: @props.criterionDescription}} />
        <HorizontalRadios
          editable={@props.editable}
          groupName="#{@props.sectionId}-final-judgement"
          options={@props.finalJudgementOptions}
          additionalOptions={@props.finalJudgementAdditionalOptions}
          selectedOption={@props.finalJudgementSelectedOption}
          onChange={@props.onFinalJudgementChange}
        />
      </div>
      <ButtonsRow className="bottom-buttons">
        <Button
          className="btn-cancel"
          label={@i18n '/translation:actions.cancel'}
          onClick={@props.onCancel}
        />
        <Button
          disabled={not @props.editable}
          className="btn"
          label={@i18n '/translation:actions.apply'}
          onClick={@props.onApply}
        />
      </ButtonsRow>
    </div>

module.exports = DetailedJudgements
