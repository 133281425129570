var Algorithms, DraftQuestions, Events, Exceptions, Model, OutcomesGeneration, PrognosticQuestions, Project, Question, QuestionGroups, Questions, QuestionsGeneration, References, TeamMembers, W, docSectionsTemplateHelper, mediator, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('models/base/model');

TeamMembers = require('models/team_members');

DraftQuestions = require('base/models/draft_questions');

QuestionGroups = require('models/question_groups');

Question = require('models/question');

Questions = require('models/questions');

PrognosticQuestions = require('models/prognostic_questions');

OutcomesGeneration = require('models/outcomes_generation');

QuestionsGeneration = require('models/questions_generation');

References = require('models/references/references');

Events = require('models/events');

Algorithms = require('models/algorithms');

mediator = require('mediator');

Exceptions = require('lib/exceptions');

W = require('when');

utils = require('base/lib/utils');

docSectionsTemplateHelper = require('stores/utils/doc_sections_template_helper');

module.exports = Project = (function(_super) {
  __extends(Project, _super);

  function Project() {
    return Project.__super__.constructor.apply(this, arguments);
  }

  Project.prototype._DOCUMENTS = {
    comparisons_question_groups: QuestionGroups,
    prognostic_question_groups: QuestionGroups,
    latestChange: Model,
    publication_status: Model,
    outcomes_generation: OutcomesGeneration,
    questions_generation: QuestionsGeneration
  };

  Project.prototype.defaults = function() {
    return _.defaults({
      draftQuestions: new DraftQuestions(),
      projectType: 'full_guideline',
      organizationId: null
    }, mediator.services.modules.getDefaultAttributes());
  };

  Project.prototype.validation = {
    name: {
      required: true
    },
    projectType: {
      required: false
    },
    customer: {
      required: false
    }
  };

  Project.prototype.nestedCollections = {
    'draftQuestions': DraftQuestions
  };

  Project.prototype.initialize = function() {
    Project.__super__.initialize.apply(this, arguments);
    if (!this.id) {
      this.set('_id', mediator.services.projectDb.generateId(mediator.user.get('name')));
      this.set('owners', [mediator.user.get('name')]);
      this.set('$data_version', mediator.services.projectMigration.getCurrentDataVersion());
    }
    return this.urlRoot = this.id;
  };

  Project.prototype.addQuestion = function(question) {
    if (!(question instanceof Question)) {
      throw new Error('question needs to be an instance of Question');
    }
    return mediator.questions.add(question);
  };

  Project.prototype.getDocFor = function(key, createIfMissing) {
    var documentInfo, id, modelClass, _ref;
    if (createIfMissing == null) {
      createIfMissing = true;
    }
    documentInfo = this._DOCUMENTS[key];
    if (!documentInfo) {
      return W.reject(new Exceptions.project_document_not_valid);
    }
    _ref = _.isFunction(documentInfo) ? [key, documentInfo] : [documentInfo.id, documentInfo["class"]], id = _ref[0], modelClass = _ref[1];
    return mediator.services.persistenceAdapter.getDoc(modelClass, id, this.id, createIfMissing);
  };

  Project.prototype.fetch = function() {
    return Project.__super__.fetch.apply(this, arguments).then((function(_this) {
      return function() {
        return mediator.services.persistenceAdapter.getDoc(Model, _this.id, mediator.services.udb.getUdbName(mediator.user.get('name')));
      };
    })(this)).then((function(_this) {
      return function(doc) {
        return _this._personalMetadoc = doc;
      };
    })(this)).then((function(_this) {
      return function() {
        return _this.getDocFor('latestChange');
      };
    })(this)).then((function(_this) {
      return function(latestChangeDoc) {
        return _this._latestChangeDoc = latestChangeDoc;
      };
    })(this)).then((function(_this) {
      return function() {
        return _this;
      };
    })(this));
  };

  Project.prototype.getPersonalMetadoc = function() {
    return this._personalMetadoc;
  };

  Project.prototype.getLatestChangeTimestamp = function() {
    return this._latestChangeDoc.get('timestamp');
  };

  Project.prototype.getCollections = function() {
    return {
      questions: new Questions(null, {
        url: this.id
      }),
      prognosticQuestions: new PrognosticQuestions(null, {
        url: this.id
      }),
      events: new Events(null, {
        url: this.id
      }),
      teamMembers: new TeamMembers(null, {
        url: this.id
      }),
      references: new References(null, {
        url: this.id
      }),
      algorithms: new Algorithms(null, {
        url: this.id
      })
    };
  };

  Project.prototype.addCurrentUserToTeamMembers = function() {
    var currentUser;
    currentUser = mediator.user;
    return this.getCollections()['teamMembers'].create({
      gdtLogin: currentUser.get('name'),
      email: currentUser.get('email'),
      lastName: currentUser.get('email').split('@')[0],
      accessRights: ['admin'],
      panelMember: false
    });
  };

  Project.prototype.createDocSectionsTemplateIfNotExist = function() {
    return mediator.services.storePersistenceAdapter.fetch(this.id, 'doc-sections-template')["catch"]((function(_this) {
      return function(e) {
        if (e.status === 404) {
          return _this.createDocSectionsTemplate();
        }
      };
    })(this));
  };

  Project.prototype.createDocSectionsTemplate = function() {
    var baseTemplate, templType, _ref;
    templType = (_ref = this.get('docSectionsTemplateType')) != null ? _ref : docSectionsTemplateHelper.getDefaultTemplateId();
    if (!templType) {
      return W.resolve();
    }
    baseTemplate = docSectionsTemplateHelper.prepareBaseTemplate(templType).set('_id', 'doc-sections-template');
    return mediator.services.storePersistenceAdapter.save(this.id, baseTemplate.toJS()).then((function(_this) {
      return function() {
        return docSectionsTemplateHelper.updateDocumentSectionsDoc(_this.id, baseTemplate);
      };
    })(this));
  };

  Project.prototype.save = function(key, value, options) {
    var attrs;
    if (_.isObject(key) || key === null) {
      attrs = key;
      options = value;
    } else {
      attrs = {};
      attrs[key] = value;
    }
    options = options ? _.clone(options) : {};
    return Project.__super__.save.apply(this, arguments);
  };

  Project.prototype.destroy = function(options) {};

  Project.prototype.dispose = function() {
    var _ref, _ref1;
    Project.__super__.dispose.apply(this, arguments);
    if ((_ref = this._personalMetadoc) != null) {
      _ref.dispose();
    }
    return (_ref1 = this._latestChangeDoc) != null ? _ref1.dispose() : void 0;
  };

  return Project;

})(Model);
