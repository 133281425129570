mediator = require 'mediator'
Modal = require 'components/common/modal'
Select = require 'components/common/select_custom'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
SubscriptionsScreen = require 'components/auth/subscriptions_screen'
Translation = require 'components/mixins/translation'
{ support_email } = require 'lib/constants'

WorkspaceSelectModal = createReactClass
  displayName: 'WorkspaceSelectModal'

  propTypes:
    onSelect: PropTypes.func.isRequired
    onClose: PropTypes.func.isRequired

  mixins: [Translation('projects:welcome')]

  getInitialState: ->
    selected: ''

  handleApply: ->
    @props.onSelect @state.selected
    @props.onClose()

  handleWorkspaceSelect: (val = '') ->
    @setState selected: val

  handleSubscriptionSuccess: ->
    @handleWorkspaceSelect 'personal'

  getWorkspaceOptions: ->
    organizationOptions = R.toPairs mediator.userOrganizations
      .map ([orgId, orgData]) ->
        value: orgId
        text: orgData.organizationName
        disabled: not orgData.isAdmin

    organizationProjectsOnly =
      mediator.services.switches.isServerSwitchOn 'organizationProjectsOnly'
    return organizationOptions if organizationProjectsOnly

    personalAccountOption = if mediator.user.get('hasPersonalSubscription')
      value: 'personal'
      text: @i18n 'my_personal_account'
    else
      value: 'create_personal'
      text: @i18n 'create_personal_account'

    organizationOptions.concat personalAccountOption

  render: ->
    <Modal
      className='workspace-select-dialog'
      isOpen
      onRequestClose={@props.onClose}
      title={@i18n 'workspace_select_title'}
    >
      <div className='message'>
        {@i18n 'select_workspace'}
      </div>
      <Select
        options={@getWorkspaceOptions()}
        onChange={@handleWorkspaceSelect}
        selected={@state.selected}
      />
      <ApplyCancelButtons
        onApply={@handleApply}
        onCancel={@props.onClose}
        applyLabel={@i18n '/translation:actions.save'}
        isSubmitEnabled={@state.selected}
      />
      <div>
        {_.map @i18n("../messages.adding_project_to_organization", returnObjects: true), (text, idx) ->
          <p style={textAlign: 'center'} key={"info-#{idx}"}>{text}</p>
        }
        <p style={textAlign: 'center'}>
          {@i18n '../messages.require_assistance', {},
            emailLink: <a href={"mailto:#{support_email}"}>{support_email}</a>
          }
        </p>
      </div>
      {if @state.selected is 'create_personal'
        <Modal isOpen onRequestClose={@handleWorkspaceSelect} className='subscriptions-modal'>
          <SubscriptionsScreen
            subscriptionService={mediator.services.subscription}
            onSuccess={@handleSubscriptionSuccess}
          />
        </Modal>
      }
    </Modal>

module.exports = WorkspaceSelectModal
