var DbDoc, MDA_TABLE_PREFIX, mdaTableDocShape;

DbDoc = require('lib/db_docs/db_doc');

mdaTableDocShape = require('lib/db_docs/doc_shapes/mda_table_doc_shape');

MDA_TABLE_PREFIX = require('lib/doc_prefixes').mdaTable;

module.exports = DbDoc(mdaTableDocShape, {
  dbSetup: {
    idPrefix: MDA_TABLE_PREFIX
  },
  methods: {
    addTopic: function(topicId) {
      return this.updateAt('topics')(function(topics) {
        return _.chain(topics != null ? topics : []).union([topicId]).uniq().value();
      });
    },
    removeTopic: function(topicId) {
      return this.updateAt('topics')(function(topics) {
        return _.without(topics != null ? topics : [], topicId);
      });
    },
    updateContent: function(newContent) {
      return this.updateAt('content')(function(oldContent) {
        var newContentWithoutSelection, oldContentWithoutSelection;
        oldContentWithoutSelection = _.omit(oldContent, 'selection');
        newContentWithoutSelection = _.omit(newContent, 'selection');
        if (_.isEqual(oldContentWithoutSelection, newContentWithoutSelection)) {
          return oldContent;
        } else {
          return newContent;
        }
      });
    },
    updateName: function(newName) {
      return this.setAt('name')(newName);
    },
    updateAnnotation: function(annotationId, data) {
      return this.updateAt('content', 'annotations', annotationId)(R.merge(R.__, data));
    },
    removeAnnotation: function(annotationId) {
      return this.updateAt('content', 'annotations')(R.dissoc(annotationId));
    }
  }
});
