var DiagnosticEffectView, DiagnosticPrevalencesView, EvidenceSynthesesToolbarView, FootnotesService, FootnotesView, ISoFView, OutcomeViewType, OutcomesView, PooledSensitivitySpecificityView, QUESTION_TYPES_BY_QUESTION_DOC_TYPE, Question, QuestionWithOutcomesView, SelectionView, Shortcuts, SourceOfDxDataView, TableScrolling, View, alt, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

DiagnosticEffectView = require('views/evidence_syntheses/diagnostic_effect_view');

DiagnosticPrevalencesView = require('views/evidence_syntheses/diagnostic_prevalences_view');

EvidenceSynthesesToolbarView = require('views/evidence_syntheses_toolbar_view');

FootnotesService = require('lib/services/footnotes_service');

FootnotesView = require('views/footnotes_view');

ISoFView = require('views/evidence_syntheses/isof_view');

mediator = require('mediator');

OutcomesView = require('views/evidence_syntheses/outcomes_view');

OutcomeViewType = require('models/outcome/view_types');

PooledSensitivitySpecificityView = require('views/evidence_syntheses/pooled_sensitivity_specificity_view');

Question = require('models/question');

SelectionView = require('base/views/selection_view');

Shortcuts = require('base/lib/traits/shortcuts');

SourceOfDxDataView = require('views/evidence_syntheses/source_of_dx_data_view');

TableScrolling = require('base/lib/traits/table_scrolling');

template = require('views/templates/evidence_syntheses/question_with_outcomes');

View = require('base/views/view');

QUESTION_TYPES_BY_QUESTION_DOC_TYPE = require('lib/questions_helper').QUESTION_TYPES_BY_QUESTION_DOC_TYPE;

module.exports = QuestionWithOutcomesView = (function(_super) {
  __extends(QuestionWithOutcomesView, _super);

  function QuestionWithOutcomesView() {
    this.showTableViewsMenu = __bind(this.showTableViewsMenu, this);
    this.changeOutcomeView = __bind(this.changeOutcomeView, this);
    this.toggleFootnotesBottomPane = __bind(this.toggleFootnotesBottomPane, this);
    return QuestionWithOutcomesView.__super__.constructor.apply(this, arguments);
  }

  QuestionWithOutcomesView.prototype.container = '#page-container';

  QuestionWithOutcomesView.prototype.template = template;

  QuestionWithOutcomesView.prototype.autoRender = true;

  QuestionWithOutcomesView.prototype.initialize = function(options) {
    var submodule;
    QuestionWithOutcomesView.__super__.initialize.apply(this, arguments);
    this.moduleName = options.moduleName;
    submodule = this.moduleName === 'prognosis' ? 'prognosis-outcomes' : 'quality-of-evidence';
    this.enable(Shortcuts);
    this.enable(TableScrolling, {
      content: '.outcomes-table-container',
      fixedElements: ".outcomes thead, .footnotes-container, .questions-view, .diagnostic-meta, #evidence-table-toolbar-container, .outcome-buttons",
      fixedMaxHeight: true
    });
    this.viewType = mediator.services.esView.getPreferredType(mediator.project, this.model.get('type'));
    this.fs = new FootnotesService({
      model: this.model
    });
    this.subscribeEvent('activatedProperty', this.activatedProperty);
    return this.subview('toolbar', new EvidenceSynthesesToolbarView({
      baseUrl: options.baseUrl,
      moduleName: this.moduleName,
      model: this.model,
      submodule: submodule,
      parentView: this,
      toggleFootnotesBottomPane: this.toggleFootnotesBottomPane
    }));
  };

  QuestionWithOutcomesView.prototype.showOutcomesSubview = function(viewType, previousViewType) {
    var outcomesObject, outcomesView, _ref;
    this.viewType = viewType;
    outcomesObject = this.getOutcomesObject();
    _(outcomesObject).extend({
      parentView: this,
      previousViewType: previousViewType,
      showTableViewsMenu: this.showTableViewsMenu,
      adolopedModel: mediator.questions.get("" + (this.model.get('_id')) + ":adoloped_" + (mediator.project.get('adolopmentIteration')))
    });
    outcomesView = viewType.isof ? new ISoFView(outcomesObject) : new OutcomesView(outcomesObject);
    this.subview('outcomes', outcomesView);
    outcomesView.render();
    if (this.model.isDiagnostic() && !viewType.isof) {
      this.subview('sourceOfDxData', new SourceOfDxDataView({
        model: this.model
      }));
      this.subview('pooledValues', new PooledSensitivitySpecificityView({
        model: this.model,
        outcomesView: outcomesView
      }));
      this.subview('prevalences', new DiagnosticPrevalencesView({
        model: this.model
      }));
      this.subview('effect', new DiagnosticEffectView({
        model: this.model
      }));
    }
    this.fixPageContainerHeight();
    this.trigger('!fixLayout');
    this.reorderFootnotesAndReferences();
    if ((_ref = this.subview('footnotesBottomPane')) != null) {
      _ref.render();
    }
    return outcomesView;
  };

  QuestionWithOutcomesView.prototype.getCurrentEditView = function() {
    if (this.subview('outcomes').currentEditView && !this.subview('outcomes').currentEditView.disposed) {
      return this.subview('outcomes').currentEditView;
    }
  };

  QuestionWithOutcomesView.prototype.showFootnotesSubview = function(view, options) {
    if (this.getCurrentEditView() && !options.forceQualityFootnote) {
      this.subview('outcomes').currentEditView.subview('footnotes', view);
    } else {
      this.subview('footnotes', view);
    }
    view.show(options);
    return view;
  };

  QuestionWithOutcomesView.prototype.activatedProperty = function(property, cell, question, outcome) {
    var _ref;
    this.activeProperty = property;
    this.activeOutcome = outcome;
    this.activeCell = cell;
    return (_ref = this.subview('footnotesBottomPane')) != null ? _ref.setActiveOutcomeAndProperty(this.activeOutcome, this.activeProperty, cell) : void 0;
  };

  QuestionWithOutcomesView.prototype.toggleFootnotesBottomPane = function() {
    var view;
    if (this.subview('footnotesBottomPane')) {
      this.removeSubview('footnotesBottomPane');
    } else {
      this.subview('footnotesBottomPane', view = new FootnotesView({
        model: this.model,
        activeOutcome: this.activeOutcome,
        activeProperty: this.activeProperty,
        activeCell: this.activeCell,
        outcomesView: this,
        fs: this.fs
      }));
      view.render();
    }
    this.fixLayout();
    return this.subview('footnotesBottomPane') != null;
  };

  QuestionWithOutcomesView.prototype.getOutcomesObject = function() {
    return {
      container: this.$('.outcomes-container'),
      viewType: this.viewType,
      questionModel: this.model
    };
  };

  QuestionWithOutcomesView.prototype.reorderFootnotesAndReferences = function() {
    this.fs.automaticallySortFootnotes();
    return this.fs.automaticallySortReferences();
  };

  QuestionWithOutcomesView.prototype.fixPageContainerHeight = function() {
    this.$el.height($('#page-container').height());
    if (this.viewType.isof) {
      return this.$el.css({
        'overflow-y': 'auto'
      });
    } else {
      return this.$el.css({
        'overflow-y': ''
      });
    }
  };

  QuestionWithOutcomesView.prototype.afterRender = function() {
    this.fixPageContainerHeight();
    QuestionWithOutcomesView.__super__.afterRender.apply(this, arguments);
    this.showOutcomesSubview(this.viewType);
    return this.fixLayout();
  };

  QuestionWithOutcomesView.prototype.dispose = function() {
    this.activeCell = null;
    alt.recycle('EtdStore');
    return QuestionWithOutcomesView.__super__.dispose.apply(this, arguments);
  };

  QuestionWithOutcomesView.prototype.getTableViewOptions = function() {
    var _ref;
    if ((_ref = this.model) != null ? _ref.isDiagnostic() : void 0) {
      return [
        {
          value: 'LayerOne',
          text: $.t('es:outcome_view_type.layer_one')
        }, {
          value: 'LayerTwo',
          text: $.t('es:outcome_view_type.layer_two')
        }, {
          value: 'LayerOneSof',
          text: $.t('es:outcome_view_type.layer_one_sof')
        }, {
          value: 'LayerTwoSof',
          text: $.t('es:outcome_view_type.layer_two_sof')
        }, {
          value: 'Dx_iSoF',
          text: $.t('es:outcome_view_type.isof')
        }
      ];
    } else {
      return [
        {
          value: 'OneRow',
          text: $.t('es:outcome_view_type.grade_one_row')
        }, {
          value: 'SOF',
          text: $.t('es:outcome_view_type.sof_table')
        }, {
          value: 'ACCP',
          text: $.t('es:outcome_view_type.grade_v2')
        }, {
          value: 'SOF_ACCP',
          text: $.t('es:outcome_view_type.sof_table_v2')
        }, {
          value: 'SOF_v3',
          text: $.t('es:outcome_view_type.sof_table_v3')
        }, {
          value: 'Tx_iSoF',
          text: $.t('es:outcome_view_type.isof')
        }
      ];
    }
  };

  QuestionWithOutcomesView.prototype.changeOutcomeView = function(viewTypeName) {
    return mediator.services.esView.savePreference(mediator.project, this.model.get('type'), viewTypeName);
  };

  QuestionWithOutcomesView.prototype.showTableViewsMenu = function(e) {
    var isTableNarrative, items, list, narrativeModeOption, offset, subviewName;
    isTableNarrative = this.model.get('$tableMode') === 'narrative';
    narrativeModeOption = {
      value: 'narrative',
      type: 'checkbox',
      text: $.t('es:outcome.mode.narrative'),
      checked: isTableNarrative,
      disabled: !this.model.areAllOutcomesNarrative() || _.str.endsWith(this.viewType.name, 'iSoF')
    };
    items = this.model.isPrognostic() ? [narrativeModeOption] : this.model.isDiagnostic() ? this.getTableViewOptions() : this.getTableViewOptions().concat([
      {
        type: 'hline'
      }, narrativeModeOption
    ]);
    if (this.undoNotifier) {
      this.undoNotifier.destroy();
    }
    subviewName = 'tableModesMenu';
    offset = $(e.target).offset();
    list = new SelectionView({
      className: 'table-view-selection',
      displayCssOptions: {
        right: "10px",
        left: null,
        top: offset.top + 20,
        display: "block"
      },
      listItems: items,
      acceptHandler: (function(_this) {
        return function(evt) {
          var optionsView, previousViewType, selectedValue, tableMode;
          optionsView = _this.subview(subviewName);
          selectedValue = optionsView.getSelectedItemValue();
          if (selectedValue === 'narrative') {
            tableMode = isTableNarrative ? 'regular' : 'narrative';
            return _this.model.set('$tableMode', tableMode);
          } else {
            previousViewType = _this.viewType;
            _this.viewType = OutcomeViewType.get(selectedValue);
            return _this.changeOutcomeView(_this.viewType.name).ensure(function() {
              if (optionsView != null) {
                optionsView.hide();
              }
              return _this.showOutcomesSubview(_this.viewType, previousViewType);
            });
          }
        };
      })(this),
      abortHandler: (function(_this) {
        return function() {
          var _ref;
          return (_ref = _this.subview(subviewName)) != null ? _ref.hide() : void 0;
        };
      })(this)
    });
    this.subview(subviewName, list);
    return list.show({
      left: e.clientX,
      top: e.clientY
    }, this.viewType.name);
  };

  return QuestionWithOutcomesView;

})(View);
