Modal = require 'components/common/modal'
ConfirmationModal = require 'components/common/confirmation_modal'
AccountOption = require 'components/organizations/account_option'
SubscriptionsScreen = require 'components/auth/subscriptions_screen'
Translation = require 'components/mixins/translation'
mediator = require 'mediator'


PersonalAccountOption = createReactClass
  displayName: 'PersonalAccountOption'

  propTypes:
    hasPersonalAccount: PropTypes.bool.isRequired
    onGoToPersonalAccount: PropTypes.func.isRequired
    onSubscriptionSuccess: PropTypes.func.isRequired

  mixins: [
    Translation()
  ]

  getInitialState: ->
    showPersonalAccountWarning: false
    showSubscriptionModal: false

  toggleShowPersonalAccountWarning: ->
    @setState showPersonalAccountWarning: not @state.showPersonalAccountWarning

  toggleSubscriptionModal: ->
    @setState showSubscriptionModal: not @state.showSubscriptionModal

  handleStartPersonalAccountWarningConfirm: ->
    @toggleShowPersonalAccountWarning()
    @toggleSubscriptionModal()

  handleSubscriptionSuccess: ->
    @toggleSubscriptionModal()
    @props.onSubscriptionSuccess()

  render: ->
    { hasPersonalAccount, onGoToPersonalAccount } = @props
    { showPersonalAccountWarning, showSubscriptionModal } = @state

    if hasPersonalAccount
      <AccountOption onOpen={onGoToPersonalAccount}>
        <div>
          <img className='avatar' src='images/person.svg' />
          <div className='text'> {@i18n 'my_personal_workspace'}</div>
        </div>
      </AccountOption>
    else
      <div
        className='start-personal-account-option text'
        onClick={@toggleShowPersonalAccountWarning}
      >
        {@i18n 'personal_account_question'}
        {if showPersonalAccountWarning
          <ConfirmationModal isOpen
            question={@i18n 'personal_account_start_confirmation'}
            confirmLabel={@i18n 'actions.next'}
            onCancel={@toggleShowPersonalAccountWarning}
            onConfirm={@handleStartPersonalAccountWarningConfirm}
          />
        }
        {if showSubscriptionModal
          <Modal isOpen onRequestClose={@handleSubscriptionSuccess} className='subscriptions-modal'>
            <SubscriptionsScreen
              subscriptionService={mediator.services.subscription}
              onSuccess={@handleSubscriptionSuccess}
            />
          </Modal>
        }
      </div>

module.exports = PersonalAccountOption
