OverReviewActions = require 'actions/over_review_actions'
StatusMatrix = require 'components/common/status_matrix'
StudiesMatrixLegend = require 'components/over_review/studies_matrix_legend'
StudyStatusSelect = require 'components/over_review/study_status_select'
Translation = require 'components/mixins/translation'
{ Card, CardContent } = ReactComponents

StudiesTab = createReactClass
  displayName: 'StudiesTab'

  propTypes:
    studiesMatrix: PropTypes.objectOf(PropTypes.shape(
      studyId: PropTypes.string
    )).isRequired
    studies: PropTypes.arrayOf(PropTypes.shape(
      _id: PropTypes.string
      text: PropTypes.string
    )).isRequired
    sources: PropTypes.object.isRequired
    sourcesOrder: PropTypes.arrayOf(PropTypes.string).isRequired
    questionId: PropTypes.string.isRequired

  mixins: [
    Translation('over_review:studiesTab')
  ]

  getInitialState: ->
    studiesToMerge: null

  toggleMergeMode: ->
    @setState studiesToMerge: if @state.studiesToMerge then null else []

  handleStudyStatusSelect: (sourceId, studyId) -> (studyStatus) =>
    { questionId } = @props
    OverReviewActions.updateStudiesMatrix { questionId, sourceId, studyId, studyStatus }

  handleStudyToggle: (evt) ->
    studyId = evt.target.getAttribute 'data-studyId'
    checked = evt.target.checked

    @setState studiesToMerge: if checked
      @state.studiesToMerge.concat studyId
    else
      R.reject R.equals(studyId), @state.studiesToMerge

  handleStudiesMerge: ->
    { studiesToMerge } = @state
    # for now this just the text of study which was the first one selected
    firstSelectedStudy = R.find R.whereEq(_id: R.head studiesToMerge), @props.studies

    OverReviewActions.mergeStudies
      questionId: @props.questionId,
      masterStudyText: firstSelectedStudy.text
      mergeStudyIds: studiesToMerge
    @toggleMergeMode()

  prepareStudiesRows: ->
    { studies, sourcesOrder, studiesMatrix, questionId } = @props

    studies.map (study) =>
      rowId: study._id
      columns: R.map ((sourceId) =>
        studyStatus = studiesMatrix[sourceId]?[study._id] ? 'none'

        columnId: sourceId
        className:
          "over-review-matrix-cell__status-#{studyStatus}"
        component:
          <StudyStatusSelect
            onSelect={@handleStudyStatusSelect sourceId, study._id}
            selected={studyStatus}
          />
      ), sourcesOrder

  prepareStudiesRowsLabels: ->
    { studiesToMerge } = @state

    R.reduce (labels, study) =>
      { _id: studyId, text } = study
      labels[studyId] =
        tooltip: text
        label: if studiesToMerge
          <label key={studyId}>
            <input
              checked={R.contains studyId, @state.studiesToMerge}
              type='checkbox'
              data-studyId={studyId}
              onChange={@handleStudyToggle}
            />
            {text}
          </label>
        else
          text
      labels
    , {}, @props.studies

  prepareSourcesColumnsLabels: ->
    { sources, sourcesOrder } = @props

    sourcesOrder.map (sourceId, idx) ->
      label: "#{idx + 1}."
      tooltip: sources[sourceId].sourceName

  render: ->
    rows = @prepareStudiesRows()
    colLabels = @prepareSourcesColumnsLabels()
    rowLabels = @prepareStudiesRowsLabels()
    { studiesToMerge } = @state

    <div className="over-review-studies-tab">
      <h2>{@i18n 'matrix'}</h2>
      <Card>
        <CardContent>
          <div className='status-marix-with-legend'>
            <StatusMatrix
              grouppedRows={[{rows: rows, caption: R.toUpper @i18n('../studies') }]}
              rowLabels={rowLabels}
              columnLabels={colLabels}
              type="column-check"
              withRowNumbers={false}
            />
            <StudiesMatrixLegend />
          </div>
          {if studiesToMerge
            <div className='merge-buttons'>
              <button className='btn btn-cancel' onClick={@toggleMergeMode}>
                {@i18n '/actions.cancel'}
              </button>
              <button
                className='btn btn-success'
                disabled={studiesToMerge.length < 2}
                onClick={@handleStudiesMerge}
              >
                {@i18n '../actions.merge'}
              </button>
            </div>
          else
            <button className='btn btn-info' onClick={@toggleMergeMode}>
              {@i18n '../actions.merge_studies'}
            </button>
          }
        </CardContent>
      </Card>
    </div>

module.exports = StudiesTab
