AlgorithmsActions = require 'actions/algorithms_actions'
AlgorithmsStore = require 'stores/algorithms_store'
ConnectStore = require 'components/enhancers/connect_store'
Spinner = require 'components/common/spinner'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'
AlgorithmsListItem = require 'components/algorithms/algorithms_list_item'
mediator = require 'mediator'
Router = require 'router'

storeConnector =
  AlgorithmsStore: (Store) ->
    activeEditItemsIds: Store.getActiveEditItemsIds()
    algorithms: Store.getAlgorithms()
    isFetching: Store.isFetching()

AlgorithmsList = ({ activeEditItemsIds, algorithms, isFetching }) ->
  i18n = useI18n('algorithms:list')
  { updateAlgorithm, deleteAlgorithm, toggleAlgorithmEdit } = AlgorithmsActions

  navigateToAlgorithm = (id) ->
    algorithmLink = Router::getProjectRelativeUrl "/algorithms/#{id}"
    mediator.publish '!router:route', algorithmLink

  handleUpdateAlgorithm = useCoffeeCallback [updateAlgorithm], (id, updatedAlgorithm) ->
    updateAlgorithm(mediator.project.id, id, updatedAlgorithm)
    toggleAlgorithmEdit(id)

  handleDeleteAlgorithm = useCoffeeCallback [deleteAlgorithm], (id) ->
    deleteAlgorithm(mediator.project.id, id)
  
  handleEditAlgorithm = useCoffeeCallback [toggleAlgorithmEdit], (id) ->
    toggleAlgorithmEdit(id)

  if isFetching
    <Spinner />
  else
    <div className="algorithms-list">
      {if algorithms.isEmpty()
        <p>{i18n('empty')}</p>
      else
        algorithms.valueSeq().map (algorithm) ->
          id = _.get(algorithm, '_id')
          isEditing = activeEditItemsIds.includes id
          <AlgorithmsListItem
            key={id}
            id={id}
            algorithm={algorithm}
            isEditing={isEditing}
            navigateToAlgorithm={navigateToAlgorithm}
            deleteAlgorithm={handleDeleteAlgorithm}
            updateAlgorithm={handleUpdateAlgorithm}
            editAlgorithm={handleEditAlgorithm}
          />
      }
    </div>

AlgorithmsList.propTypes =
  activeEditItemsIds: PropTypes.instanceOf(Immutable.List).isRequired
  algorithms: PropTypes.instanceOf(Immutable.OrderedMap).isRequired
  isFetching: PropTypes.bool.isRequired

module.exports = ConnectStore AlgorithmsList, AlgorithmsStore, storeConnector
