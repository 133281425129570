var Algorithm, Model, PouchMemento,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('models/base/model');

PouchMemento = require('lib/traits/pouch_memento');

module.exports = Algorithm = (function(_super) {
  __extends(Algorithm, _super);

  function Algorithm() {
    return Algorithm.__super__.constructor.apply(this, arguments);
  }

  Algorithm.prototype.defaults = {
    id: '',
    title: '',
    blocks: [],
    user_id: '',
    project_id: ''
  };

  Algorithm.prototype.initialize = function() {
    Algorithm.__super__.initialize.apply(this, arguments);
    return this.enable(PouchMemento);
  };

  Algorithm.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    return Algorithm.__super__.dispose.apply(this, arguments);
  };

  return Algorithm;

})(Model);
