var QuestionsStore, Rm5Export, TABLE_MAP, mediator, rm5ExportConfig, updateWithReferencesData, _wrapToAppendiceDIV;

Rm5Export = require('lib/rm5_export');

QuestionsStore = require('stores/questions_store');

rm5ExportConfig = require('lib/rm5_export_config');

mediator = require('mediator');

TABLE_MAP = {
  'sof_v1': 'sof',
  'sof_v2': 'sof_accp',
  'sof_v3': 'sof_v3',
  'sof_short': 'sof_short',
  'relative_importance': 'relative_importance',
  'l1_sof': 'l1_sof',
  'l2_sof': 'l2_sof',
  'evidence_short': 'evidence_short'
};

_wrapToAppendiceDIV = function(html, optionalAttributes) {
  var attrName, attrValue, attrsString;
  if (optionalAttributes == null) {
    optionalAttributes = {};
  }
  attrsString = '';
  for (attrName in optionalAttributes) {
    attrValue = optionalAttributes[attrName];
    attrsString += " " + attrName + "=\"" + attrValue + "\"";
  }
  return "<div class=\"inserted\"" + attrsString + "> " + html + " </div>";
};

updateWithReferencesData = function(tableData, outcomeModels) {
  var allActiveReferences, outcomes, type;
  outcomes = tableData.outcomes, type = tableData.type;
  allActiveReferences = [];
  tableData.outcomes = _.map(outcomes, function(outcomeData) {
    var attrName, cell, cellAttributes, cellAttrsMapKey, cellName, cellReferenceIds, id, outcomeCells, outcomeModel, outcomeRefIndices, outcomeReferences, refIds, rows, verifiedIds, verifiedOutcomeReferences, _i, _len, _ref, _ref1;
    rows = outcomeData.rows, id = outcomeData.id;
    outcomeModel = _.findWhere(outcomeModels, {
      id: id
    });
    outcomeReferences = (_ref = outcomeModel.get('references')) != null ? _ref : {};
    verifiedOutcomeReferences = {};
    for (attrName in outcomeReferences) {
      refIds = outcomeReferences[attrName];
      verifiedIds = refIds.filter(function(refId) {
        return mediator.colls.references.get(refId) != null;
      });
      verifiedOutcomeReferences[attrName] = verifiedIds;
    }
    if (_.isEmpty(verifiedOutcomeReferences)) {
      return outcomeData;
    }
    outcomeRefIndices = {};
    outcomeCells = _.chain(rows).pluck('cells').flatten().value();
    for (_i = 0, _len = outcomeCells.length; _i < _len; _i++) {
      cell = outcomeCells[_i];
      cellName = cell.name;
      cellAttrsMapKey = cellName === 'quality' && type === 'diag' ? 'qualityDx' : cellName === 'outcome' && tableData.tableType === 'sof_v3' ? 'sofV3OutcomeLabel' : cellName;
      cellAttributes = (_ref1 = rm5ExportConfig.attributesMap[cellAttrsMapKey]) != null ? _ref1 : [];
      cellReferenceIds = _.reduce(cellAttributes, function(refs, attrName) {
        var attrReferences, _ref2;
        attrReferences = (_ref2 = verifiedOutcomeReferences[attrName]) != null ? _ref2 : [];
        return _.union(refs, attrReferences);
      }, []);
      if (_.isEmpty(cellReferenceIds)) {
        continue;
      }
      allActiveReferences = _.union(allActiveReferences, cellReferenceIds);
      outcomeRefIndices[cellName] = _.chain(cellReferenceIds).map(function(refId) {
        return allActiveReferences.indexOf(refId) + 1;
      }).sort().value();
    }
    return _.extend(outcomeData, {
      references: outcomeRefIndices
    });
  });
  tableData.references = allActiveReferences.map(function(refId) {
    return mediator.colls.references.get(refId).vancouverFormat();
  });
  return tableData;
};

module.exports = {
  getResearchEvidenceTable: function(tableName, tableData) {
    var tableHtml, templates;
    templates = {
      'sof_short': Handlebars.partials.sofShortTable,
      'test_accuracy': Handlebars.partials.testAccuracySummary,
      'evidence_short': Handlebars.partials.recommendationDxEvidenceTable,
      'relative_importance': Handlebars.partials.relativeImportanceMainOutcomesTable
    };
    tableHtml = templates[tableName](tableData);
    return _wrapToAppendiceDIV(tableHtml, {
      'appendix-type': tableName
    });
  },
  getSofTable: function(tableName, questionModel, outcomesIds) {
    var HtmlExport;
    HtmlExport = require('lib/html_export');
    return new HtmlExport(questionModel, HtmlExport.sofTypesMap[tableName], {
      outcomesOrAppendicesIds: outcomesIds.toArray(),
      short: true
    }).getSource().then(function(sofHTML) {
      return _wrapToAppendiceDIV(sofHTML.match(/body>([\s\S]+)<\/body/)[1], {
        'appendix-type': tableName
      });
    });
  },
  getImageString: function(attrs) {
    var attrName, attrValue, attrsString, wrapped;
    attrsString = '';
    for (attrName in attrs) {
      attrValue = attrs[attrName];
      attrsString += " " + attrName + "=\"" + attrValue + "\"";
    }
    attrsString += " src=\"\"";
    wrapped = _wrapToAppendiceDIV("<img" + attrsString + ">", {
      'appendix-type': 'image',
      'image-id': attrs['db-image'],
      section: attrs.section
    });
    return wrapped;
  },
  getTableData: function(tableType, selectedOutcomes, qId) {
    var questionModel, selectedOutcomeModels, tableData;
    switch (tableType) {
      case 'test_accuracy':
        return QuestionsStore.getDataForTable(tableType, selectedOutcomes, qId);
      case 'sof_short':
      case 'sof_v1':
      case 'sof_v2':
      case 'sof_v3':
      case 'relative_importance':
        questionModel = QuestionsStore.getQuestionModel(qId);
        selectedOutcomeModels = questionModel.get('outcomes').filter(function(outcome) {
          return selectedOutcomes.contains(outcome.get('_id'));
        }).map(function(outcome) {
          return outcome.set('rm5export', false);
        });
        tableData = new Rm5Export(questionModel, TABLE_MAP[tableType], selectedOutcomeModels).getSofData(1);
        return updateWithReferencesData(_.extend(tableData, {
          tableType: tableType
        }), selectedOutcomeModels);
      case 'l1_sof':
      case 'l2_sof':
      case 'evidence_short':
        questionModel = QuestionsStore.getQuestionModel(qId);
        selectedOutcomeModels = questionModel.get('outcomes').filter(function(outcome) {
          var _ref;
          return (_ref = outcome.get('name')) === 'TP' || _ref === 'FN' || _ref === 'TN' || _ref === 'FP' || _ref === 'Inconclusive' || _ref === 'Complications';
        }).map(function(outcome) {
          return outcome.set('rm5export', false);
        });
        questionModel = QuestionsStore.getDataForTable(tableType, selectedOutcomes, qId);
        tableData = new Rm5Export(questionModel, TABLE_MAP[tableType], selectedOutcomeModels).getDataForDxInsert();
        return updateWithReferencesData(_.extend(tableData, {
          tableType: tableType
        }), selectedOutcomeModels);
    }
  }
};
