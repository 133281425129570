{ isEmpty } = require 'lodash'
{ useI18n } = require 'lib/react_utils'

SidebarCanvasEditor = ({ canvasSize, onCanvasSizeUpdate }) ->
  i18n = useI18n('algorithms:sidebar')

  handleCanvasSizeUpdate = (evt) ->
    canvasDimension = evt.target.name
    canvasDimensionInputValue = if isEmpty(evt.target.value) then '0' else evt.target.value
    canvasDimensionValue = parseInt(canvasDimensionInputValue)
    updatedCanvasSize = _.defaults({}, { "#{canvasDimension}": canvasDimensionValue }, canvasSize)
    onCanvasSizeUpdate(updatedCanvasSize)
  
  <div className='algorithm-sidebar__editor-pt'>
    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('canvas_dimensions')}:</label>
      <div className='algorithms-sidebar__label-offset'>
        <div className='algorithms-sidebar__label-offset-field'>
          <label className='algorithms-sidebar__label'>{i18n('width')}:</label>
          <input
            name='width'
            value={canvasSize.width}
            onChange={handleCanvasSizeUpdate}
            className='algorithms-sidebar__label-input'
          />
        </div>
        <div className='algorithms-sidebar__label-offset-field'>
          <label className='algorithms-sidebar__label'>{i18n('height')}:</label>
          <input
            name='height'
            value={canvasSize.height}
            onChange={handleCanvasSizeUpdate}
            className='algorithms-sidebar__label-input'
          />
        </div>
      </div>
    </div>
  </div>

SidebarCanvasEditor.propTypes =
  canvasSize: PropTypes.object.isRequired
  onCanvasSizeUpdate: PropTypes.func.isRequired

module.exports = SidebarCanvasEditor
