DatePickerMonthYearControls = createReactClass
  displayName: 'DatePickerMonthYearControls'

  propTypes:
    visibleDate: PropTypes.instanceOf(Date)
    fromYear: PropTypes.number.isRequired
    toYear: PropTypes.number.isRequired
    localeUtils: PropTypes.shape({
      getMonths: PropTypes.func.isRequired
    })
    onChange: PropTypes.func.isRequired

  handleChange: (e) ->
    { onChange } = @props
    { year, month } = e.target.form
    onChange new Date parseInt(year.value), parseInt(month.value)

  render: ->
    { localeUtils, fromYear, toYear, visibleDate } = @props
    months = localeUtils.getMonths()
    years = _.range(fromYear, toYear + 1)

    <form className="DayPicker-Caption">
      <select name="month" onChange={@handleChange} value={visibleDate.getMonth()}>
        {_.map months, (month, i) -> <option key={month} value={i}>{month}</option>}
      </select>
      <select name="year" onChange={@handleChange} value={visibleDate.getFullYear()}>
        {_.map years, (year) -> <option key={year} value={year}>{year}</option>}
      </select>
    </form>

DatePicker = createReactClass
  displayName: 'DatePicker'

  propTypes:
    fromYear: PropTypes.number
    toYear: PropTypes.number
    onSave: PropTypes.func
    modifiers: PropTypes.object
    canSelectMonth: PropTypes.bool
    value: PropTypes.string
    placeholder: PropTypes.string
    inputHidden: PropTypes.bool
    trigger: PropTypes.node

  getDefaultProps: ->
    fromYear: 1970
    toYear: new Date().getFullYear()
    modifiers: {}
    canSelectMonth: true
    placeholder: ''
    inputHidden: false
    trigger: null

  getInitialState: ->
    isOpen: false
    visibleDate: new Date()

  componentDidMount: ->
    if @props.value
      @setState visibleDate: new Date moment(@props.value, 'DD-MM-YYYY').toDate()

  toggleOpen: ->
    { visibleDate, isOpen } = @state
    { value } = @props

    newVisibleDate = if value
      moment(value, 'DD-MM-YYYY').toDate()
    else if visibleDate
      visibleDate
    else
      new Date()

    @setState { isOpen: not isOpen, visibleDate: newVisibleDate }

  onMonthChange: (date) ->
    { visibleDate } = @state
    @setState visibleDate: new Date(
      visibleDate.getFullYear(), new Date(date).getMonth(), visibleDate.getDate())

  onDateChosen: (date, modifiers, _e) ->
    return if modifiers.disabled
    { onSave } = @props
    { visibleDate } = @state

    @toggleOpen()
    onSave? _e, new Date(visibleDate.getFullYear(), visibleDate.getMonth(), date.getDate()), modifiers

  onVisibleDateChange: (visibleDate) ->
    @setState { visibleDate }

  render: ->
    {
      canSelectMonth
      fromYear
      toYear
      modifiers
      value
      placeholder
      inputHidden
      trigger
    } = @props
    { isOpen, visibleDate } = @state

    <div className="date-picker-component">
      <div className="date-picker-component__inner">
        <input
          type={if inputHidden then 'hidden' else "text"}
          className="date"
          value={value ? ''}
          readOnly
          onClick={@toggleOpen}
          placeholder={placeholder}
        />
        {if trigger
          React.cloneElement(trigger, { onClick: @toggleOpen })
        else <button
          className="date-pick"
          type="button"
          onClick={@toggleOpen}
        />}
      </div>
      {isOpen and <div className="DayPicker-Container">
        <DayPicker
          onDayClick={@onDateChosen}
          onMonthChange={@onMonthChange}
          fromMonth={new Date(fromYear, 0)}
          toMonth={new Date(toYear, 11)}
          month={visibleDate}
          initialMonth={visibleDate}
          selectedDays={@props.selectedDays}
          captionElement={if canSelectMonth
            <DatePickerMonthYearControls
              visibleDate={visibleDate}
              fromYear={fromYear}
              toYear={toYear}
              onChange={@onVisibleDateChange}
            />
          else
            undefined}
          modifiers={modifiers}
        />
      </div>}
    </div>

module.exports = DatePicker
