var Algorithm, Algorithms, OrderedCollection,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

OrderedCollection = require('models/base/ordered_collection');

Algorithm = require('models/algorithm');

module.exports = Algorithms = (function(_super) {
  __extends(Algorithms, _super);

  function Algorithms() {
    return Algorithms.__super__.constructor.apply(this, arguments);
  }

  Algorithms.prototype.model = Algorithm;

  Algorithms.prototype.defaults = {
    algorithms: []
  };

  Algorithms.prototype.initialize = function() {
    Algorithms.__super__.initialize.apply(this, arguments);
    return _.extend(this, new Backbone.Memento(this));
  };

  return Algorithms;

})(OrderedCollection);
