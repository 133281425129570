var CoiInputView, DbHelper, FormsApiHelper, MdgQuestionDoc, ModalView, Scrolling, TableScrolling, delay, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

DbHelper = require('base/lib/db_helper');

delay = require('when/delay');

FormsApiHelper = require('base/lib/forms_api_helper').coi;

MdgQuestionDoc = require('lib/db_docs/mdg_question_doc');

mediator = require('mediator');

ModalView = require('base/views/modal_view');

Scrolling = require('base/lib/traits/scrolling');

TableScrolling = require('base/lib/traits/table_scrolling');

template = require('views/templates/team/coi_input');

module.exports = CoiInputView = (function(_super) {
  __extends(CoiInputView, _super);

  function CoiInputView() {
    return CoiInputView.__super__.constructor.apply(this, arguments);
  }

  CoiInputView.prototype.template = template;

  CoiInputView.prototype.className = 'coi-input-view';

  CoiInputView.prototype.initialize = function() {
    this.member = this.options.member;
    this.title = $.t('team:coi.edit_title', {
      name: this.member.getFullName()
    });
    this.options.position = 'top';
    this.options.offsetY = 50;
    this.options.width = 1000;
    CoiInputView.__super__.initialize.apply(this, arguments);
    this.enable(TableScrolling, {
      adjustTo: '.questions-with-conflicts',
      content: '.questions table'
    }, {
      fixedElements: '.questions thead'
    });
    this.enable(Scrolling, {
      adjustTo: '.questions-with-conflicts',
      content: '.answers'
    });
    this.delegate('click', 'button.cancel', this.cancel);
    this.delegate('click', 'button.apply', this.apply);
    return this.questions = this.collection.models;
  };

  CoiInputView.prototype.onFocusGained = function() {
    return this._retrieveForm();
  };

  CoiInputView.prototype.cancel = function() {
    return this.dispose();
  };

  CoiInputView.prototype.apply = function() {
    var q, _i, _len, _ref;
    this._setData();
    _ref = this.questions;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      q = _ref[_i];
      q.save();
    }
    return this.dispose();
  };

  CoiInputView.prototype._retrieveForm = function() {
    return FormsApiHelper.getFormDoc(this.member.get('coiFormId')).then((function(_this) {
      return function(response) {
        _this.answers = response;
        _this.formRetrieved = true;
        return _this.render();
      };
    })(this)).otherwise((function(_this) {
      return function(response) {
        mediator.log(response);
        return delay(100).then(_this._retrieveForm);
      };
    })(this));
  };

  CoiInputView.prototype._setData = function() {
    var $input, input, question, _i, _len, _ref, _results;
    _ref = this.$('.questions input');
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      input = _ref[_i];
      $input = $(input);
      question = this.collection.get($input.data('question'));
      _results.push(question.setConflictStatusForMember(this.member.id, $input.is(':checked')));
    }
    return _results;
  };

  CoiInputView.prototype._getConflicts = function(coiFormType) {
    if (!this.answers) {
      return;
    }
    switch (coiFormType) {
      case 'who':
      case 'nhf':
      case 'nhi-it':
        return this._getConflictsForType('who');
      case 'icmje':
      case 'imcje':
        return this._getIcmjeConflicts();
      case 'mspsc':
        return this._getConflictsForType('mspsc');
    }
  };

  CoiInputView.prototype._getConflictsForType = function(formType) {
    var conflicts;
    conflicts = _(this.answers.conflictEntries).filter(function(e) {
      return e.isConflict;
    });
    _(conflicts).each(function(c) {
      return c.description = $.t("team:coi." + formType + "_form." + (_.string.underscored(c.type.name)));
    });
    return conflicts;
  };

  CoiInputView.prototype._getIcmjeConflicts = function() {
    var conflicts;
    return conflicts = _(this.answers.conflictEntries).chain().filter(function(e) {
      return e.moneyPaid || e.moneyInst;
    }).map(this._addIcmjeDescription).groupBy('entityName').value();
  };

  CoiInputView.prototype._addIcmjeDescription = function(entry) {
    var key, translated;
    key = entry.moneyPaid && entry.moneyInst ? 'paid_both' : entry.moneyPaid ? 'paid_individual' : 'paid_institution';
    translated = $.t("team:coi." + key);
    entry.description = "" + entry.type.name + ": " + translated + " (" + entry.comments + ")";
    return entry;
  };

  CoiInputView.prototype.getTemplateData = function() {
    var conflicts, _ref;
    conflicts = this._getConflicts(this.options.coiFormType);
    return {
      questions: _(this.questions).map((function(_this) {
        return function(q) {
          return {
            id: q.id,
            question: q.get('questionShort'),
            value: q.get('coi')[_this.member.id]
          };
        };
      })(this)),
      conflicts: conflicts,
      isConflict: !_(conflicts).isEmpty(),
      other: (((_ref = this.answers) != null ? _ref.areOtherRelationships : void 0) ? this.answers.otherRelationships : void 0),
      coiFormType: this.options.coiFormType,
      previewFormUrl: this.getFormUrl(),
      formRetrieved: this.formRetrieved
    };
  };

  CoiInputView.prototype.afterRender = function() {
    CoiInputView.__super__.afterRender.apply(this, arguments);
    this.$('.questions-with-conflicts').height(Math.min($(window).height() - 250, this.$el.height()));
    return this.trigger('!fixLayout');
  };

  CoiInputView.prototype.getFormUrl = function() {
    var formUrl;
    formUrl = "" + (DbHelper.getFormsAppUrl()) + "#";
    switch (this.options.coiFormType) {
      case 'imcje':
      case 'icmje':
        formUrl += 'coi-preview';
        break;
      default:
        formUrl += "" + this.options.coiFormType + "-coi-preview";
    }
    formUrl += "/" + (this.member.get('coiFormId')) + "/sections";
    return formUrl;
  };

  return CoiInputView;

})(ModalView);
