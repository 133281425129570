var DB_VIEW, DUPLICATION_ATTRS, DbHelper, ID_HASH_ATTRS, RefUtils, SEARCH_FIELDS, STATUSES, authorStringToObject, authorsToString, createReferenceDoc, filterOutDuplicatedReferences, filterReferencesByIds, get, getAttachmentUrl, getDefaultAttrs, getDuplicates, getDuplicatesGroups, getExportHTML, getReferenceDuplicationHash, getReferenceIDHash, getReferenceMd5Hash, markDuplicate, markKept, markMerged, mdgFormat, refDocPrefix, referenceIdsFromContent, sanitizeReference, setMd5Hash, vancouverFormat,
  __slice = [].slice;

DbHelper = require('base/lib/db_helper');

get = require('lodash').get;

refDocPrefix = require('lib/doc_prefixes').reference;

DB_VIEW = {
  map: function(doc) {
    if (doc.docType === 'reference') {
      return emit(-doc.added, doc);
    }
  }
};

STATUSES = ['inScreening', 'possibleDuplicate', 'inFullTextReview', 'included', 'excluded', 'irrelevant'];

SEARCH_FIELDS = ['authors', 'title', 'year', 'publishedIn'];

getDefaultAttrs = function() {
  return {
    authors: [],
    title: '',
    year: '',
    publishedIn: '',
    added: Date.now(),
    lastUpdated: Date.now(),
    status: 'inScreening',
    volume: '',
    issue: '',
    pages: '',
    abstract: '',
    tags: [],
    authorKeywords: [],
    DOI: '',
    PMID: '',
    source: '',
    docType: 'reference',
    researchResults: false,
    attachment: null,
    recNumber: null
  };
};

DUPLICATION_ATTRS = ['authors', 'title', 'year'];

ID_HASH_ATTRS = _.chain(getDefaultAttrs()).keys().reject(function(k) {
  return k === 'lastUpdated' || k === 'added' || k === 'attachment' || k === 'recNumber';
}).sort().value();

getReferenceMd5Hash = _.curry(function(fieldsList, referenceSpec) {
  var field, fieldVal, valuesToHash;
  valuesToHash = (function() {
    var _i, _len, _results;
    _results = [];
    for (_i = 0, _len = fieldsList.length; _i < _len; _i++) {
      field = fieldsList[_i];
      fieldVal = referenceSpec[field];
      if (Array.isArray(fieldVal)) {
        _results.push(fieldVal.map(JSON.stringify).join(''));
      } else {
        _results.push(fieldVal);
      }
    }
    return _results;
  })();
  return md5(valuesToHash.join(''));
});

getReferenceIDHash = getReferenceMd5Hash(ID_HASH_ATTRS);

getReferenceDuplicationHash = getReferenceMd5Hash(DUPLICATION_ATTRS);

setMd5Hash = function(referenceSpec) {
  return _.extend(referenceSpec, {
    md5hash: getReferenceDuplicationHash(referenceSpec)
  });
};

authorsToString = function(authors) {
  return authors.map(function(author) {
    var firstNames, lastName;
    firstNames = author.get('firstNames').join(' ');
    lastName = author.get('lastName');
    if (_.isEmpty(firstNames)) {
      return lastName;
    } else {
      return "" + lastName + ", " + firstNames;
    }
  }).join('; ');
};

authorStringToObject = function(authorString) {
  var firstNames, lastName, nameParts, _i, _ref;
  nameParts = authorString.replace(/\s+/g, ' ').trim().split(/, ?/);
  if (nameParts.length > 1) {
    lastName = nameParts[0], firstNames = 2 <= nameParts.length ? __slice.call(nameParts, 1) : [];
  } else {
    _ref = nameParts[0].split(' '), firstNames = 2 <= _ref.length ? __slice.call(_ref, 0, _i = _ref.length - 1) : (_i = 0, []), lastName = _ref[_i++];
  }
  return {
    firstNames: firstNames,
    lastName: lastName
  };
};

createReferenceDoc = function(referenceSpec) {
  var docDraft;
  if (referenceSpec == null) {
    referenceSpec = {};
  }
  docDraft = setMd5Hash(_.defaults(referenceSpec, getDefaultAttrs()));
  return _.extend(docDraft, {
    '_id': "" + refDocPrefix + (getReferenceIDHash(docDraft))
  });
};

getDuplicatesGroups = function(references) {
  return references.filterNot(function(ref) {
    return ref.get('deduplicationStatus') === 'merged';
  }).groupBy(function(ref) {
    return ref.get('md5hash');
  }).filterNot(function(group) {
    return group.size === 1;
  });
};

getDuplicates = function(references) {
  return getDuplicatesGroups(references).valueSeq().flatten(1).toList();
};

markDuplicate = function(references) {
  return references.map(function(ref) {
    return ref.set('status', 'possibleDuplicate');
  });
};

markKept = function(reference) {
  return reference.withMutations(function(reference) {
    reference.set('md5hash', reference.get('_id'));
    reference.set('deduplicationStatus', 'kept');
    reference.set('status', 'inScreening');
    return reference.set('lastUpdated', Date.now());
  });
};

markMerged = function(reference, referenceIdx) {
  var marked;
  marked = referenceIdx === 0 ? reference.set('status', 'inScreening') : reference.set('deduplicationStatus', 'merged');
  return marked.set('lastUpdated', Date.now());
};

getExportHTML = function(references, editorHTML) {
  var ReferencesSummary, div, outputDoc, resultingHTML;
  outputDoc = new DOMParser().parseFromString(editorHTML, "text/html");
  references.forEach(function(ref, idx) {
    var refId;
    refId = ref.get('_id');
    return _.forEach(outputDoc.querySelectorAll("[data-refid=\"" + refId + "\"]"), function(node) {
      return node.innerText = idx + 1;
    });
  });
  div = document.createElement('div');
  div.classList.add('references-summary');
  outputDoc.body.appendChild(div);
  ReferencesSummary = require('components/references/references_summary');
  ReactDOM.render(React.createElement(ReferencesSummary, {
    references: references
  }), outputDoc.querySelector('.references-summary'));
  resultingHTML = outputDoc.documentElement.outerHTML;
  ReactDOM.unmountComponentAtNode(outputDoc.querySelector('.references-summary'));
  div = null;
  outputDoc = null;
  return resultingHTML;
};

referenceIdsFromContent = function(editorContent) {
  var entities;
  if (!editorContent || _.isString(editorContent)) {
    return [];
  }
  entities = editorContent.get('entityMap');
  return _.unique(editorContent.get('blocks').reduce(function(references, block) {
    var entityRanges;
    entityRanges = block.get('entityRanges');
    if (entityRanges.isEmpty()) {
      return references;
    }
    entityRanges.forEach(function(entityRange) {
      var entity;
      entity = entities.get("" + (entityRange.get('key')));
      if (entity.get('type') !== 'REFERENCE') {
        return;
      }
      return references = references.concat(entity.getIn(['data', 'refIds']).toArray());
    });
    return references;
  }, []));
};

vancouverFormat = function(referenceData, addHTML) {
  var addIfDefined, authors, title, value;
  if (addHTML == null) {
    addHTML = false;
  }
  authors = referenceData.authors, title = referenceData.title;
  addIfDefined = function(field, separator, addItalic) {
    var fieldVal, _ref;
    if (addItalic == null) {
      addItalic = false;
    }
    fieldVal = (_ref = referenceData[field]) != null ? _ref : '';
    if (fieldVal.length) {
      if (addHTML && addItalic) {
        return " <i>" + fieldVal + "</i>" + separator;
      } else {
        return " " + fieldVal + separator;
      }
    } else {
      return '';
    }
  };
  authors = authors.map(function(author) {
    return "" + author.lastName + ", " + author.firstNames;
  }).join(', ');
  value = "" + authors + ". " + title + ".";
  value += addIfDefined('publishedIn', ';', true);
  value += addIfDefined('year', '.');
  return value;
};

mdgFormat = function(referenceData) {
  var firstAuthorLastName, year;
  firstAuthorLastName = get(referenceData, 'authors[0].lastName');
  year = get(referenceData, 'year');
  return _.compact([firstAuthorLastName, year]).join(', ');
};

filterOutDuplicatedReferences = function(referencesImm) {
  return referencesImm.filterNot(function(ref) {
    return ref.get('deduplicationStatus') === 'merged';
  });
};

filterReferencesByIds = function(referencesImm, refIds) {
  var deduplicatedReferencesByIds;
  refIds = [].concat(refIds);
  deduplicatedReferencesByIds = filterOutDuplicatedReferences(referencesImm).groupBy(function(ref) {
    return ref.get('_id');
  });
  return _.reduce(refIds, function(refs, refId) {
    var ref;
    ref = deduplicatedReferencesByIds.getIn([refId, 0]);
    if (ref != null) {
      return refs.push(ref);
    } else {
      return refs;
    }
  }, Immutable.List());
};

getAttachmentUrl = function(projectId, referenceId) {
  return "" + (DbHelper.getBackendUrl()) + "/storage/references/" + projectId + "/" + referenceId;
};

sanitizeReference = function(refSpec) {
  return _.mapObject(refSpec, function(value) {
    if (!_.isString(value)) {
      return value;
    }
    return _.escape(value);
  });
};

RefUtils = {
  DB_VIEW: DB_VIEW,
  STATUSES: STATUSES,
  SEARCH_FIELDS: SEARCH_FIELDS,
  getDefaultAttrs: getDefaultAttrs,
  setMd5Hash: setMd5Hash,
  getDuplicatesGroups: getDuplicatesGroups,
  authorsToString: authorsToString,
  authorStringToObject: authorStringToObject,
  createReferenceDoc: createReferenceDoc,
  getDuplicates: getDuplicates,
  markDuplicate: markDuplicate,
  markKept: markKept,
  markMerged: markMerged,
  getExportHTML: getExportHTML,
  vancouverFormat: vancouverFormat,
  mdgFormat: mdgFormat,
  referenceIdsFromContent: referenceIdsFromContent,
  getReferenceMd5Hash: getReferenceMd5Hash,
  getReferenceIDHash: getReferenceIDHash,
  getReferenceDuplicationHash: getReferenceDuplicationHash,
  filterOutDuplicatedReferences: filterOutDuplicatedReferences,
  filterReferencesByIds: filterReferencesByIds,
  getAttachmentUrl: getAttachmentUrl,
  sanitizeReference: sanitizeReference
};

module.exports = RefUtils;
