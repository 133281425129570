var authorStringToObject, fromMedlineRecord, fromRisRecord, getAuthors, getDate, getFirstValueForTag, getRecordStrings, getRisPages, medlineRecordsDelim, parse, parseRecord, pickFirstVal, recordRowRe, risRecordsDelim;

authorStringToObject = require('lib/references_utils').authorStringToObject;

risRecordsDelim = /ER\s*-\s/;

medlineRecordsDelim = /\n\n/;

recordRowRe = /(\w+)\s*-\s(.+)/g;

getRecordStrings = function(format, sourceString) {
  var recordStrings;
  recordStrings = format === 'default' ? sourceString.split(risRecordsDelim).slice(0, -1) : sourceString.replace(/\n\s{2,}/g, ' ').split(medlineRecordsDelim);
  return recordStrings.map(function(s) {
    return s.trim();
  });
};

pickFirstVal = function(keys, object) {
  var key, _i, _len;
  for (_i = 0, _len = keys.length; _i < _len; _i++) {
    key = keys[_i];
    if (object[key] != null) {
      return object[key];
    }
  }
};

getFirstValueForTag = _.compose(_.head, pickFirstVal);

getAuthors = function(authorTags, record) {
  return _(record).chain().pick(authorTags).values().flatten().map(authorStringToObject).value();
};

getDate = function(dateTags, record) {
  var date, _ref;
  date = getFirstValueForTag(dateTags, record);
  if (date == null) {
    return;
  }
  return ((_ref = date.match(/\d{4}/)) != null ? _ref[0] : void 0) || '';
};

getRisPages = function(record) {
  var endPage, startPage;
  startPage = record['SP'];
  endPage = record['EP'];
  if (startPage && endPage) {
    return "" + startPage + " - " + endPage;
  } else if (startPage || endPage) {
    return "" + ((startPage != null) || (endPage != null));
  } else {
    return '';
  }
};

fromRisRecord = function(record) {
  return {
    itemType: getFirstValueForTag(['TY'], record),
    authors: getAuthors(['AU', 'A1', 'A2', 'A3', 'A4', 'TA'], record),
    title: getFirstValueForTag(['TI', 'T1'], record),
    year: getDate(['PY', 'DA', 'Y1'], record),
    publishedIn: getFirstValueForTag(['J1', 'JA', 'JF', 'JO'], record),
    volume: getFirstValueForTag(['VL'], record),
    pages: getRisPages(record),
    abstract: getFirstValueForTag(['AB'], record),
    DOI: getFirstValueForTag(['DO'], record)
  };
};

fromMedlineRecord = function(record) {
  return {
    itemType: getFirstValueForTag(['PT'], record),
    authors: getAuthors(['AU', 'CN'], record),
    title: getFirstValueForTag(['TI', 'TT'], record),
    year: getDate(['DP', 'DEP', 'Y1'], record),
    publishedIn: getFirstValueForTag(['BTI', 'CTI', 'JT'], record),
    volume: getFirstValueForTag(['VI'], record),
    pages: getFirstValueForTag(['PG'], record),
    abstract: getFirstValueForTag(['AB'], record),
    PMID: getFirstValueForTag(['PMID'], record),
    source: getFirstValueForTag(['SO'], record)
  };
};

parseRecord = function(recordString) {
  var recordMap, result, tag, value, _i;
  recordMap = {};
  while ((result = recordRowRe.exec(recordString)) != null) {
    _i = result.length - 2, tag = result[_i++], value = result[_i++];
    recordMap[tag] = (recordMap[tag] || []).concat(value);
  }
  return recordMap;
};

parse = function(_arg, sourceString) {
  var format, recordStrings, toReference;
  format = _arg.format;
  if (format !== 'default' && format !== 'medline') {
    return null;
  }
  recordStrings = getRecordStrings(format, sourceString);
  toReference = format === 'default' ? fromRisRecord : fromMedlineRecord;
  return _.map(recordStrings, _.compose(toReference, parseRecord));
};

module.exports = _.curry(parse);
