var TypeValidationError, W, createSubDoc, docInstGetAt, docInstSetAt, docInstUpdateAt, getKeyValObject, invariant, isInstanceOf, mediator, mergeNewData, mergeSubDocs, rejectWithMessage, rejectWithValidationMessage, removeItemById, removeWhere, typeValidationInvariant, validationErrorText, _ref,
  __slice = [].slice;

W = require('when');

mediator = require('mediator');

TypeValidationError = require('lib/db_docs/field_types/type_validation_error');

_ref = require('base/lib/utils'), isInstanceOf = _ref.isInstanceOf, invariant = _ref.invariant, getKeyValObject = _ref.getKeyValObject;

docInstUpdateAt = function() {
  var keysOrLenses;
  keysOrLenses = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return function(updater) {
    return function(docInst) {
      return docInst.updateAt.apply(docInst, keysOrLenses)(updater);
    };
  };
};

docInstSetAt = function() {
  var keysOrLenses;
  keysOrLenses = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return function(value) {
    return function(docInst) {
      return docInst.setAt.apply(docInst, keysOrLenses)(value);
    };
  };
};

docInstGetAt = function() {
  var keysOrLenses;
  keysOrLenses = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return function(docInst) {
    return docInst.getAt.apply(docInst, keysOrLenses);
  };
};

typeValidationInvariant = invariant(R.compose(R.not, isInstanceOf(TypeValidationError)));

validationErrorText = function(errorText) {
  return $.t('errors:model.doc_fields_validation', {
    errors: errorText
  });
};

rejectWithMessage = function(message) {
  return W.reject(message);
};

rejectWithValidationMessage = R.compose(rejectWithMessage, validationErrorText, R.prop('message'));

createSubDoc = function(newDocOrDocs) {
  if (newDocOrDocs == null) {
    newDocOrDocs = {};
  }
  return function(subDocs, subDocsContainerType) {
    var firstFailedValidation, maybeCreatedDocs, newDocs, subDocShape;
    newDocs = [].concat(newDocOrDocs);
    subDocShape = subDocsContainerType['_typeMeta'].ItemType;
    maybeCreatedDocs = R.map(subDocShape.defaults.bind(subDocShape), newDocs);
    firstFailedValidation = R.find(isInstanceOf(TypeValidationError), maybeCreatedDocs);
    if (firstFailedValidation != null) {
      mediator.dialogs.warning(firstFailedValidation.message);
      return subDocs;
    } else {
      if (_.isArray(subDocs)) {
        return subDocs.concat(maybeCreatedDocs);
      } else {
        return maybeCreatedDocs.reduce(function(subDocsAcc, maybeCreatedDoc) {
          return R.assoc(maybeCreatedDoc['_id'], maybeCreatedDoc, subDocsAcc);
        }, subDocs);
      }
    }
  };
};

removeItemById = function(subDocId, idProp) {
  if (idProp == null) {
    idProp = '_id';
  }
  return function(subDocs) {
    if (R.has(subDocId, subDocs) && !_.isArray(subDocs)) {
      return R.dissoc(subDocId, subDocs);
    } else {
      return R.reject(R.propEq(idProp, subDocId), subDocs);
    }
  };
};

removeWhere = function(pred) {
  return function(collection) {
    if (_.isArray(collection)) {
      return R.reject(pred, collection);
    } else {
      return R.pickBy(R.compose(R.not, pred), collection);
    }
  };
};

mergeNewData = R.flip(R.merge);

mergeSubDocs = function(newSubDocs) {
  return R.cond([[_.isArray, R.compose(R.uniqBy(R.prop('_id')), R.concat(newSubDocs))], [R.T, mergeNewData(newSubDocs)]]);
};

module.exports = {
  docInstUpdateAt: docInstUpdateAt,
  docInstSetAt: docInstSetAt,
  typeValidationInvariant: typeValidationInvariant,
  rejectWithValidationMessage: rejectWithValidationMessage,
  createSubDoc: createSubDoc,
  removeItemById: removeItemById,
  docInstGetAt: docInstGetAt,
  mergeNewData: mergeNewData,
  mergeSubDocs: mergeSubDocs,
  removeWhere: removeWhere
};
