var OutcomeViewTypes, printoutsMap;

OutcomeViewTypes = require('models/outcome/view_types');

printoutsMap = {
  'oneRow': {
    template: 'views/templates/printouts/outcomes_one_row',
    viewType: OutcomeViewTypes.get('PrintoutOneRow'),
    spliceTag: '<div class="outcomes-container">',
    listSelector: '.printout-rows',
    name: "" + ($.t('es:outcome_view_type.grade_one_row'))
  },
  'sof': {
    template: 'views/templates/printouts/outcomes_sof',
    viewType: OutcomeViewTypes.get('PrintoutSOF'),
    spliceTag: '<body>',
    listSelector: '.printout-rows',
    name: "" + ($.t('es:outcome_view_type.sof_table'))
  },
  'ACCP_grade': {
    template: 'views/templates/printouts/outcomes_accp_grade',
    viewType: OutcomeViewTypes.get('PrintoutGRADEACCP'),
    spliceTag: '<body>',
    listSelector: '.printout-rows',
    name: "" + ($.t('es:outcome_view_type.grade_v2'))
  },
  'SOF_ACCP': {
    template: 'views/templates/printouts/outcomes_accp_sof',
    viewType: OutcomeViewTypes.get('PrintoutSOFACCP'),
    spliceTag: '<body>',
    listSelector: '.printout-rows',
    name: "" + ($.t('es:outcome_view_type.sof_table_v2'))
  },
  'SOF_v3': {
    template: 'views/templates/printouts/outcomes_sof_v3',
    viewType: OutcomeViewTypes.get('PrintoutSOFv3'),
    spliceTag: '<body>',
    listSelector: '.printout-rows',
    name: "" + ($.t('es:outcome_view_type.sof_table_v3'))
  },
  'layerOne': {
    template: 'views/templates/printouts/diagnostic/layer_one',
    viewType: OutcomeViewTypes.get('PrintoutLayerOne'),
    spliceTag: '<div class="outcomes-container">',
    listSelector: 'tbody.outcomes',
    name: "" + ($.t('es:outcome_view_type.diag_sof'))
  },
  'layerTwo': {
    template: 'views/templates/printouts/diagnostic/layer_two',
    viewType: OutcomeViewTypes.get('PrintoutLayerTwo'),
    spliceTag: '<div class="outcomes-container">',
    listSelector: 'tbody.outcomes',
    name: "" + ($.t('es:outcome_view_type.diag_sof'))
  },
  'layerOneSof': {
    template: 'views/templates/printouts/diagnostic/layer_one_sof',
    viewType: OutcomeViewTypes.get('PrintoutLayerOneSof'),
    spliceTag: '<div class="outcomes-container">',
    listSelector: 'tbody.outcomes',
    name: "" + ($.t('es:outcome_view_type.diag_sof'))
  },
  'layerTwoSof': {
    template: 'views/templates/printouts/diagnostic/layer_two_sof',
    viewType: OutcomeViewTypes.get('PrintoutLayerTwoSof'),
    spliceTag: '<div class="outcomes-container">',
    listSelector: 'tbody.outcomes',
    name: "" + ($.t('es:outcome_view_type.diag_sof-l2'))
  },
  'recommendations-v1': {
    template: 'views/templates/printouts/recommendations_printout',
    viewType: 'recommendations',
    spliceTag: '<div id="page-container">',
    name: "" + ($.t('es:recommendations.title'))
  },
  'recommendations-v2': {
    template: 'views/templates/printouts/recommendations_printout_ietd',
    viewType: 'recommendations',
    spliceTag: '<div id="page-container">',
    name: "" + ($.t('es:recommendations.title'))
  },
  'prognosis': {
    template: 'views/templates/printouts/prognosis/prognostic',
    viewType: OutcomeViewTypes.get('PrintoutPrognosis'),
    spliceTag: '<body>',
    listSelector: '.printout-rows',
    name: "" + ($.t('es:outcome_view_type.prognosis'))
  }
};

module.exports = printoutsMap;
