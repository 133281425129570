ConnectStore = require 'components/enhancers/connect_store'
DuplicateStudiesDialog = require 'components/mdg_tables/duplicate_studies_dialog'
ExportDialog = require 'components/mdg_tables/export_dialog'
IconButton = require 'components/common/icon_button'
MDGSearchStrategyStore = require 'stores/mdg_search_strategy_store'
MDGTableActions = require 'actions/mdg_table_actions'
MDGTableStore = require 'stores/mdg_table_store'
ReferencesStore = require 'stores/references_store'
ToolbarQuestionsList = require 'components/evidence_syntheses/toolbar_questions_list'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

MDGTableToolbarControls = ({ mode, question, searchStrategy }) ->
  i18n = useI18n()

  onAddStudy = useCoffeeCallback [MDGTableActions, question], ->
    MDGTableActions.addRow question.get '_id'

  onExport = useCoffeeCallback [mode, searchStrategy, question, MDGTableActions], ->
    switch mode
      when 'searchStrategy'
        console.log 'TODO: T4439', searchStrategy.toJS()
      when 'table'
        MDGTableActions.openExportDialog()
      else
        throw new Error("Wrong mode #{mode}")

  switch mode
# TODO: Uncomment when specification for search strategy printout is there.
#    when 'searchStrategy'
#      <IconButton
#        className="buttons__export-table"
#        iconName="export"
#        label={i18n('actions.export_table')}
#        labelPosition="right"
#        onClick={onExport}
#      />
    when 'table'
      <React.Fragment>
        <IconButton
          className="buttons__add-new-source"
          iconName="plus"
          label={i18n('actions.add_new_source')}
          labelPosition="right"
          onClick={onAddStudy}
        />
        <IconButton
          className="buttons__export-table"
          iconName="export"
          label={i18n('actions.export_table')}
          labelPosition="right"
          onClick={onExport}
        />
      </React.Fragment>
    else null

MDGTableToolbarControls.propTypes =
  mode: PropTypes.oneOf(['searchStrategy', 'table']).isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  searchStrategy: PropTypes.instanceOf(Immutable.Map).isRequired

storeConnector =
  MDGTableStore: (Store) ->
    isFetching: Store.isFetching()
    question: Store.getCurrentQuestion()
    isExportDialogOpen: Store.isExportDialogOpen()
  MDGSearchStrategyStore: (Store) ->
    searchStrategy: Store.getCurrentSearchStrategy()
  ReferencesStore: (Store) ->
    isFetchingReferences: Store.isFetching()
    references: Store.getReferences() # (*)

# (*) is a bit on a slippery ground - however it's only used in export and only on used references
#     if you want to use it for sth that displays references, you need to use the deduplicated
#     references, not the value returned in (*)

MDGTableToolbar = ({
  isExportDialogOpen
  isFetching
  isFetchingReferences
  mode
  question
  references
  searchStrategy
}) ->
  return null if isFetching or isFetchingReferences

  <div className="mdg-table-toolbar">
    <div className="mdg-table-toolbar__questions">
      <ToolbarQuestionsList
        additionalProps={{ mode }}
        currentQuestion={question.get('question', '')}
        questionsCollectionType="regular"
      />
    </div>
    <div className="mdg-table-toolbar__buttons">
      <MDGTableToolbarControls
        mode={mode}
        question={question}
        searchStrategy={searchStrategy}
      />
    </div>
    <ExportDialog
      isOpen={isExportDialogOpen}
      onClose={MDGTableActions.closeExportDialog}
      question={question}
      references={references}
    />
    <DuplicateStudiesDialog />
  </div>

MDGTableToolbar.propTypes =
  isExportDialogOpen: PropTypes.bool.isRequired
  isFetching: PropTypes.bool.isRequired
  isFetchingReferences: PropTypes.bool.isRequired
  mode: PropTypes.oneOf(['searchStrategy', 'table']).isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  references: PropTypes.instanceOf(Immutable.List).isRequired
  searchStrategy: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore MDGTableToolbar,
  [ MDGTableStore, MDGSearchStrategyStore, ReferencesStore ],
  storeConnector
