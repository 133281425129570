var Application, Chaplin, Dialogs, DiscardChangesStore, FixedSizeQueue, FluxModulesWatcher, FocusManager, GdtDispatcher, GdtRouter, HelpResources, Layout, SynchronizationWatcher, Tooltips, alt, mainTemplate, mediator, routes, wire,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Chaplin = require('chaplin');

mediator = require('mediator');

routes = require('routes');

GdtRouter = require('router');

GdtDispatcher = require('dispatcher');

Layout = require('views/layout');

Dialogs = require('base/lib/dialogs');

Tooltips = require('base/lib/tooltips');

FocusManager = require('base/lib/focus_manager');

mainTemplate = require('views/templates/main');

wire = require('lib/wire/wire');

HelpResources = require('lib/help_resources');

FluxModulesWatcher = require('lib/flux_modules_watcher');

FixedSizeQueue = require('base/lib/fixed_size_queue');

SynchronizationWatcher = require('base/lib/synchronization_watcher');

DiscardChangesStore = require('stores/discard_changes_store');

alt = require('alt');

module.exports = Application = (function(_super) {
  __extends(Application, _super);

  function Application() {
    return Application.__super__.constructor.apply(this, arguments);
  }

  Application.prototype.title = $.t('application_title');

  Application.prototype.initialize = function(debug, integrationTests) {
    if (debug == null) {
      debug = false;
    }
    if (integrationTests == null) {
      integrationTests = false;
    }
    Application.__super__.initialize.apply(this, arguments);
    this.debugMode = Boolean(debug);
    this.integrationMode = Boolean(integrationTests);
    this.initMediator();
    this.handleServiceWorker();
    this.initServices().then((function(_this) {
      return function(services) {
        var SessionController;
        mediator.services = services;
        if (_this.integrationMode) {
          return;
        }
        _this.initDispatcher();
        _this.initLayout();
        SessionController = require('controllers/session_controller');
        return new SessionController({
          authenticationService: services.authentication,
          udbService: services.udb,
          container: $('#gdt-app')
        });
      };
    })(this), function(error) {
      return mediator.dialogs.fatalError(error);
    });
    this.subscribeEvent('sessionStarted', function() {
      this.initControllers();
      this.initRouter(routes, {
        pushState: false
      });
      this.showBrowserCompatibilityWarning();
      return typeof Object.freeze === "function" ? Object.freeze(this) : void 0;
    });
    this.subscribeEvent('!reloadApplication', function(clearHash) {
      if (clearHash == null) {
        clearHash = false;
      }
      if (clearHash) {
        return location.assign("" + location.origin + location.pathname + location.search);
      } else {
        return location.reload();
      }
    });
    return window.addEventListener('beforeunload', function(evt) {
      if (DiscardChangesStore.hasChanges()) {
        evt.preventDefault();
        return evt.returnValue = '';
      }
    });
  };

  Application.prototype.initServices = function() {
    return wire('lib/services/context');
  };

  Application.prototype.initDispatcher = function(options) {
    return this.dispatcher = new GdtDispatcher(options);
  };

  Application.prototype.initRouter = function(routes, options) {
    this.router = new GdtRouter(options);
    if (typeof routes === "function") {
      routes(this.router.match, this.router.projectRoute, this.router.submodule);
    }
    return this.router.startHistory();
  };

  Application.prototype.initLayout = function() {
    return this.layout = new Layout({
      title: this.title,
      titleTemplate: ((function(_this) {
        return function() {
          return _this.title;
        };
      })(this))
    });
  };

  Application.prototype.initControllers = function() {
    var HeaderController, SidebarController;
    HeaderController = require('controllers/header_controller');
    SidebarController = require('controllers/sidebar_controller');
    $('#gdt-app').html(mainTemplate);
    new HeaderController();
    return new SidebarController();
  };

  Application.prototype.initMediator = function() {
    mediator.debug = this.debugMode;
    if (this.debugMode) {
      mediator.publish = _.wrap(mediator.publish, function(func, what, onwhat) {
        console.log(what);
        return func(what, onwhat);
      });
      mediator.log = function() {
        return console.log.apply(console, arguments);
      };
    } else {
      mediator.log = function() {};
    }
    mediator.user = null;
    mediator.projects = null;
    mediator.userOrganizations = null;
    mediator.project = null;
    mediator.questions = null;
    mediator.currentQuestionId = null;
    mediator.prognosticQuestions = null;
    mediator.events = null;
    mediator.colls = null;
    mediator.currentFocus = null;
    mediator.heirView = null;
    mediator.fm = new FocusManager();
    mediator.setFocus = mediator.fm.setFocus;
    mediator.dialogs = new Dialogs();
    mediator.tooltips = new Tooltips();
    mediator.services = null;
    mediator.helpResources = new HelpResources();
    mediator.actionsQueue = new FixedSizeQueue(20);
    mediator.syncWatcher = new SynchronizationWatcher();
    mediator.projectDocumentSectionsVersion = null;
    new FluxModulesWatcher();
    mediator.activeWorkspace = null;
    return mediator.seal();
  };

  Application.prototype.showBrowserCompatibilityWarning = function() {
    if ($.browser.webkit || $.browser.mozilla) {
      return;
    }
    return mediator.dialogs.warning({
      message: $.t('partially_tested_browser'),
      ms: false
    });
  };

  Application.prototype.handleServiceWorker = function() {
    var processServiceWorkerUpdating;
    if (!window.sw) {
      return;
    }
    processServiceWorkerUpdating = function() {
      return mediator.publish('updateAvailable');
    };
    return window.sw.workboxInstance.addEventListener('waiting', processServiceWorkerUpdating);
  };

  return Application;

})(Chaplin.Application);
