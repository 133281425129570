CustomRenderMixin = require 'components/mixins/custom_render_mixin'
SidebarModuleLink = require 'components/sidebar/sidebar_module_link'
utils = require 'base/lib/utils'
mediator = require 'mediator'
QuestionsStore = require 'stores/questions_store'
ConnectStore = require 'components/enhancers/connect_store'
{ useCoffeeMemo } = require 'lib/react_utils'

{ getModuleProps, getSubmoduleProps } = require 'lib/sidebar_helper'
{ string, array } = PropTypes

minWidthWithModules = "55px"
minWidthWithoutModules = "0px"

getModuleClasses = (module, collapsed, absoluteExpand) ->
  classNames
    'collapsed': collapsed and not absoluteExpand,
    'expanded': not collapsed or absoluteExpand,
    'selected': module.selected
    'has-submodules': not _.isEmpty module.submodules

rejectSubmodule = (moduleId, currentQuestionType) -> (submodule) ->
  if moduleId is 'evidence_syntheses' and currentQuestionType is 'good-practice-statement'
    submodule.id is 'presentations'

storeConnectors =
  QuestionsStore: (Store) ->
    currentQuestion: Store.getQuestion()

Sidebar = createReactClass

  displayName: "Sidebar"

  mixins: [CustomRenderMixin]

  propTypes:
    currentQuestionId: string
    currentModule: string
    modules: array

  getDefaultProps: ->
    currentQuestion: Immutable.Map()

  getInitialState: ->
    collapsed: mediator.user.get('sidebarCollapsed') ? false
    absoluteExpand: not mediator.user.get('sidebarCollapsed')
    absoluteMode: mediator.user.get('sidebarCollapsed')

  componentWillReceiveProps: (nextProps) ->
    minWidth = if _.isEmpty(nextProps.modules) then minWidthWithoutModules else minWidthWithModules
    ReactDOM.findDOMNode(this).parentNode.style.minWidth = minWidth

  toggleSidebar: ->
    @sidebar.style.position = 'relative'
    collapsed = not @state.collapsed
    @setState
      collapsed: collapsed,
      absoluteExpand: false,
      absoluteMode: collapsed
    mediator.user.set('sidebarCollapsed', collapsed)
    mediator.user.save()
    # force Redraw is used for redrawing sidebar due to safari issues.
    # see https://phabricator.lab.evidenceprime.com/T545
    return unless utils.isSafari()
    _.delay ->
      el = $("#sidebar-inner-container")
      if el.length != 0 then el.forceRedraw()
    , 500

  expandSidebar: ->
    return if not @state.collapsed #if siderbar is expanded do nothing
    @sidebar.style.position = 'absolute'
    @setState absoluteExpand: true

  collapseSidebar: (e) ->
    # this will prevent collapsing sidebar when mouse is hovering over tooltip because
    # tooltips are bound to document.body
    return if e.nativeEvent.toElement?.classList.contains('opentip');
    return if not @state.collapsed #if siderbar is expanded do nothing
    @setState absoluteExpand: false

  sidebarEl: (e) -> @sidebar = e

  render: ->
    return <div /> unless @props.currentModule #need empty node
    classes = classNames utils.getCurrentLanguage(),
      'collapsed': @state.collapsed and not @state.absoluteMode,
      'expanded': not @state.collapsed and not @state.absoluteMode
      'absolute-expand': @state.absoluteExpand and @state.absoluteMode
      'absolute-collapsed': not @state.absoluteExpand and @state.absoluteMode

    btnClassess = classNames(
      'btn-toggle',
      'btn-collapsed': @state.collapsed,
      'btn-expanded': not @state.collapsed
    )
    { currentQuestion, currentQuestionId, modules } = @props
    { collapsed, absoluteExpand } = @state

    questionType = currentQuestion?.get('type')

    <div
      id="sidebar-inner-container"
      className={classes}
      onMouseLeave={@collapseSidebar}
      ref={@sidebarEl}
    >
      <div className="flex-container">
        {###
          onMouseEnter is here, so when user hover over bottom button it won't expand sidebar
        ###}
        <div id="sidebar" onMouseEnter={@expandSidebar} >
          <nav >
            <ul>
              {modules.map (module) ->
                submodules = _.reject(module.submodules, rejectSubmodule(module.id, questionType))
                <Module
                  key={module.id}
                  absoluteExpand={absoluteExpand}
                  collapsed={collapsed}
                  currentQuestionId={currentQuestionId}
                  module={module}
                  submodules={submodules}
                />
              }
            </ul>
          </nav>
        </div>
        <div className='bottom'>
          <div className='pull-right'>
            <button className={btnClassess} onClick={@toggleSidebar} />
          </div>
        </div>
      </div>
    </div>

Module = ({ currentQuestionId, module, submodules, collapsed, absoluteExpand }) ->
  { href, classes, title, label } = getModuleProps module
  sidebarOpen = collapsed and not absoluteExpand
  submenuClasses = classNames 'submenu', 'collapsed': sidebarOpen, 'expanded': not sidebarOpen
  <li className={getModuleClasses(module, collapsed, absoluteExpand)}>
    <SidebarModuleLink
      absoluteExpand={absoluteExpand}
      classes={classes}
      collapsed={collapsed}
      href={href}
      label={label}
      title={title}
    />
    {submodules and <ol className={submenuClasses}>
      {submodules.map (submodule) ->
        submoduleClasses = getModuleClasses(submodule, collapsed, absoluteExpand)
        {
          href
          classes
          title
          label
        } = getSubmoduleProps module.id, currentQuestionId, submodule
        <li key={submodule.id} className={submoduleClasses}>
          <SidebarModuleLink
            absoluteExpand={absoluteExpand}
            classes={classes}
            collapsed={collapsed}
            href={href}
            label={label}
            title={title}
          />
        </li>
      }
    </ol>}
  </li>

Module.propTypes =
  currentQuestionId: PropTypes.string

Module.defaultProps =
  currentQuestionId: ''

module.exports =
  Sidebar: ConnectStore(Sidebar, [ QuestionsStore ], storeConnectors)
  SidebarComponent: Sidebar
