EtdActions = require 'actions/etd_actions'
Select = require 'components/common/select_custom'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ Button } = ReactComponents
IconButton = require 'components/common/icon_button'
Translation = require 'components/mixins/translation'
GdtEditorWithApply = Editor.EditorWithApply

SingleRecommendation = createReactClass
  editorRef: (el) ->
    @gdtEditor = el?.gdtEditor

  onApply: ->
    @saveContent()

  onBlur: ->
    return if @props.noAutoSave
    @saveContent()

  onChangeRecommendation: (value) ->
    EtdActions.updateSelectedRecommendationValue {index: @props.index, value}

  onRemove: ->
    EtdActions.removeRecommendation @props.index

  onCancel: ->
    @gdtEditor.resetContent @props.content

  saveContent: ->
    content = @gdtEditor.getEditorContent()
    options = isConsensus: @props.isConsensus
    EtdActions.updateEditorContent {
      cellId: "multipleRecommendations_#{@props.index}#conclusionsMultiRecommendations",
      content, options
    }

  render: ->

    { options, selectedOption, renderMode, showDeleteButton, content, stickControlsTo } = @props

    <div className="multiple-recommendations__single">
      <div className="single__header">
        <div className="header__select-options">
          <Select
            options={options}
            selected={selectedOption}
            onChange={@onChangeRecommendation}
          />
        </div>
        {renderMode isnt 'printout' and showDeleteButton and <IconButton
            iconName="decline-icon"
            onClick={@onRemove}
          />
        }
      </div>
      <GdtEditorWithApply
        editorContent={content}
        onApply={@onApply}
        onBlur={@onBlur}
        onCancel={@onCancel}
        onChange={@onChange}
        ref={@editorRef}
        stickControlsTo={stickControlsTo}
      />
    </div>

MultipleRecommendations = createReactClass
  displayName: "MultipleRecommendations"
  propTypes:
    editable: PropTypes.bool
    renderMode: PropTypes.string

  mixins: [ CustomRenderMixin, Translation() ]

  getDefaultProps: ->
    editable: true
    stickControlsTo: '#recommendations'

  addRecommendation: ->
    EtdActions.addRecommendation()

  render: ->
    {renderMode, editable, section, sectionId, readOnly} = @props
    options = section.get('options').toJS()
    showDeleteButton = section.get('recommendations').size > 1
    <div>
      {section.get('recommendations').map (recommendation, idx) =>
        <SingleRecommendation
          key={_.uniqueId()}
          index={idx}
          options={options}
          content={recommendation.get('content')}
          renderMode={renderMode}
          selectedOption={recommendation.get('selectedOption')}
          showDeleteButton={showDeleteButton}
        />
      }
      {renderMode isnt 'printout' and <p className="text-right">
          <Button onClick={@addRecommendation}>{@i18n 'actions.add_recommendation'}</Button>
        </p>
      }
    </div>

module.exports = MultipleRecommendations
