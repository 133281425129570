Button = require 'components/common/button'
SpinnerButton = require 'components/common/spinner_button'
Translation = require 'components/mixins/translation'
{ func, string, bool } = PropTypes

wrappedButton = (Component, props) ->
  <div className="col-6">
    <Component {...props} />
  </div>

ApplyCancelButtons = createReactClass
  displayName: 'ApplyCancelButtons'

  propTypes:
    applying: bool
    onApply: func.isRequired
    onCancel: func.isRequired
    isCancelEnabled: bool
    isSubmitEnabled: bool
    cancelLabel: string
    applyLabel: string
    wrappedWithCol: bool
    displayApplyButton: bool
    displayCancelButton: bool

  getDefaultProps: ->
    applyLabel: $.t 'actions.apply'
    cancelLabel: $.t 'actions.cancel'
    applyClass: 'btn-apply'
    cancelClass: 'btn-cancel'
    isCancelEnabled: true
    isSubmitEnabled: true
    wrappedWithCol: true
    displayApplyButton: true
    displayCancelButton: true

  getButton: (Btn, props) ->
    if @props.wrappedWithCol
      wrappedButton Btn, props
    else
      <Btn {...props} />

  render: ->
    {
      applyLabel,
      cancelLabel,
      applyClass,
      cancelClass,
      onApply,
      onCancel,
      displayApplyButton,
      displayCancelButton,
      applying,
      isCancelEnabled,
      isSubmitEnabled
    } = @props
    applyBtnClass = classNames 'btn btn-block', applyClass
    cancelBtnClass = classNames 'btn btn-block', cancelClass
    containerClass = classNames 'row buttons-react', 'not-wrapped': not @props.wrappedWithCol

    applyBtn = @getButton SpinnerButton, {
      className: applyBtnClass
      disabled: not isSubmitEnabled
      label: applyLabel
      loading: applying
      onClick: onApply
    }
    cancelBtn = @getButton Button, {
      className: cancelBtnClass
      disabled: not isCancelEnabled
      label: cancelLabel
      onClick: onCancel
    }

    <div className={containerClass}>
      {displayCancelButton and cancelBtn}
      {displayApplyButton and applyBtn}
    </div>

module.exports = ApplyCancelButtons
