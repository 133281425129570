var Rm5ExportTableCell, rm5ExportConfig, theadLabels, theadSchemas, _buildThead, _prevalenceHeader;

rm5ExportConfig = require('lib/rm5_export_config');

theadSchemas = rm5ExportConfig.theadSchemas;

theadLabels = rm5ExportConfig.theadLabels;

Rm5ExportTableCell = rm5ExportConfig.Rm5ExportTableCell;

_prevalenceHeader = function(viewType, value) {
  var per1000text;
  return per1000text = viewType === 'evidence_short' ? $.t('es:outcome.diagnostic.effect_per_1000_patients_for_pre_test', {
    prob: value
  }) : "" + ($.t('es:outcome.diagnostic.prevalence')) + " " + value + "%";
};

_buildThead = function(theadSchema, viewType, model) {
  var calculatedRowspan, cellLabel, cellParams, comparatorTestPresent, labelText, placeholder, placeholderValue, prevalences, row, thead, theadRow, _i, _len, _ref;
  prevalences = model.get('prevalences');
  comparatorTestPresent = model.get('comparatorTestPresent');
  thead = [];
  for (_i = 0, _len = theadSchema.length; _i < _len; _i++) {
    row = theadSchema[_i];
    theadRow = {
      cells: []
    };
    for (cellLabel in row) {
      cellParams = row[cellLabel];
      calculatedRowspan = comparatorTestPresent ? cellParams[0] + 1 : cellParams[0];
      placeholder = (_ref = cellLabel.match(/\[[a-z]+\]/)) != null ? _ref[0] : void 0;
      labelText = !placeholder ? theadLabels[cellLabel] : (placeholderValue = placeholder === '[comparatorTest]' ? comparatorTest : indexTest, cellLabel = cellLabel.replace(placeholder, ''), "" + theadLabels[cellLabel] + " " + placeholderValue);
      if (cellLabel === 'prevalences') {
        if (viewType === 'evidence_short') {
          prevalences.map(function(_arg) {
            var num, value;
            num = _arg.num, value = _arg.value;
            return theadRow.cells.push(new Rm5ExportTableCell({
              type: 'th',
              textContent: _prevalenceHeader(viewType, value),
              colspan: comparatorTestPresent ? 2 : 1,
              rowspan: 1,
              name: "thPrevalence"
            }));
          });
        } else {
          prevalences.map(function(_arg) {
            var num, value;
            num = _arg.num, value = _arg.value;
            return theadRow.cells.push(new Rm5ExportTableCell({
              type: 'th',
              textContent: _prevalenceHeader(viewType, value),
              colspan: comparatorTestPresent ? 2 : 1,
              rowspan: 1,
              name: "thPrevalence"
            }));
          });
        }
      } else if (cellLabel === 'resultsPer1000') {
        cellLabel = _.string.capitalize(cellLabel);
        theadRow.cells.push(new Rm5ExportTableCell({
          type: 'th',
          textContent: labelText,
          rowspan: 1,
          colspan: comparatorTestPresent ? prevalences.length * 2 : prevalences.length,
          name: "th" + cellLabel
        }));
      } else {
        cellLabel = _.string.capitalize(cellLabel);
        theadRow.cells.push(new Rm5ExportTableCell({
          type: 'th',
          textContent: labelText,
          rowspan: calculatedRowspan,
          colspan: cellParams[1],
          name: "th" + cellLabel
        }));
      }
    }
    thead.push(theadRow);
  }
  if (comparatorTestPresent) {
    theadRow = {
      cells: []
    };
    prevalences.map(function(_arg) {
      var num, value;
      num = _arg.num, value = _arg.value;
      theadRow.cells.push(new Rm5ExportTableCell({
        type: 'th',
        textContent: model.get('indexTest'),
        colspan: 1,
        rowspan: 1,
        name: "thIndexTest"
      }));
      return theadRow.cells.push(new Rm5ExportTableCell({
        type: 'th',
        textContent: model.get('comparatorTest'),
        colspan: 1,
        rowspan: 1,
        name: "thComparatorTest"
      }));
    });
    thead.push(theadRow);
  }
  return thead;
};

module.exports = function(viewType, model) {
  var theadSchema;
  theadSchema = theadSchemas["" + viewType + "_regular"];
  return _buildThead(theadSchema, viewType, model);
};
