var DbDoc, callMethod, createSubDoc, docInstGetAt, docInstUpdateAt, generateGUID, lensPath, lensWhere, mapped, mediator, mergeNewData, mergeSubDocs, overReviewDocPrefix, overReviewDocShape, removeItemById, removeWhere, whereId, _ref, _ref1, _ref2;

_ref = require('lib/lens_utils'), mapped = _ref.mapped, lensPath = _ref.lensPath, whereId = _ref.whereId, lensWhere = _ref.lensWhere;

overReviewDocPrefix = require('lib/doc_prefixes').overReview;

DbDoc = require('lib/db_docs/db_doc');

_ref1 = require('base/lib/utils'), generateGUID = _ref1.generateGUID, callMethod = _ref1.callMethod;

overReviewDocShape = require('lib/db_docs/doc_shapes/over_review_doc_shape');

_ref2 = require('lib/db_docs/document_instance_helpers'), docInstUpdateAt = _ref2.docInstUpdateAt, removeItemById = _ref2.removeItemById, docInstGetAt = _ref2.docInstGetAt, createSubDoc = _ref2.createSubDoc, mergeNewData = _ref2.mergeNewData, removeWhere = _ref2.removeWhere, mergeSubDocs = _ref2.mergeSubDocs;

mediator = require('mediator');

module.exports = DbDoc(overReviewDocShape, {
  dbSetup: {
    idPrefix: overReviewDocPrefix
  },
  methods: {
    mergeStudies: function(mergeStudyIds, masterStudyText) {
      var masterStudyId;
      masterStudyId = generateGUID();
      return R.compose(docInstUpdateAt('studiesMatrix', mapped)(R.compose(R.fromPairs, R.map(R.cond([[R.compose(R.contains(R.__, mergeStudyIds), R.head), R.update(0, masterStudyId)], [R.T, R.identity]])), R.toPairs)), docInstUpdateAt('sources', mapped, 'studyIds')(R.cond([[R.compose(R.not, R.isEmpty(), R.intersection(mergeStudyIds)), R.append(masterStudyId)], [R.T, R.identity]])), docInstUpdateAt('studies', lensWhere(R.compose(R.contains(R.__, mergeStudyIds), R.prop('_id'))))(mergeNewData({
        mergedInto: masterStudyId
      })), docInstUpdateAt('studies')(R.append({
        _id: masterStudyId,
        text: masterStudyText
      })))(this);
    },
    createSource: function(sourceOrSources, studies) {
      var newMatrixData, sourceIds, sources;
      sources = _.isArray(sourceOrSources) ? sourceOrSources : [sourceOrSources];
      sourceIds = R.pluck('_id', sources);
      if (sourceIds == null) {
        mediator.dialogs.warning('`_id` field was not specified. Source was not created.');
      }
      if (sourceIds == null) {
        return this;
      }
      newMatrixData = R.reduce(function(matrix, source) {
        matrix[source._id] = R.reduce(function(acc, studyId) {
          acc[studyId] = 'included';
          return acc;
        }, {}, source.studyIds);
        return matrix;
      }, {}, sources);
      return R.compose(docInstUpdateAt('sourcesOrder')(function(order) {
        return order.concat(sourceIds);
      }), docInstUpdateAt('studies')(mergeSubDocs(studies)), docInstUpdateAt('sources')(createSubDoc(sources)), docInstUpdateAt('studiesMatrix')(mergeNewData(newMatrixData)))(this);
    },
    updateSource: function(sourceId, sourceData, studies) {
      return R.compose(docInstUpdateAt('studies')(mergeSubDocs(studies)), docInstUpdateAt('sources', sourceId)(mergeNewData(sourceData)))(this);
    },
    deleteSource: function(sourceId) {
      return R.compose(R.converge(function(activeStudyIds, docInst) {
        return docInstUpdateAt('studies')(R.innerJoin(function(study, activeStudyId) {
          return study['_id'] === activeStudyId;
        }, R.__, activeStudyIds))(docInst);
      }, [R.converge(R.union, [R.compose(R.flatten, R.values, R.map(R.keys), docInstGetAt('studiesMatrix')), R.compose(R.flatten, R.values, docInstGetAt('sources', mapped, 'studyIds'))]), R.identity]), docInstUpdateAt('reviewedOutcomes', mapped, 'sourcesOutcomesMapping')(removeItemById(sourceId)), docInstUpdateAt('sourcesOrder')(removeWhere(R.equals(sourceId))), docInstUpdateAt('studiesMatrix')(removeItemById(sourceId)), docInstUpdateAt('sources')(removeItemById(sourceId)))(this);
    },
    createSourceOutcome: function(sourceId, outcomeData) {
      return this.updateAt('sources', sourceId, 'outcomes')(createSubDoc(outcomeData));
    },
    deleteSourceOutcome: function(sourceId, outcomeId) {
      return this.updateAt('sources', sourceId, 'outcomes')(removeItemById(outcomeId));
    },
    updateSourceOutcome: function(sourceId, outcomeId, outcomeData) {
      return this.updateAt('sources', sourceId, 'outcomes', whereId(outcomeId))(mergeNewData(outcomeData));
    },
    createReviewedOutcome: function(name) {
      return this.updateAt('reviewedOutcomes')(createSubDoc({
        name: name
      }));
    },
    deleteReviewedOutcome: function(reviewedOutcomeId) {
      return this.updateAt('reviewedOutcomes')(removeItemById(reviewedOutcomeId));
    },
    updateReviewedOutcome: function(reviewedOutcomeId, outcomeData) {
      return this.updateAt('reviewedOutcomes', whereId(reviewedOutcomeId))(mergeNewData(outcomeData));
    },
    getStudySourcesCount: function(studyId) {
      var sourcesWithStudy;
      sourcesWithStudy = this.getAt('sources', lensWhere(R.compose(R.contains(studyId), R.prop('studyIds'))));
      return R.keys(sourcesWithStudy).length;
    },
    mapSourceOutcome: function(reviewedOutcomeId, sourceId, sourceOutcomeId) {
      var mappingFn;
      mappingFn = sourceOutcomeId != null ? R.assoc(sourceId, sourceOutcomeId) : R.dissoc(sourceId);
      return this.updateAt('reviewedOutcomes', whereId(reviewedOutcomeId), 'sourcesOutcomesMapping')(mappingFn);
    },
    updateSourceQualityOfReview: function(sourceId, qualityVal) {
      return this.setAt('sources', whereId(sourceId), 'qualityOfReview')(qualityVal);
    },
    updateStudiesMatrix: function(sourceId, studyId, studyStatus) {
      return this.updateAt('studiesMatrix', sourceId)(R.assoc(studyId, studyStatus));
    },
    updateSourceComment: function(sourceId, comment) {
      return this.updateAt('sources', sourceId)(mergeNewData({
        comment: comment
      }));
    }
  }
});
