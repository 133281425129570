Rating = require 'components/common/rating'

StarRatingTable = ({ rows, columns, onChangeRating, rating }) ->
  <table className="summary-of-judgements interventions-rating">
    <thead>
      <tr>
        <th className="blank"/>
          {columns.map (column) ->
            <th key={column.get('name')} className="blue-cell">{column.get('name')}</th>
          .toList()}
      </tr>
    </thead>
    <tbody>
      {rows.map (row) ->
        <tr key={row.get('name')}>
          <td>{row.get('name')}</td>
          {columns.map (column) ->
            columnId = column.get('id')
            rowId = row.get('id')
            <td key={columnId}>
              <Rating
                onRate={onChangeRating(rowId, columnId)}
                rating={rating.getIn [rowId, columnId]}
              />
            </td>
          .toList()}
        </tr>
      .toList()}
    </tbody>
  </table>

StarRatingTable.propTypes =
  rows: PropTypes.instanceOf(Immutable.Map).isRequired
  columns: PropTypes.instanceOf(Immutable.List).isRequired
  onChangeRating: PropTypes.func.isRequired
  rating: PropTypes.instanceOf(Immutable.Map)

StarRatingTable.defaultProps =
  rating: Immutable.Map()

module.exports = StarRatingTable
