var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "<div class=\"quality-compound-edit\">\n";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "SOF, SOF_ACCP, LayerOneSof, LayerTwoSof, SOF_v3", (depth0 != null ? depth0.viewTypeName : depth0), {"name":"inStringArray","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.manualQualityInput : depth0), {"name":"if","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isDiagnostic : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <div class=\"edit-input-block form-group row mt-10\">\n      <label for=\"riskOfBias\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "risk_of_bias", {"name":"i18n","hash":{},"data":data})))
    + "\n        <span class=\"context-help-icon\" data-property=\"riskOfBias\"></span>\n      </label>\n      <div class=\"inline\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.robinsIEnabled : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.program(8, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </div>\n      <div class=\"sup\"></div>\n    </div>\n    <div class=\"edit-input-block form-group row mt-10\">\n      <label for=\"inconsistency\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "inconsistency", {"name":"i18n","hash":{},"data":data})))
    + "\n        <span class=\"context-help-icon\" data-property=\"inconsistency\"></span>\n      </label>\n      <div class=\"inline\">\n        "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "inconsistency", ((stack1 = (depth0 != null ? depth0.inconsistency : depth0)) != null ? stack1.name : stack1), "None: not_serious, Serious: serious, VerySerious: very_serious", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n      </div>\n      <div class=\"sup\"></div>\n    </div>\n    <div class=\"edit-input-block form-group row mt-10\">\n        <label for=\"indirectness\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "indirectness", {"name":"i18n","hash":{},"data":data})))
    + "\n          <span class=\"context-help-icon\" data-property=\"indirectness\"></span>\n        </label>\n        <div class=\"inline\">\n          "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "indirectness", ((stack1 = (depth0 != null ? depth0.indirectness : depth0)) != null ? stack1.name : stack1), "None: not_serious, Serious: serious, VerySerious: very_serious", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n        </div>\n        <div class=\"sup\"></div>\n    </div>\n    <div class=\"edit-input-block form-group row mt-10\">\n        <label for=\"imprecision\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "imprecision", {"name":"i18n","hash":{},"data":data})))
    + "\n          <span class=\"context-help-icon\" data-property=\"imprecision\"></span>\n        </label>\n        <div class=\"inline\">\n          "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "imprecision", ((stack1 = (depth0 != null ? depth0.imprecision : depth0)) != null ? stack1.name : stack1), "None: not_serious, Serious: serious, VerySerious: very_serious, ExtremelySerious: extremely_serious", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n        </div>\n        <div class=\"sup\"></div>\n    </div>\n    <div class=\"edit-input-block form-group row mt-10\">\n      <label for=\"publicationBias\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "publication_bias", {"name":"i18n","hash":{},"data":data})))
    + "\n        <span class=\"context-help-icon\" data-property=\"publicationBias\"></span>\n      </label>\n      <div class=\"inline\">\n        "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "publicationBias", ((stack1 = (depth0 != null ? depth0.publicationBias : depth0)) != null ? stack1.name : stack1), "None: undetected, Serious: publication_bias_strongly_suspected_short", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n      </div>\n      <div class=\"sup\"></div>\n    </div>\n    <div class=\"edit-input-block form-group row mt-10\">\n        <label for=\"largeEffect\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "large_effect", {"name":"i18n","hash":{},"data":data})))
    + "\n          <span class=\"context-help-icon\" data-property=\"largeEffect\"></span>\n        </label>\n        <div class=\"inline\">\n          "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "largeEffect", ((stack1 = (depth0 != null ? depth0.largeEffect : depth0)) != null ? stack1.name : stack1), "No: no, Large: large, VeryLarge: very_large", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n        </div>\n        <div class=\"sup\"></div>\n    </div>\n    <div class=\"edit-input-block form-group row mt-10\">\n      <label for=\"plausibleConfounding\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "plausible_confounding", {"name":"i18n","hash":{},"data":data})))
    + "\n        <span class=\"context-help-icon\" data-property=\"plausibleConfounding\"></span>\n      </label>\n      <div class=\"inline\">\n        "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "plausibleConfounding", ((stack1 = (depth0 != null ? depth0.plausibleConfounding : depth0)) != null ? stack1.name : stack1), "No: no, ReducedForRR: reduced_for_RR, IncreasedForRR: increased_for_RR", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n      </div>\n      <div class=\"sup\"></div>\n    </div>\n    <div class=\"edit-input-block form-group row mt-10\">\n      <label for=\"doseResponseGradient\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dose_response_gradient", {"name":"i18n","hash":{},"data":data})))
    + "\n        <span class=\"context-help-icon\" data-property=\"doseResponseGradient\"></span>\n      </label>\n      <div class=\"inline\">\n        "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "doseResponseGradient", ((stack1 = (depth0 != null ? depth0.doseResponseGradient : depth0)) != null ? stack1.name : stack1), "No: no, Large: yes", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n      </div>\n      <div class=\"sup\"></div>\n    </div>\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.manualQualityInput : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <div class=\"edit-input-block form-group row mt-10\">\n        <label for=\"designStudies\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "study_design", {"name":"i18n","hash":{},"data":data})))
    + "\n          <span class=\"context-help-icon\" data-property=\"designStudies\"></span>\n        </label>\n        <div class=\"inline\">\n          "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "designStudies", ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.cross_sectional_cohort_type: cross_sectional_cohort_type,\n            ObsStudies.case_control_type_accuracy: case_control_type_accuracy", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n          </div>\n        <div class=\"sup\"></div>\n      </div>\n";
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "riskOfBias", ((stack1 = (depth0 != null ? depth0.riskOfBias : depth0)) != null ? stack1.name : stack1), "None: not_serious,\n            Serious: serious,\n            VerySerious: very_serious,\n            ExtremelySerious: extremely_serious", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n";
},"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "riskOfBias", ((stack1 = (depth0 != null ? depth0.riskOfBias : depth0)) != null ? stack1.name : stack1), "None: not_serious, Serious: serious, VerySerious: very_serious", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n";
},"10":function(depth0,helpers,partials,data) {
  return "<hr>";
  },"12":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <div class=\"edit-input-block form-group row mt-10\">\n      <label for=\"doseResponseGradient\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "certainty", {"name":"i18n","hash":{},"data":data})))
    + "\n        <span class=\"context-help-icon\" data-property=\"doseResponseGradient\"></span>\n      </label>\n      <div class=\"inline\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.acpServer : depth0), {"name":"if","hash":{},"fn":this.program(13, data),"inverse":this.program(15, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n      <div class=\"sup\"></div>\n    </div>\n";
},"13":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          "
    + escapeExpression(((helpers.selectableNumberAttr || (depth0 && depth0.selectableNumberAttr) || helperMissing).call(depth0, "quality", (depth0 != null ? depth0.quality : depth0), "4: qualities.high, 3: qualities.moderate, 2: qualities.low, 1: qualities.very_low_acp", {"name":"selectableNumberAttr","hash":{},"data":data})))
    + "\n";
},"15":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          "
    + escapeExpression(((helpers.selectableNumberAttr || (depth0 && depth0.selectableNumberAttr) || helperMissing).call(depth0, "quality", (depth0 != null ? depth0.quality : depth0), "4: qualities.high, 3: qualities.moderate, 2: qualities.low, 1: qualities.very_low", {"name":"selectableNumberAttr","hash":{},"data":data})))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "quality", "certainty", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}