SubscriptionsScreen = require 'components/auth/subscriptions_screen'
Spinner = require 'components/common/spinner'
IconButton = require 'components/common/icon_button'
Modal = require 'components/common/modal'
Confirmation = require 'components/common/confirmation'
Translation = require 'components/mixins/translation'
DateUtils = require 'lib/date_utils'
mediator = require 'mediator'

SubscriptionInformation = createReactClass
  displayName: 'SubscriptionInformation'

  mixins: [Translation('account.subscription_information')]

  componentDidMount: ->
    @fetchInfo()

  getInitialState: ->
    loading: true
    confirming: false

  fetchInfo: ->
    @setState(loading: true, error: null)
    @props.service.info().then (info) =>
      @setState({info})
    , (error) =>
      @setState({error})
    .finally => @setState(loading: false)

  confirm: (callback) -> @setState(confirming: true, confirmationCallback: callback)
  confirmationClosed: -> @setState(confirming: false, confirmationCallback: null)
  confirmed: ->
    @state.confirmationCallback?()
    @confirmationClosed()

  cancelAcademic: ->
    @confirm =>
      @props.service.cancelAcademic().catch mediator.dialogs.fatalError

  manage: ->
    @props.service.manage(@fetchInfo).catch mediator.dialogs.fatalError

  renderFormGroup: ->
    return <Spinner /> if @state.loading
    if @state.error
      return <div className="form-group">
        <div className="row">
          <div className="error">{@state.error.message}</div>
        </div>
      </div>

    unless @state.info.currentSubscriptionType
      return <div className="form-group">
        <div className="row">
          <label>{@i18n('no_individual')}</label>
        </div>
      </div>

    <div className="form-group">
      <div className="row">
        <div className="col-3">
          <label>{@i18n('type')}</label>
        </div>
        <div className="col-9">
          <label>{@i18n("type_#{@state.info.currentSubscriptionType}")}</label>
          {@state.info.currentSubscriptionType is 'academic' and
            <span className="explanation"
              dangerouslySetInnerHTML={__html: @i18n('academic_limitations', tosLink:
                "<a href='#{@i18n '/terms_of_service_url'}' target='_blank'>
                  #{@i18n '/terms_of_service'}
                </a>")} />
          }
        </div>
      </div>
      {@state.info.latestSubscription?.expires and
        <div className="row">
          <div className="col-3">
            <label>{@i18n('expires')}</label>
          </div>
          <div className="col-9 value">
            {DateUtils.format(@state.info.latestSubscription.expires)}
          </div>
        </div>
      }
      {@state.info.currentSubscriptionType is 'academic' and
        <InstitutionEditor value={@state.info.latestSubscription.institution}
          service={@props.service} onChange={@fetchInfo} />
      }
    </div>

  renderButtons: ->
    return if @state.loading or @state.error
    switch @state.info.currentSubscriptionType
      when 'academic'
        <div className="row">
          <div className="col-4 col-offset-4">
            <button className="btn btn-block" onClick={@cancelAcademic}>
              {@i18n('cancel_subscription')}
            </button>
          </div>
          <div className="col-4">
            <UpgradeAcademicButton service={@props.service} />
          </div>
        </div>
      when 'standard'
        <div className="row">
          <div className="col-4 col-offset-8">
            <button className="btn btn-block" onClick={@manage}>
              {@i18n('manage_subscription')}
            </button>
          </div>
        </div>

  render: ->
    <div className="form form-horizontal">
      <div className="row">
          <h3>{@i18n('title')}</h3>
      </div>
      {@renderFormGroup()}
      {@renderButtons()}
      {@state.confirming and
        <Modal
          isOpen
          onClose={@confirmationClosed}
          className='onboarding-notification confirmation-modal'
        >
          <Confirmation
            question={@i18n('cancel_confirmation')}
            onCancel={@confirmationClosed}
            onConfirm={@confirmed}
          />
        </Modal>
      }
    </div>

InstitutionEditor = createReactClass
  displayName: 'InstitutionEditor'

  mixins: [Translation('account.subscription_information')]

  getInitialState: ->
    editing: false

  valueChanged: (e) -> @setState(value: e.target.value)

  startEditting: -> @setState(editing: true, value: @props.value)
  save: ->
    @setState(submitting: true)
    @props.service.checkoutAcademic(@state.value)
    .finally => @setState(submitting: false)
    .then @props.onChange, mediator.dialogs.fatalError

  render: ->
    <div className="row">
      <div className="col-3">
        <label>{@i18n('institution')}</label>
      </div>
      <div className="col-8">
        {if @state.editing
          <input className="form-control" type="text" value={@state.value}
            onChange={@valueChanged} />
        else
          <span className="value">{@props.value}</span>
        }
      </div>
      <div className="col-1">
        {if @state.editing
          <IconButton iconName="save" disabled={@state.submitting or not @state.value?.trim()}
            onClick={@save} />
        else
          <IconButton iconName="edit" onClick={@startEditting} />
        }
      </div>
    </div>

UpgradeAcademicButton = createReactClass
  displayName: 'UpgradeAcademicButton'

  mixins: [Translation('account.subscription_information')]

  getInitialState: ->
    upgrading: false

  upgrade: -> @setState(upgrading: true)
  closed: -> @setState(upgrading: false)
  reload: -> mediator.publish '!reloadApplication'

  render: ->
    <div>
      <button className="btn btn-block btn-alternative" onClick={@upgrade}>
        {@i18n('upgrade_subscription')}
      </button>
      {@state.upgrading and
        <Modal isOpen onClose={@closed} closeButton={true}
          style={content: {
            position: 'absolute'
            top: '50%'
            bottom: 'auto'
            left: '50%'
            right: 'auto'
            transform: 'translate(-50%, -50%)'
          }}
        >
          <SubscriptionsScreen subscriptionService={@props.service} hideAcademic={true}
            onSuccess={@reload} onAuthError={@reload} />
        </Modal>
      }
    </div>

module.exports = SubscriptionInformation
