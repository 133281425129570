ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Modal =  require 'components/common/modal'
Translation = require 'components/mixins/translation'
EditableTextItem = require 'components/over_review/editable_text_item'
{ getKeyValObject, generateGUID } = require 'base/lib/utils'
{ whereId } = require 'lib/lens_utils'
{ mergeNewData } = require 'lib/db_docs/document_instance_helpers'
{ instanceOf, object, func, array } = PropTypes

SOURCE_FIELDS = [ 'sourceName', 'intervention', 'comparison', 'population' ]

SourceEditModal = createReactClass
  displayName: 'SourceEditModal'

  propTypes:
    sourceEditData: object
    onClose: func.isRequired
    onSave: func.isRequired
    suggestStudies: func

  mixins: [
    Translation('over_review:titles')
  ]

  getInitialState: ->
    initialState =
      editingStudyId: null
      editableStudies: {}
      studyEditConfirmation: null

    R.merge initialState, @props.sourceEditData

  # to be able to submit one must populate all source fields and create at least one outcome and
  # at least one study
  _hasEmtpyFields: ->
    SOURCE_FIELDS.reduce (areEmpty, fieldName) =>
      areEmpty or _.isEmpty @state[fieldName]
    , false

  _hasEmptyOucomes: ->
    { outcomes } = @state
    _.isEmpty(outcomes) or R.any R.compose(R.isEmpty, R.prop('name')), outcomes

  _hasEmptyStudies: ->
    { studies } = @state
    _.isEmpty(studies) or R.any R.compose(R.isEmpty, R.prop('text')), studies

  canSave: ->
    not (@_hasEmtpyFields() or @_hasEmptyOucomes() or @_hasEmptyStudies())

  handleSave: ->
    @props.onSave R.omit ['editingStudyId', 'editableStudies', 'studyEditConfirmation'], @state

  showStudyEditConfirmation: (studyId, editedText, sourcesCount) ->
    @setState studyEditConfirmation: { studyId, editedText, sourcesCount }

  handleStudyEditConfirmation: (cancelled, affectsAllSources) -> =>
    return @setState studyEditConfirmation: null if cancelled
    { studyId, editedText, sourcesCount } = @state.studyEditConfirmation

    if affectsAllSources
      @setState
        studyEditConfirmation: null
        studies: R.over whereId(studyId), mergeNewData(text: editedText), @state.studies
        editableStudies: R.assoc studyId, true, @state.editableStudies
    else
      newStudyId = generateGUID()
      updatedStudies = R.over(
        whereId(studyId)
        mergeNewData(text: editedText, _id: newStudyId)
        @state.studies
      )

      @setState
        studyEditConfirmation: null
        studies: updatedStudies
        editableStudies: R.assoc newStudyId, true, @state.editableStudies

  onFieldInputChange: (evt) ->
    fieldName = evt.target.getAttribute 'name'
    fieldValue = evt.target.value

    @setState getKeyValObject fieldName, fieldValue

  handleOutcomeCreate: ->
    @setState outcomes: @state.outcomes.concat _id: generateGUID(), name: ''

  handleOutcomeChange: (outcomeId) -> (evt) =>
    @setState
      outcomes: R.over whereId(outcomeId), mergeNewData(name: evt.target.value), @state.outcomes

  handleOutcomeDelete: (outcomeId) -> =>
    outcomeIdx = R.findIndex R.whereEq(_id: outcomeId), @state.outcomes

    @setState
      outcomes: R.remove outcomeIdx, 1, @state.outcomes

  handleStudyCreate: ->
    @setState studies: @state.studies.concat _id: generateGUID(), text: ''

  handleStudyChange: (studyId) -> (evt) =>
    # study edit was confirmed or study has no other sources bound to
    text = evt.target.value
    updatedStudies = R.over whereId(studyId), mergeNewData({ text }), @state.studies
    canStudyBeEdited = @state.editableStudies[studyId]

    if canStudyBeEdited
      @setState studies: updatedStudies
    else
      sourcesCount = @props.getStudySourcesCount studyId
      if sourcesCount > 0
        @showStudyEditConfirmation studyId, text, sourcesCount
      else
        @setState
          editableStudies: R.assoc studyId, true, @state.editableStudies
          studies: updatedStudies

  handleStudyDelete: (studyId) -> =>
    studyIdx = R.findIndex R.whereEq(_id: studyId), @state.studies

    @setState
      studies: R.remove studyIdx, 1, @state.studies

  handleStudyFocus: (studyId) -> =>
    @setState editingStudyId: studyId

  handleSuggestionSelect: (studyId, suggestionId, suggestionText) -> =>
    updatedStudies = R.over(
      whereId(studyId),
      mergeNewData _id: suggestionId, text: suggestionText
      @state.studies
    )

    @setState
      editingStudyId: null
      studies: updatedStudies

  renderSourceField: (fieldName, idx) ->
    fieldValue = @state[fieldName]

    <div className='field-block' key={fieldName}>
      <span className='field-name'>
        {@i18n "../#{_.str.underscored fieldName}"}
      </span>
      <input
        autoFocus={idx is 0}
        name={fieldName}
        onChange={@onFieldInputChange}
        value={fieldValue}
      />
    </div>

  renderSourceOutcome: ({ _id, name }) ->
    <EditableTextItem
      isEditing
      key={_id}
      text={name}
      onDelete={@handleOutcomeDelete _id}
      onChange={@handleOutcomeChange _id}
    />

  renderSourceStudy: ({ _id, text }) ->
    <div key={_id}>
      <EditableTextItem
        autoFocus={false}
        inputPlaceholder={"#{@i18n '../author'}, #{@i18n '../year'}"}
        isEditing
        onFocus={@handleStudyFocus _id}
        onDelete={@handleStudyDelete _id}
        onChange={@handleStudyChange _id}
        text={text}
      />
      {if @state.editingStudyId is _id
        suggestions = @props.suggestStudies text

        unless _.isEmpty suggestions
          <div className='suggestions'>
            {suggestions.map ({ _id: suggestionId, text: suggestionText }) =>
              <div
                key={suggestionId}
                onClick={@handleSuggestionSelect _id, suggestionId, suggestionText}
              >
                {suggestionText}
              </div>
            }
          </div>
      }
    </div>

  render: ->
    { onClose } = @props
    { _id: sourceId, outcomes, studies } = @state

    <Modal
      isOpen
      title={@i18n if sourceId then 'edit_source' else 'add_source'}
      onClose={onClose}
      onRequestClose={onClose}
      className='over-review-source-edit-modal'
      modalSize='medium'
    >
      <div className='source-fields'>
        {SOURCE_FIELDS.map @renderSourceField}
      </div>
      <div className='outcomes-and-sources'>
        <div className='source-outcomes'>
          <div className='section-title'>{@i18n '../outcomes'}</div>
          <div className='source-outcomes-list'>
            {outcomes.map @renderSourceOutcome}
          </div>
          <div className='add-item'>
            <button onClick={@handleOutcomeCreate}>
              +{@i18n '../actions.add_source_outcome'}
            </button>
          </div>
        </div>
        <div className='source-studies'>
          <div className='section-title'>{@i18n '../studies'}</div>
          <div className='source-studies-list'>
            {studies.map @renderSourceStudy}
          </div>
          <div className='add-item'>
            <button onClick={@handleStudyCreate}>
              +{@i18n '../actions.add_source_study'}
            </button>
          </div>
        </div>
      </div>
      <ApplyCancelButtons
        onApply={@handleSave}
        applyLabel={@i18n '/actions.save'}
        onCancel={onClose}
        isSubmitEnabled={@canSave()}
      />
      {if @state.studyEditConfirmation
        { sourcesCount } = @state.studyEditConfirmation
        <Modal
          isOpen
          title={@i18n '../titles.study_edit_confirmation'}
          className='over-review-study-edit-comfirmation-modal'
        >
          <div>
            {@i18n '../study_edit_confirmation_text', { sourcesCount }}
          </div>
          <div className='buttons'>
            <button className='btn btn-cancel' onClick={@handleStudyEditConfirmation true}>
              {@i18n '../actions.discard_change'}
            </button>
            <button className='btn btn-success' onClick={@handleStudyEditConfirmation false, false}>
              {@i18n '../actions.this_source_only'}
            </button>
            <button className='btn btn-danger' onClick={@handleStudyEditConfirmation false, true}>
              {@i18n '../actions.all_occurrences'}
            </button>
          </div>
        </Modal>
      }
    </Modal>

module.exports = SourceEditModal
