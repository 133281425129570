var MDA_TABLE_PREFIX, MDA_TOPIC_PREFIX, MDGSearchStrategyDoc, MDG_QUESTION_PREFIX, MDG_SEARCH_STRATEGY_PREFIX, MdaTableDoc, MdaTopicDoc, MdgQuestionDoc, OverarchingQuestionDoc, QUESTION_DOC_TYPES, QuestionDoc, QuestionGroupsActions, QuestionsActions, QuestionsStatusesActions, SectionDoc, W, alt, callMethod, deleteConnectionsFromDocumentSectionsAndRecommendations, docInstSetAt, docInstUpdateAt, generateGUID, get, getDocByPrefix, getMaxQuestionsOrdering, getPrefixForDocType, getRecommendationsToUpdateWithRelatedTableWithQuestionId, markDocumentSectionsWithQuestionIdAsDeleted, mdaHelper, mediator, prefixDocMapper, prefixesByType, prepareCode, questionDocsByType, questionMappers, questionsOrderingUpdate, removeItemById, set, showUndoNotification, updateGroupsDoc, whereId, _ref, _ref1, _ref2, _ref3, _ref4,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

alt = require('alt');

callMethod = require('base/lib/utils').callMethod;

_ref = require('lodash'), get = _ref.get, set = _ref.set;

mdaHelper = require('lib/mda_helper');

MdaTableDoc = require('lib/db_docs/mda_table_doc');

MdaTopicDoc = require('lib/db_docs/mda_topic_doc');

MdgQuestionDoc = require('lib/db_docs/mdg_question_doc');

MDGSearchStrategyDoc = require('lib/db_docs/mdg_search_strategy_doc');

mediator = require('mediator');

OverarchingQuestionDoc = require('lib/db_docs/overarching_question_doc');

QuestionsStatusesActions = require('actions/questions_statuses_actions');

QuestionDoc = require('lib/db_docs/question_doc');

QuestionGroupsActions = require('actions/question_groups_actions');

SectionDoc = require('lib/db_docs/document_sections_v2_section_doc');

W = require('when');

_ref1 = require('lib/doc_prefixes'), MDA_TABLE_PREFIX = _ref1.mdaTable, MDA_TOPIC_PREFIX = _ref1.mdaTopic, MDG_QUESTION_PREFIX = _ref1.mdgQuestion, MDG_SEARCH_STRATEGY_PREFIX = _ref1.mdgSearchStrategy;

whereId = require('lib/lens_utils').whereId;

_ref2 = require('lib/db_docs/document_instance_helpers'), docInstUpdateAt = _ref2.docInstUpdateAt, docInstSetAt = _ref2.docInstSetAt, removeItemById = _ref2.removeItemById;

generateGUID = require('base/lib/utils').generateGUID;

_ref3 = require('lib/questions_helper'), QUESTION_DOC_TYPES = _ref3.QUESTION_DOC_TYPES, prepareCode = _ref3.prepareCode;

_ref4 = require('lib/question_groups_helper'), updateGroupsDoc = _ref4.updateGroupsDoc, questionsOrderingUpdate = _ref4.questionsOrderingUpdate, getMaxQuestionsOrdering = _ref4.getMaxQuestionsOrdering;

questionDocsByType = {
  mdaTable: MdaTableDoc,
  mdaTopic: MdaTopicDoc,
  mdgQuestion: MdgQuestionDoc,
  regular: QuestionDoc
};

getDocByPrefix = function(docId) {
  if (mediator.services.switches.isOn('mdgTables')) {
    return MdgQuestionDoc;
  }
  return _.reduce([[MDA_TABLE_PREFIX, MdaTableDoc], [MDA_TOPIC_PREFIX, MdaTopicDoc], [MDG_QUESTION_PREFIX, MdgQuestionDoc]], function(acc, _arg) {
    var doc, prefix;
    prefix = _arg[0], doc = _arg[1];
    if (docId.indexOf(prefix) === 0) {
      return doc;
    } else {
      return acc;
    }
  }, QuestionDoc);
};

prefixesByType = {
  mdaTable: MDA_TABLE_PREFIX,
  mdaTopic: MDA_TOPIC_PREFIX,
  mdgQuestion: MDG_QUESTION_PREFIX
};

getPrefixForDocType = function(docType) {
  var _ref5;
  return (_ref5 = prefixesByType[docType]) != null ? _ref5 : '';
};

prefixDocMapper = function(_arg) {
  var rows;
  rows = _arg.rows;
  return _.pluck(rows, 'doc');
};

questionMappers = {
  mdaTopic: prefixDocMapper,
  mdaTable: prefixDocMapper,
  mdgQuestion: prefixDocMapper
};

showUndoNotification = function(okCb) {
  var notification;
  return notification = mediator.dialogs.undo($.t('es:question.deleted')).on('click:undo', function() {
    notification.destroy();
    return okCb();
  });
};

markDocumentSectionsWithQuestionIdAsDeleted = function(projectId, questionId) {
  return SectionDoc.at(projectId).fetchMany().then(function(sections) {
    var parentsToFetch, sectionIds, sectionsToDelete, updatedSections;
    sectionsToDelete = _.filter(sections, function(section) {
      return section.questionId === questionId;
    });
    if (_.isEmpty(sectionsToDelete)) {
      return [];
    }
    sectionIds = _.pluck(sectionsToDelete, '_id');
    parentsToFetch = _.compact(_.pluck(sectionsToDelete, 'parent'));
    updatedSections = _.map(sectionsToDelete, function(section) {
      return {
        _id: section._id,
        _rev: section._rev,
        _deleted: true
      };
    });
    if (_.isEmpty(parentsToFetch)) {
      return updatedSections;
    }
    return mediator.services.storePersistenceAdapter.fetch(projectId, parentsToFetch).then(function(_arg) {
      var parentRows, parents, updatedParents;
      parentRows = _arg.rows;
      parents = _.compact(_.pluck(parentRows, 'doc'));
      updatedParents = _.map(parents, function(parent) {
        parent.sections = _.difference(parent.sections, sectionIds);
        return parent;
      });
      return _.union(updatedParents, updatedSections);
    });
  });
};

getRecommendationsToUpdateWithRelatedTableWithQuestionId = function(projectId, questionId) {
  return W.all([MdgQuestionDoc.at(projectId).fetchMany(), QuestionDoc.at(projectId).fetchMany()]).then(function(_arg) {
    var etdDocsToFetch, mdgQuestionRows, questionDocs, _ref5;
    (_ref5 = _arg[0], mdgQuestionRows = _ref5.rows), questionDocs = _arg[1];
    etdDocsToFetch = _.chain(mdgQuestionRows).pluck('doc').union(questionDocs).compact().map(function(question) {
      var _ref6;
      return (_ref6 = question.recommendationIds) != null ? _ref6 : [];
    }).flatten().uniq().value();
    if (_.isEmpty(etdDocsToFetch)) {
      return [];
    }
    return mediator.services.storePersistenceAdapter.fetch(projectId, etdDocsToFetch).then(function(_arg1) {
      var recommendationRows, recommendations;
      recommendationRows = _arg1.rows;
      recommendations = _.compact(_.pluck(recommendationRows, 'doc'));
      return _.reduce(recommendations, function(acc, recommendation) {
        var mdgRelatedTables, regularRelatedQuestionIds;
        mdgRelatedTables = get(recommendation, 'templateData.conclusions.sections.recommendation.relatedTable', []);
        if (__indexOf.call(mdgRelatedTables, questionId) >= 0) {
          acc.push(set(recommendation, 'templateData.conclusions.sections.recommendation.relatedTable', _.without(mdgRelatedTables, questionId)));
        }
        regularRelatedQuestionIds = get(recommendation, 'templateData.relatedQuestionsIds', []);
        if (__indexOf.call(regularRelatedQuestionIds, questionId) >= 0) {
          acc.push(set(recommendation, 'templateData.relatedQuestionsIds', _.without(regularRelatedQuestionIds, questionId)));
        }
        return acc;
      }, []);
    });
  });
};

deleteConnectionsFromDocumentSectionsAndRecommendations = function(projectId, questionId) {
  return W.all([markDocumentSectionsWithQuestionIdAsDeleted(projectId, questionId), getRecommendationsToUpdateWithRelatedTableWithQuestionId(projectId, questionId)]).then(function(docs) {
    var docsToSave;
    docsToSave = _.flatten(docs);
    if (_.isEmpty(docsToSave)) {
      return;
    }
    return mediator.services.storePersistenceAdapter.bulkDocs(projectId, docsToSave);
  });
};

QuestionsActions = (function() {
  function QuestionsActions() {
    this.generateActions('fetchSuccess', 'fetchError', 'pouchSaveError', 'createQuestionSuccess', 'createQuestionError', 'deleteQuestionSuccess', 'deleteQuestionError', 'undoQuestionDeleteSuccess', 'startQuestionEdit', 'stopQuestionEdit', 'updateQuestionSuccess', 'updateQuestionError', 'duplicateQuestionSuccess', 'duplicateQuestionError', 'getQuestionOverReviewModuleStateSuccess', 'getQuestionOverReviewModuleStateError', 'openMdgCreateQuestionDialog', 'openMdgEditQuestionDialog', 'closeMdgCreateQuestionDialog', 'changeMdgCreateQuestionDialogQuestionType', 'setMdgCreateQuestionDialogQuestionDataField', 'addMdgCreateQuestionDialogCode', 'removeMdgCreateQuestionDialogCode', 'createMdgQuestionSuccess', 'updateMdgQuestionSuccess', 'createMdgQuestionError', 'updateMdgQuestionError');
  }

  QuestionsActions.prototype.dbChange = function(change, _pending, _lastSeq) {
    var doc, _ref5;
    doc = change.doc;
    if (_ref5 = doc.docType, __indexOf.call(QUESTION_DOC_TYPES, _ref5) < 0) {
      return;
    }
    return this.dispatch(change);
  };

  QuestionsActions.prototype.fetch = function(params) {
    var Doc, dbId, projectId, questionType, _ref5;
    if (params == null) {
      params = {};
    }
    dbId = params.dbId, projectId = params.projectId, questionType = params.questionType;
    this.dispatch();
    if (dbId == null) {
      dbId = projectId != null ? projectId : mediator.project.id;
    }
    if (questionType == null) {
      questionType = mediator.services.switches.isOn('mdgTables') ? 'mdgQuestion' : 'regular';
    }
    Doc = questionDocsByType[questionType];
    if (!Doc) {
      throw new Error("No Doc for type " + questionType);
    }
    return Doc.at(dbId).fetchMany().then((_ref5 = questionMappers[questionType]) != null ? _ref5 : _.identity).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
  };

  QuestionsActions.prototype.updateQuestion = function(params) {
    var projectId, questionData, questionId;
    this.dispatch(params);
    projectId = mediator.project.id;
    questionId = params.questionId, questionData = params.questionData;
    return getDocByPrefix(questionId).at(projectId, questionId).update(questionData).then(this.actions.updateQuestionSuccess)["catch"]((function(_this) {
      return function(err) {
        _this.actions.updateQuestionError({
          questionId: questionId,
          err: err
        });
        return _this.actions.pouchSaveError(err);
      };
    })(this));
  };

  QuestionsActions.prototype.addCode = function(_arg) {
    var code, codeGroup, codes, payload, projectId, questionId;
    codeGroup = _arg.codeGroup, payload = _arg.payload;
    projectId = mediator.project.id;
    questionId = payload.parentId, codes = payload.codes;
    code = codes.first();
    return QuestionDoc.at(projectId, questionId).updateAsInstance(docInstSetAt('codes', codeGroup, code.get('@id'))(prepareCode(code)))["catch"]((function(_this) {
      return function(err) {
        _this.actions.updateQuestionError({
          questionId: questionId,
          err: err
        });
        return _this.actions.pouchSaveError(err);
      };
    })(this));
  };

  QuestionsActions.prototype.removeCode = function(_arg) {
    var code, codeGroup, payload, projectId, questionId;
    codeGroup = _arg.codeGroup, payload = _arg.payload;
    projectId = mediator.project.id;
    questionId = payload.parentId, code = payload.code;
    return QuestionDoc.at(projectId, questionId).updateAsInstance(docInstUpdateAt('codes', codeGroup)(removeItemById(code.get('id'))))["catch"]((function(_this) {
      return function(err) {
        _this.actions.updateQuestionError({
          questionId: questionId,
          err: err
        });
        return _this.actions.pouchSaveError(err);
      };
    })(this));
  };

  QuestionsActions.prototype.updatePlainLanguageSummary = function(data) {
    var outcomeId, projectId, questionId, text;
    questionId = data.questionId, outcomeId = data.outcomeId, text = data.text;
    projectId = mediator.project.id;
    return QuestionDoc.at(projectId, questionId).updateAsInstance(docInstSetAt('outcomes', whereId(outcomeId), 'plain_language_summary')(text))["catch"](this.actions.pouchSaveError);
  };

  QuestionsActions.prototype.createQuestion = function(_arg) {
    var attributes, projectId, questionsCollectionType;
    questionsCollectionType = _arg.questionsCollectionType, attributes = _arg.attributes;
    QuestionDoc = questionDocsByType[questionsCollectionType];
    projectId = mediator.project.id;
    return QuestionDoc.at(projectId).create(attributes).then((function(_this) {
      return function(createdQuestion) {
        QuestionGroupsActions.updateQuestionPosition({
          questionType: questionsCollectionType,
          qId: createdQuestion['_id'],
          orderNumber: null
        })["finally"](function() {
          return _this.actions.createQuestionSuccess(createdQuestion);
        });
        return createdQuestion;
      };
    })(this))["catch"]((function(_this) {
      return function(err) {
        _this.actions.createQuestionError(err);
        return _this.actions.pouchSaveError(err);
      };
    })(this));
  };

  QuestionsActions.prototype.createQuestions = function(_arg) {
    var projectId, questions, questionsCollectionType;
    questionsCollectionType = _arg.questionsCollectionType, questions = _arg.questions, projectId = _arg.projectId;
    QuestionDoc = questionDocsByType[questionsCollectionType];
    if (projectId == null) {
      projectId = mediator.project.id;
    }
    return getMaxQuestionsOrdering(questionsCollectionType, projectId).then(function(maxQuestionsOrdering) {
      return QuestionDoc.at(projectId).createMany(questions).then(function(addingResult) {
        var createdQuestions, updater;
        createdQuestions = _.map(questions, function(questionDoc, idx) {
          return _.extend(questionDoc, {
            _id: addingResult[idx].id,
            _rev: addingResult[idx].rev
          });
        });
        updater = function(doc) {
          return _.reduce(createdQuestions, function(acc, questionDoc, idx) {
            var questionUpdater;
            questionUpdater = questionsOrderingUpdate(questionDoc._id, maxQuestionsOrdering + idx + 1);
            return questionUpdater(acc);
          }, doc);
        };
        return updateGroupsDoc(questionsCollectionType, updater, projectId).then(function() {
          return createdQuestions;
        });
      });
    });
  };

  QuestionsActions.prototype.deleteQuestion = function(questionId, showUndo) {
    var confirmOptions, questionText, _ref5;
    if (showUndo == null) {
      showUndo = true;
    }
    questionText = ((_ref5 = this.alt.stores.QuestionsStore.getQuestion(questionId)) != null ? _ref5.get('question') : void 0) || '';
    confirmOptions = {
      title: $.t('es:delete_question_confirmation.title'),
      message: $.t('es:delete_question_confirmation.message', {
        question: questionText
      }),
      confirmText: $.t('actions.delete'),
      declineText: $.t('actions.cancel')
    };
    return mediator.dialogs.confirm(confirmOptions, (function(_this) {
      return function(confirmed) {
        var etdHelper, projectId;
        if (!confirmed) {
          return;
        }
        projectId = mediator.project.id;
        _this.dispatch(questionId);
        etdHelper = require('lib/etd_helper');
        return getDocByPrefix(questionId).at(projectId, questionId)["delete"]().then(function(deletedQuestion) {
          var groupId, questionType, removeRelatedDocumentsPromise;
          questionType = _this.alt.stores.QuestionsStore.getQuestionType(questionId);
          groupId = _this.alt.stores.QuestionGroupsStore.getGroupId(questionType, questionId);
          if (groupId !== 'questionsWithoutGroup') {
            QuestionGroupsActions.removeQuestionFromGroup({
              questionType: questionType,
              qId: questionId
            });
          }
          removeRelatedDocumentsPromise = deletedQuestion.topicRootId ? mdaHelper.deleteTopicRelatedDocs(deletedQuestion.topicRootId) : etdHelper.removeRecommendationTable(deletedQuestion.recommendationIds);
          return W.all([removeRelatedDocumentsPromise, QuestionsStatusesActions.removeQuestionStatusesDoc(questionId), deleteConnectionsFromDocumentSectionsAndRecommendations(projectId, questionId)]).then(function(_arg) {
            var deletedRecommendationDocs;
            deletedRecommendationDocs = _arg[0];
            return mediator.services.overReviewService.deleteOverReview(questionId).then(function(deletedOverReview) {
              var undoActionBound;
              if (showUndo) {
                undoActionBound = _this.actions.undoQuestionDelete.bind(_this.actions, {
                  questionType: questionType,
                  questionId: questionId,
                  groupId: groupId
                });
                showUndoNotification(undoActionBound);
              }
              _this.actions.deleteQuestionSuccess({
                deletedQuestion: deletedQuestion,
                deletedRecommendationDocs: deletedRecommendationDocs,
                deletedOverReview: deletedOverReview
              });
              return deletedQuestion;
            });
          });
        })["catch"](function(err) {
          _this.actions.deleteQuestionError({
            questionId: questionId,
            err: err
          });
          return _this.actions.pouchSaveError(err);
        });
      };
    })(this));
  };

  QuestionsActions.prototype.undoQuestionDelete = function(_arg) {
    var QuestionsStatusesStore, QuestionsStore, deletedQuestionDoc, etdHelper, groupId, projectId, questionId, questionType, restoredQuestionDoc, _ref5;
    questionId = _arg.questionId, questionType = _arg.questionType, groupId = _arg.groupId;
    etdHelper = require('lib/etd_helper');
    _ref5 = this.alt.stores, QuestionsStore = _ref5.QuestionsStore, QuestionsStatusesStore = _ref5.QuestionsStatusesStore;
    deletedQuestionDoc = QuestionsStore.getDeletedQuestionDoc(questionId);
    if (!deletedQuestionDoc) {
      return;
    }
    projectId = mediator.project.id;
    restoredQuestionDoc = deletedQuestionDoc["delete"]('_deleted').toJS();
    return getDocByPrefix(questionId).at(projectId).save(restoredQuestionDoc).then((function(_this) {
      return function(qDoc) {
        var deletedOverReviewDoc, deletedQuestionsStatusesDoc, deletedRecommendationDocs;
        deletedRecommendationDocs = QuestionsStore.getDeletedRecommendationDocs(questionId);
        deletedOverReviewDoc = QuestionsStore.getDeletedOverReviewDoc(questionId);
        deletedQuestionsStatusesDoc = QuestionsStatusesStore.getDeletedQuestionStatusesDoc(questionId);
        return etdHelper.restoreRecommendationTables(deletedRecommendationDocs).then(function() {
          return QuestionsStatusesActions.restoreDeletedDoc(deletedQuestionsStatusesDoc);
        }).then(function() {
          if (deletedOverReviewDoc != null) {
            return mediator.services.overReviewService.restoreOverReviewDocument(deletedOverReviewDoc);
          }
        }).then(function() {
          if (groupId !== 'questionsWithoutGroup') {
            QuestionGroupsActions.moveQuestionToGroup({
              questionType: questionType,
              qId: questionId,
              groupId: groupId
            });
          }
          return _this.actions.undoQuestionDeleteSuccess(qDoc);
        });
      };
    })(this))["catch"](this.actions.pouchSaveError);
  };

  QuestionsActions.prototype.duplicateQuestion = function(_arg) {
    var Doc, OverarchingQuestionsStore, QuestionGroupsStore, QuestionsStore, docPrefix, duplicateQuestionData, duplicateQuestionOrderNumber, etdHelper, groupId, newQuestionId, projectId, questionData, questionId, questionType, sourceQuestion, sourceQuestionOrderNumber, type, _ref5;
    questionId = _arg.questionId, type = _arg.type;
    etdHelper = require('lib/etd_helper');
    _ref5 = this.alt.stores, OverarchingQuestionsStore = _ref5.OverarchingQuestionsStore, QuestionsStore = _ref5.QuestionsStore, QuestionGroupsStore = _ref5.QuestionGroupsStore;
    questionType = QuestionsStore.getQuestionType(questionId);
    sourceQuestion = type === 'overarching' ? OverarchingQuestionsStore.getQuestion(questionId) : QuestionsStore.getQuestion(questionId);
    sourceQuestionOrderNumber = QuestionGroupsStore.getQuestionOrderNumber(questionType, questionId);
    questionData = _.omit(sourceQuestion.toJS(), '_id', '_rev', 'orderNumber', 'recommendationIds', 'revManWebReviewId', 'revManWebTableId', 'revManWebAnalysisGroupId', 'revManWebAnalysisGroupName');
    groupId = QuestionGroupsStore.getGroupId(questionType, questionId);
    projectId = mediator.project.id;
    duplicateQuestionOrderNumber = (sourceQuestionOrderNumber != null ? sourceQuestionOrderNumber : sourceQuestion.get('orderNumber', 0)) + 0.01;
    docPrefix = getPrefixForDocType(questionType);
    newQuestionId = !_.isEmpty(docPrefix) ? "" + docPrefix + (generateGUID()) : void 0;
    duplicateQuestionData = _.extend(questionData, {
      orderNumber: duplicateQuestionOrderNumber,
      _id: newQuestionId
    });
    this.dispatch(questionId);
    Doc = type === 'overarching' ? OverarchingQuestionDoc : getDocByPrefix(questionId);
    return Doc.at(projectId).create(duplicateQuestionData).then(function(duplicatedQuestion) {
      return QuestionGroupsActions.updateQuestionPosition({
        groupId: groupId,
        orderNumber: duplicateQuestionOrderNumber,
        qId: duplicatedQuestion['_id'],
        questionType: questionType
      }).then(function() {
        return duplicatedQuestion;
      });
    }).then(function(duplicatedQuestion) {
      var duplicatedQuestionId, recommendationIdsArray, sourceRecommendationIds, _ref6;
      sourceRecommendationIds = (_ref6 = sourceQuestion.get('recommendationIds')) != null ? _ref6 : Immutable.List();
      if (sourceRecommendationIds.isEmpty()) {
        return duplicatedQuestion;
      } else {
        recommendationIdsArray = sourceRecommendationIds.toJS();
        duplicatedQuestionId = duplicatedQuestion['_id'];
        return etdHelper.duplicateRecommendationTable(recommendationIdsArray, duplicatedQuestionId).then(function(recommendationsTable) {
          return Doc.at(projectId, duplicatedQuestionId).update({
            'recommendationIds': [recommendationsTable['_id']]
          });
        });
      }
    }).then(function(duplicatedQuestion) {
      var duplicatedQuestionId, newSearchStrategyId, sourceSearchStrategyId;
      sourceSearchStrategyId = sourceQuestion.get('searchStrategyId', null);
      if (sourceSearchStrategyId) {
        duplicatedQuestionId = duplicatedQuestion['_id'];
        newSearchStrategyId = "" + MDG_SEARCH_STRATEGY_PREFIX + (generateGUID());
        return MDGSearchStrategyDoc.at(projectId, sourceSearchStrategyId).fetch().then(function(originalSearchStrategy) {
          return MDGSearchStrategyDoc.at(projectId).create(_.extend(originalSearchStrategy, {
            _id: newSearchStrategyId,
            _rev: null
          })).then(function(newSearchStrategy) {
            return Doc.at(projectId, duplicatedQuestionId).updateAsInstance(function(doc) {
              return doc.setAt('searchStrategyId')(newSearchStrategyId);
            });
          });
        });
      } else {
        return duplicatedQuestion;
      }
    }).then(function(duplicatedQuestion) {
      var MdaTopicsActions, topicRootId;
      topicRootId = duplicatedQuestion.topicRootId;
      if (topicRootId) {
        MdaTopicsActions = require('actions/mda_topics_actions');
        return MdaTopicsActions.duplicateTopicRootAndChapters(duplicatedQuestion).then(function(duplicatedTopicRoot) {
          return Doc.at(projectId, duplicatedQuestion._id).updateAsInstance(function(doc) {
            return doc.setAt('topicRootId')(duplicatedTopicRoot._id);
          });
        });
      } else {
        return duplicatedQuestion;
      }
    }).then(this.actions.duplicateQuestionSuccess)["catch"]((function(_this) {
      return function(err) {
        _this.actions.duplicateQuestionError({
          questionId: questionId,
          err: err
        });
        return _this.actions.pouchSaveError(err);
      };
    })(this));
  };

  QuestionsActions.prototype.toggleOverReview = function(_arg) {
    var isEnabled, questionId;
    questionId = _arg.questionId, isEnabled = _arg.isEnabled;
    this.dispatch({
      questionId: questionId,
      isEnabled: isEnabled
    });
    return mediator.services.overReviewService.toggleModuleState(questionId, isEnabled);
  };

  QuestionsActions.prototype.toggleCadthPublication = function(questionId) {
    return QuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('toggleCadthPublication'));
  };

  QuestionsActions.prototype.getQuestionOverReviewModuleState = function(questionId) {
    return mediator.services.overReviewService.isModuleEnabled(questionId).then((function(_this) {
      return function(isEnabled) {
        return _this.actions.getQuestionOverReviewModuleStateSuccess({
          questionId: questionId,
          isEnabled: isEnabled
        });
      };
    })(this))["catch"](this.actions.getQuestionOverReviewModuleStateError);
  };

  QuestionsActions.prototype.createMdgQuestion = function(questionData) {
    var projectId;
    this.dispatch();
    projectId = mediator.project.id;
    return MdgQuestionDoc.at(projectId).create(_.extend({}, questionData, {
      _id: "" + MDG_QUESTION_PREFIX + (generateGUID())
    })).then((function(_this) {
      return function(createdQuestion) {
        QuestionGroupsActions.updateQuestionPosition({
          questionType: 'regular',
          qId: createdQuestion['_id'],
          orderNumber: null
        })["finally"](function() {
          return _this.actions.createMdgQuestionSuccess(createdQuestion);
        });
        return createdQuestion;
      };
    })(this))["catch"]((function(_this) {
      return function(err) {
        _this.actions.createMdgQuestionError(err);
        return _this.actions.pouchSaveError(err);
      };
    })(this));
  };

  QuestionsActions.prototype.updateMdgQuestion = function(params) {
    var projectId, questionData, questionId;
    this.dispatch(params);
    projectId = mediator.project.id;
    questionId = params.questionId, questionData = params.questionData;
    return MdgQuestionDoc.at(projectId, questionId).update(questionData).then(this.actions.updateMdgQuestionSuccess)["catch"]((function(_this) {
      return function(err) {
        _this.actions.updateMdgQuestionError({
          questionId: questionId,
          err: err
        });
        return _this.actions.pouchSaveError(err);
      };
    })(this));
  };

  return QuestionsActions;

})();

module.exports = alt.createActions(QuestionsActions);
