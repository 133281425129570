var AlgorithmsSidebarView, ReactComponent, SidebarComponent, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

SidebarComponent = require('components/algorithms/sidebar');

mediator = require('mediator');

module.exports = AlgorithmsSidebarView = (function(_super) {
  __extends(AlgorithmsSidebarView, _super);

  function AlgorithmsSidebarView() {
    return AlgorithmsSidebarView.__super__.constructor.apply(this, arguments);
  }

  AlgorithmsSidebarView.prototype.autoRender = true;

  AlgorithmsSidebarView.prototype.initialize = function() {
    AlgorithmsSidebarView.__super__.initialize.apply(this, arguments);
    return this.enable(ReactComponent);
  };

  AlgorithmsSidebarView.prototype.afterRender = function() {
    AlgorithmsSidebarView.__super__.afterRender.apply(this, arguments);
    return this.renderSimpleComponent(SidebarComponent, {}, document.getElementById('right-sidebar-container'));
  };

  AlgorithmsSidebarView.prototype.dispose = function() {
    this.unmountComponent();
    return AlgorithmsSidebarView.__super__.dispose.apply(this, arguments);
  };

  return AlgorithmsSidebarView;

})(View);
