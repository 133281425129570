ReferenceInsertControl = require './reference_insert_control'
referenceNode = require './reference_node'
ReferenceNodeView = require './reference_node_view'

{ createInsertControl } = Epiditor

module.exports = (exportMode = false) ->
  {
    id: 'GdtReferenceInsertExtension'
    schema: {
      nodes: { reference: referenceNode }
    }
    toolbarControls: [
      createInsertControl(ReferenceInsertControl)
    ]
    nodeViews: (portalsAPI) ->
      reference: (node, view, getPos) ->
        new ReferenceNodeView(node, view, getPos, portalsAPI, exportMode)
  }
