CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdActions = require 'actions/etd_actions'
Rating = require 'components/common/rating'
RankingDescription = require 'components/etd/judgements/ranking_description'
Select = require 'components/common/select_custom'
SummaryOfJudgementSectionNameCell = require 'components/etd/soj_section_name_cell'
Translation = require 'components/mixins/translation'
{ useI18n } = require 'lib/react_utils'

QualityIndicatorsSummaryOfJudgements = createReactClass
  displayName: "QualityIndicatorsSummaryOfJudgements"

  propTypes:
    templateId: PropTypes.string.isRequired
    qualityIndicators: PropTypes.instanceOf(Immutable.List)

  getDefaultProps: ->
    qualityIndicators: Immutable.List()

  mixins: [Translation('es:recommendations.table'), CustomRenderMixin]

  _getSelectedOption: (criterion) ->
    options = criterion.get('options').concat(criterion.get('additionalOptions'))
    selectedOption = options.find (option) -> option.get('value') is criterion.get('selectedOption')
    if selectedOption then selectedOption.get('text') else ''

  getImportancesList: ->
    _.map ['low', 'moderate', 'high'], (value) =>
      value: value
      text: @i18n "importance_value_#{value}"

  updateSectionImportance: (sectionId) -> (importance) ->
    EtdActions.changeImportance sectionId, importance, false

  getTags: ->
    { qualityIndicators } = @props
    Immutable.Map qualityIndicators.map (v) -> [v.get('id'), v]

  render: ->
    {
      criterions
      editable
      hiddenSections
      renderMode
      sections
      templateId
      toggleSection
    } = @props

    tags = @getTags()
    tagsCount = tags.size

    isPrintout = renderMode is 'printout'
    importancesList = @getImportancesList()

    <div>
      <table className="summary-of-judgements interventions-rating">
        <thead className="header">
          <tr>
            <th className="criteria blank" key="1"></th>
            {sections.map (section) ->
              <th className="blue-cell" key={section.get('criterionId')}>
                {section.get('name')}
              </th>
            .toList()}
          </tr>
          <tr>
            <th className="criteria blank" key="1">{@i18n 'importance_for_decision'}</th>
            {sections.map (section) =>
              criterionId = section.get('criterionId')
              not isPrintout and <td key={criterionId}>
                <Select
                  className="review-table__importance-for-decision-dropdown"
                  options={importancesList}
                  selected={section.get('importance', '')}
                  onChange={@updateSectionImportance criterionId}
                />
              </td>
            .toList()}
          </tr>
        </thead>
        <tbody>
          {tags.valueSeq().map (tag, idx) ->
            <TagRow
              criterions={criterions}
              index={idx}
              key={tag.get('id')}
              sections={sections}
              tag={tag}
              tagsCount={tagsCount}
            />
          .toList()}
        </tbody>
      </table>
      <RankingDescription type="none" i18n={@i18n} />
    </div>


TagRow = ({ criterions, index, sections, tag, tagsCount }) ->
  <tr>
    <td>{tag.get('name')}</td>
    {sections.map (section, key) ->
      <SectionCell
        key={key}
        section={section}
        criterions={criterions}
        tagsCount={tagsCount}
        tag={tag}
        index={index}
      />
    .toList()}
  </tr>

TagRow.propTypes =
  criterions: PropTypes.instanceOf(Immutable.Map).isRequired
  index: PropTypes.number.isRequired
  sections: PropTypes.instanceOf(Immutable.Map).isRequired
  tag: PropTypes.instanceOf(Immutable.Map).isRequired
  tagsCount: PropTypes.number.isRequired

SectionCell = ({ section, criterions, tagsCount, tag, index }) ->
  i18n = useI18n('es:recommendations.table')
  criterionId = section.get 'criterionId'
  criterion = criterions.get criterionId, Immutable.Map()
  # if rating is 'all' then it means it's the same for each row
  # so we render it only in first row and expanding it with rowSpan
  if criterion.getIn(['rating', 'all']) and index is 0
    <td rowSpan={tagsCount} className="dark-gray-cell bold" key={criterionId}>
      {i18n("ranking_judgement.#{criterion.getIn(['rating', 'all'])}")}
    </td>
  else if not criterion.getIn(['rating', 'all'])
    <td key={criterionId}>
      <Rating
        readOnly
        rating={criterion.getIn(['rating', tag.get('id')], 0)}
        maxRate={3}
      />
    </td>
  else null

SectionCell.propTypes =
  criterions: PropTypes.instanceOf(Immutable.Map).isRequired
  index: PropTypes.number.isRequired
  section: PropTypes.instanceOf(Immutable.Map).isRequired
  tag: PropTypes.instanceOf(Immutable.Map).isRequired
  tagsCount: PropTypes.number.isRequired

module.exports = QualityIndicatorsSummaryOfJudgements
