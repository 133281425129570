var AlgorithmView, AlgorithmsController, AlgorithmsView, GdtRouter, ModuleController, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

GdtRouter = require('router');

AlgorithmsView = require('views/algorithms/algorithms_view');

AlgorithmView = require('views/algorithms/algorithm_view');

mediator = require('mediator');

module.exports = AlgorithmsController = (function(_super) {
  __extends(AlgorithmsController, _super);

  function AlgorithmsController() {
    return AlgorithmsController.__super__.constructor.apply(this, arguments);
  }

  AlgorithmsController.prototype.index = function() {
    return this.view = new AlgorithmsView({
      moduleName: this.moduleName,
      collection: mediator.algorithms
    });
  };

  AlgorithmsController.prototype.algorithm = function(params) {
    return this.view = new AlgorithmView({
      moduleName: this.moduleName,
      algorithmId: params.id
    });
  };

  AlgorithmsController.prototype._viewParams = function(params) {
    return {
      algorithm: mediator.algorithms.get(params.id),
      model: algorithm
    };
  };

  return AlgorithmsController;

})(ModuleController);
