ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
OutcomesTableViewStore = require 'stores/outcomes_table_view_store'
orGradeTable = require 'lib/gdt_tables/definitions/or_grade_table'
{ FixedHeaderTable, Header, Body } = require 'components/common/fixed_header_table'
GdtTable = require 'components/common/gdt_table/gdt_table'
{ array, object, shape, string } = PropTypes

storeConnectors =
  OutcomesTableViewStore: (Store) ->
    outcomeEditMetadata: Store.getOutcomeEditMetadata()

EvidenceTableTab = createReactClass
  displayName: 'EvidenceTableTab'

  propTypes:
    question: shape(
      intervention: string.isRequired
      comparison: string.isRequired
      population: string.isRequired
    ).isRequired
    questionId: string.isRequired
    sources: object.isRequired
    reviewedOutcomes: array

  getTableData: ->
    { reviewedOutcomes, sources } = @props

    reviewedOutcomes.map ({ _id, name, sourcesOutcomesMapping }) ->
      mappedOutcomes = R.values(
        R.mapObjIndexed(
          (sourceOutcomeId, sourceId) ->
            { sourceName, outcomes } = sources[sourceId]
            R.merge { sourceId, source: sourceName }, R.find(
              R.whereEq _id: sourceOutcomeId
              outcomes
            )
          , sourcesOutcomesMapping
        )
      )

      { name, mappedOutcomes }

  render: ->
    { question, viewType, reviewedOutcomes, outcomeEditMetadata, questionId } = @props

    <div className='over-review-evidence-table-tab'>
      <div className='outcomes-container'>
        <GdtTable
          items={@getTableData()}
          table={orGradeTable}
          additionalProps={
            _viewTypeName: 'one_row'
            question: question
            outcomeEditMetadata: outcomeEditMetadata
            questionId: questionId
          }
          tableContainer='.outcomes-container'
          tableClassName='standard-table'
          bodyClassName='one-row'
        />
      </div>
    </div>

module.exports = ConnectStore EvidenceTableTab, OutcomesTableViewStore, storeConnectors
