{ SelectWithCheckboxes } = ReactComponents
ConnectStore = require 'components/enhancers/connect_store'
GlobalExportActions = require 'actions/global_export_actions'
GlobalExportStore = require 'stores/global_export_store'
ItemWithCheckbox = require 'components/common/item_with_checkbox'
{ QUESTIONS_VIEW_TYPE_TO_I18N_KEY } = require 'lib/questions_helper'
Translation = require 'components/mixins/translation'
utils = require 'base/lib/utils'

DOCUMENTS_AND_FORMATS = Immutable.fromJS [
  type: 'evidenceTables'
  formats: ['docx', 'pdf']
,
  type: 'isofs'
  formats: ['link']
,
  type: 'etds'
  formats: ['docx', 'pdf']
,
  type: 'documentSections'
  formats: ['docx', 'pdf']
,
  type: 'presentations'
  formats: ['link', 'pdf']
]

PRESENTATION_AUDIENCES = ['clinicians', 'policymakers', 'patients']

MANAGEMENT_QUESTION_VIEWS = [
  'OneRow', 'SOF', 'ACCP', 'SOF_ACCP', 'SOF_v3'
]

DIAGNOSTIC_QUESTION_VIEWS = [
  'LayerOne', 'LayerTwo', 'LayerOneSof', 'LayerTwoSof'
]

storeConnectors =
  GlobalExportStore: (Store) ->
    selectedDocuments: Store.getSelectedDocuments()
    selectedFormats: Store.getSelectedFormats()
    selectedPresentationAudiences: Store.getSelectedPresentationAudiences()
    selectedDiagnosticQuestionViews: Store.getSelectedDiagnosticQuestionViews()
    selectedManagementQuestionViews: Store.getSelectedManagementQuestionViews()
    isAnyDiagnosticQuestionChosen: Store.isAnyDiagnosticQuestionChosen()
    isAnyManagementQuestionChosen: Store.isAnyManagementQuestionChosen()
    isDocumentSectionsEnabled: Store.isDocumentSectionsEnabled()

PresentationAudiencesSelect = createReactClass
  displayName: 'GlobalExportPresentationAudiencesSelect'
  mixins: [Translation('projects:global_export_dialog.presentation_audiences')]
  propTypes:
    selectedPresentationAudiences: PropTypes.instanceOf(Immutable.List).isRequired
    selectedDocuments: PropTypes.instanceOf(Immutable.Map).isRequired

  render: ->
    { selectedPresentationAudiences, selectedDocuments } = @props

    options = _.map PRESENTATION_AUDIENCES, (audience) =>
      value: audience
      text: @i18n audience

    <SelectWithCheckboxes
      disabled={not selectedDocuments.get('presentations', false)}
      name="presentation_audiences"
      onChange={GlobalExportActions.selectPresentationAudiences}
      options={options}
      selectedOptions={selectedPresentationAudiences.toJS()}
      withSelectAll={false}
      defaultLabel={@i18n 'select'}
    />

QuestionViewsSelect = createReactClass
  displayName: 'GlobalExportQuestionViewsSelect'
  mixins: [Translation('projects:global_export_dialog.question_views')]
  propTypes:
    selectedDiagnosticQuestionViews: PropTypes.instanceOf(Immutable.List).isRequired
    selectedManagementQuestionViews: PropTypes.instanceOf(Immutable.List).isRequired
    isAnyDiagnosticQuestionChosen: PropTypes.bool.isRequired
    isAnyManagementQuestionChosen: PropTypes.bool.isRequired
    selectedDocuments: PropTypes.instanceOf(Immutable.Map).isRequired

  render: ->
    {
      selectedDiagnosticQuestionViews
      selectedManagementQuestionViews
      isAnyDiagnosticQuestionChosen
      isAnyManagementQuestionChosen
      selectedDocuments
    } = @props

    groupedOptions = {}
    selectedOptions = {}

    if isAnyManagementQuestionChosen
      groupedOptions.management =
        caption: @i18n 'management_questions'
        options: _.map MANAGEMENT_QUESTION_VIEWS, (option) =>
          text: @i18n "/es:outcome_view_type.#{QUESTIONS_VIEW_TYPE_TO_I18N_KEY[option]}"
          value: option
      selectedOptions.management = selectedManagementQuestionViews.toJS()

    if isAnyDiagnosticQuestionChosen
      groupedOptions.diagnostic =
        caption: @i18n 'diagnostic_questions'
        options: _.map DIAGNOSTIC_QUESTION_VIEWS, (option) =>
          text: @i18n "/es:outcome_view_type.#{QUESTIONS_VIEW_TYPE_TO_I18N_KEY[option]}"
          value: option
      selectedOptions.diagnostic = selectedDiagnosticQuestionViews.toJS()

    if _.isEmpty groupedOptions
      groupedOptions.selectQuestions =
        caption: @i18n 'select_questions'
        options: []

    <SelectWithCheckboxes
      disabled={not selectedDocuments.get('evidenceTables', false)}
      name="question_views"
      onChange={GlobalExportActions.selectQuestionViews}
      groupedOptions={groupedOptions}
      defaultLabel={@i18n 'select'}
      selectedOptions={selectedOptions}
      withSelectAll={false}
    />

FormatsSelect = createReactClass
  displayName: 'GlobalExportFormatsSelect'
  mixins: [Translation('projects:global_export_dialog.formats')]
  propTypes:
    type: PropTypes.string.isRequired
    formats: PropTypes.instanceOf(Immutable.List).isRequired
    selectedFormats: PropTypes.instanceOf(Immutable.List).isRequired
    selectedDocuments: PropTypes.instanceOf(Immutable.Map).isRequired

  onChangeFormats: (formats) ->
    { type } = @props
    GlobalExportActions.selectFormats type, formats

  render: ->
    { type, formats, selectedFormats, selectedDocuments } = @props

    options = formats.map (format) =>
      value: format
      text: @i18n format

    <SelectWithCheckboxes
      disabled={not selectedDocuments.get(type, false)}
      name={"#{type}_formats"}
      onChange={@onChangeFormats}
      options={options.toJS()}
      selectedOptions={selectedFormats.toJS()}
      withSelectAll
      defaultLabel={@i18n 'select'}
      selectAllLabel={@i18n 'select_all'}
    />

DocumentAndFormats = createReactClass
  displayName: 'GlobalExportDocumentAndFormats'
  mixins: [Translation('projects:global_export_dialog')]
  propTypes:
    isSelected: PropTypes.bool.isRequired
    type: PropTypes.string.isRequired
    formats: PropTypes.instanceOf(Immutable.List).isRequired
    selectedFormats: PropTypes.instanceOf(Immutable.List).isRequired
    selectedDocuments: PropTypes.instanceOf(Immutable.Map).isRequired
    title: PropTypes.string.isRequired
    additionalSelect: PropTypes.node

  getDefaultProps: ->
    additionalSelect: null

  onToggle: ->
    { type } = @props
    GlobalExportActions.selectDocument type

  render: ->
    {
      type
      isSelected
      formats
      selectedFormats
      selectedDocuments
      title
      additionalSelect
    } = @props
    <div>
      <ItemWithCheckbox checked={isSelected} onChange={@onToggle}>
        <div>{title}</div>
      </ItemWithCheckbox>
      <div>{additionalSelect}</div>
      <div>
        {formats.size > 1 and
          <FormatsSelect
            type={type}
            formats={formats}
            selectedFormats={selectedFormats}
            selectedDocuments={selectedDocuments}
          />
        }
      </div>
    </div>

DocumentsAndFormatsList = createReactClass
  displayName: 'GlobalExportDocumentsAndFormatsList'
  mixins: [Translation('projects:global_export_dialog')]
  propTypes:
    selectedDocuments: PropTypes.instanceOf(Immutable.Map).isRequired
    selectedFormats: PropTypes.instanceOf(Immutable.Map).isRequired
    selectedPresentationAudiences: PropTypes.instanceOf(Immutable.List).isRequired
    selectedDiagnosticQuestionViews: PropTypes.instanceOf(Immutable.List).isRequired
    selectedManagementQuestionViews: PropTypes.instanceOf(Immutable.List).isRequired
    isAnyDiagnosticQuestionChosen: PropTypes.bool.isRequired
    isAnyManagementQuestionChosen: PropTypes.bool.isRequired
    isDocumentSectionsEnabled: PropTypes.bool.isRequired

  render: ->
    {
      selectedDocuments
      selectedFormats
      selectedPresentationAudiences
      selectedDiagnosticQuestionViews
      selectedManagementQuestionViews
      isAnyDiagnosticQuestionChosen
      isAnyManagementQuestionChosen
      isDocumentSectionsEnabled
    } = @props
    <div className="global-export-modal__documents_and_formats">
      <div className="documents_and_formats__header">
        <div>{@i18n 'select_documents'}</div>
        <div>{@i18n 'select_types'}</div>
        <div>{@i18n 'select_formats'}</div>
      </div>
      {DOCUMENTS_AND_FORMATS.map (entry) =>
        type = entry.get 'type'
        return null if type is 'documentSections' and not isDocumentSectionsEnabled
        formats = entry.get 'formats'
        selectedFormatsForType = selectedFormats.get type
        isSelected = selectedDocuments.get type
        additionalSelect = switch type
          when 'presentations'
            <PresentationAudiencesSelect
              selectedPresentationAudiences={selectedPresentationAudiences}
              selectedDocuments={selectedDocuments}
            />
          when 'evidenceTables'
            <QuestionViewsSelect
              selectedDiagnosticQuestionViews={selectedDiagnosticQuestionViews}
              selectedManagementQuestionViews={selectedManagementQuestionViews}
              isAnyDiagnosticQuestionChosen={isAnyDiagnosticQuestionChosen}
              isAnyManagementQuestionChosen={isAnyManagementQuestionChosen}
              selectedDocuments={selectedDocuments}
            />
          else
            null
        <DocumentAndFormats
          isSelected={isSelected}
          formats={formats}
          selectedFormats={selectedFormatsForType}
          selectedDocuments={selectedDocuments}
          type={type}
          key={type}
          title={@i18n "document_types.#{utils.toUnderscore type}"}
          additionalSelect={additionalSelect}
        />
      }
    </div>

module.exports = ConnectStore DocumentsAndFormatsList, [GlobalExportStore], storeConnectors
