{ useState, Fragment } = React
{ useCoffeeCallback, useCoffeeEffect, useI18n } = require 'lib/react_utils'
Button = require 'components/common/button'
Rectangle = require './rectangle'

backgroundColors = [
  { colorClassName: 'bg-section-white', sectionColor: '#FFFFFF' }
  { colorClassName: 'bg-section-blue', sectionColor: '#D8EBFA' }
  { colorClassName: 'bg-section-green', sectionColor: '#D8FAD8' }
  { colorClassName: 'bg-section-yellow', sectionColor: '#FCF2C9' }
  { colorClassName: 'bg-section-red', sectionColor: '#FADBD8' }
  { colorClassName: 'bg-section-gray', sectionColor: '#FBFAF8' }
]

SidebarSectionEditor = ({
  currentElement,
  onElementRemove,
  onSectionDataUpdate,
}) ->
  i18n = useI18n('algorithms:sidebar')

  [label, setLabel] = useState(currentElement.data.label ? '')
  [background, setBackground] = useState(currentElement.data.background)
  [positionLock, setPositionLock] = useState(currentElement.data.lockPosition ? false)
  
  handleLabelUpdate = useCoffeeCallback [onSectionDataUpdate, setLabel], (evt) ->
    newLabel = evt.target.value
    onSectionDataUpdate({ label: newLabel })
    setLabel(newLabel)

  handlePositionLockUpdate = useCoffeeCallback [onSectionDataUpdate, setPositionLock], (newLockValue) ->
    onSectionDataUpdate({ lockPosition: newLockValue })
    setPositionLock(newLockValue)
  
  handleSectionBackgroundUpdate =
    useCoffeeCallback [onSectionDataUpdate, background, setBackground], (newBackground) ->
      onSectionDataUpdate({ background: newBackground })
      setBackground(newBackground)

  useCoffeeEffect [currentElement], () ->
    setLabel(currentElement.data.label ? '')
    setBackground(currentElement.data.background)

  <Fragment>
    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('text')}</label>
      <textarea placeholder='Type here' value={label} onChange={handleLabelUpdate} />
    </div>

    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('background')}</label>
      <div className='algorithms-sidebar__color-blocks'>
        {_.map backgroundColors, ({ colorClassName, sectionColor }) ->
          <Rectangle
            key={colorClassName}
            selected={background == sectionColor}
            colorClassName={colorClassName}
            onClick={() -> handleSectionBackgroundUpdate(sectionColor)}
          />
        }
      </div>
    </div>

    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('lock_position')}</label>
      <Button
        className="btn-cancel"
        label={if positionLock then i18n('unlock_position_btn') else i18n('lock_position_btn')}
        onClick={() => handlePositionLockUpdate(!positionLock)}
      />
    </div>

    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('delete')}</label>
      <Button
        className="btn-cancel"
        label={i18n('delete_object')}
        onClick={onElementRemove}
      />
    </div>
  </Fragment>

SidebarSectionEditor.propTypes =
  currentElement: PropTypes.object.isRequired
  onElementRemove: PropTypes.func.isRequired
  onSectionDataUpdate: PropTypes.func.isRequired

module.exports = SidebarSectionEditor
