ConnectStore = require 'components/enhancers/connect_store'
Spinner = require 'components/common/spinner'
DocumentSectionsCommonStore = require 'stores/document_sections_common_store'
DocumentSectionsV2Store = require 'stores/document_sections_v2_store'
ChaptersList = require 'components/document_sections/v2/chapters_list'
Sidebar = require 'components/document_sections/v2/sidebar'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsStore = require 'stores/questions_store'
mediator = require 'mediator'
ReferencesInsertModal = require 'components/etd/references_insert_modal'
ReferencesActions = require 'actions/references_actions'
ReferencesStore = require 'stores/references_store'

DocumentSectionsV2 = createReactClass
  displayName: 'DocumentSectionsV2'

  propTypes:
    rootChaptersOrder: PropTypes.instanceOf(Immutable.List).isRequired
    isInsertModalOpen: PropTypes.bool.isRequired
    initialReferences: PropTypes.instanceOf(Immutable.Map)
    insertReferencesHandler: PropTypes.func.isRequired
    chapters: PropTypes.instanceOf(Immutable.Map).isRequired
    currentDiscussionsFilters: PropTypes.instanceOf(Immutable.Map).isRequired
    isFetchingChapters: PropTypes.bool.isRequired
    isFetchingQuestionGroups: PropTypes.bool.isRequired
    isFetchingQuestions: PropTypes.bool.isRequired
    suggesting: PropTypes.bool.isRequired
    editingDiscussionItems: PropTypes.instanceOf(Immutable.Map).isRequired
    editingDiscussionReplies: PropTypes.instanceOf(Immutable.Map).isRequired
    activeDiscussionItem: PropTypes.oneOfType [
      PropTypes.string
      PropTypes.arrayOf PropTypes.string
    ]
    scrollToSectionContent: PropTypes.shape({
      sectionId: PropTypes.string.isRequired,
      contentPos: PropTypes.number
    })

  render: ->
    {
      activeDiscussionItem,
      activeSidebarTab,
      chapters,
      discussions,
      currentDiscussionsFilters,
      editingDiscussionItems,
      editingDiscussionReplies
      initialReferences,
      insertReferencesHandler
      isFetchingChapters,
      isFetchingQuestionGroups,
      isFetchingQuestions,
      isInsertModalOpen,
      questionGroups,
      questions,
      rootChaptersOrder,
      scrollToSectionContent,
      suggesting,
    } = @props

    if isFetchingChapters || isFetchingQuestionGroups || isFetchingQuestions
      <Spinner />
    else
      <div className="document-sections-v2 h-full overflow-hidden">
        <ChaptersList
          chaptersOrder={rootChaptersOrder}
          chapters={chapters}
          questionGroups={questionGroups}
          questions={questions}
          scrollToSectionContent={scrollToSectionContent}
        />
        <Sidebar
          activeDiscussionItem={activeDiscussionItem}
          activeTab={activeSidebarTab}
          chapters={chapters}
          chaptersOrder={rootChaptersOrder}
          discussions={discussions}
          currentDiscussionsFilters={currentDiscussionsFilters}
          editingDiscussionItems={editingDiscussionItems}
          editingDiscussionReplies={editingDiscussionReplies}
          suggesting={suggesting}
        />
        {if isInsertModalOpen
          <ReferencesInsertModal
            onClose={ReferencesActions.closeInsertModal}
            onInsert={insertReferencesHandler}
            initialReferences={initialReferences}
          />
        }
      </div>

storeConnectors =
  DocumentSectionsV2Store: (Store) ->
    rootChaptersOrder: Store.getRootChaptersOrder()
    chapters: Store.getChapters()
    isFetchingChapters: Store.isFetching()
    discussions: Store.getResultingDiscussions()
    currentDiscussionsFilters: Store.getDiscussionsFilters()
    activeSidebarTab: Store.getActiveSidebarTab()
    activeDiscussionItem: Store.getActiveDiscussionItem()
    suggesting: Store.getSuggesting()
    editingDiscussionItems: Store.getEditingDiscussionItems()
    editingDiscussionReplies: Store.getEditingDiscussionReplies()
    scrollToSectionContent: Store.getScrollToSectionContent()
  QuestionGroupsStore: (Store) ->
    isFetchingQuestionGroups: Store.isFetching()
    questionGroups: Store.getQuestionGroups()
  QuestionsStore: (Store) ->
    isFetchingQuestions: Store.isFetching()
    questions: Store.getQuestions()
  ReferencesStore: (Store) ->
    isInsertModalOpen: Store.isInsertModalOpen()
    insertReferencesHandler: Store.getInsertModalInsertHandler()
    initialReferences: Store.getInitialReferencesForInsert()

module.exports =
  ConnectStore DocumentSectionsV2,
  [
    DocumentSectionsV2Store,
    QuestionGroupsStore,
    QuestionsStore,
    ReferencesStore
  ],
  storeConnectors
