var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "/es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isDiagnostic : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.program(5, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <div class=\"edit-input-block form-group row mt-10\" data-property=\"designStudies\">\n      <div>\n        "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "designStudies", ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.cross_sectional_cohort_type: cross_sectional_cohort_type,\n          ObsStudies.case_control_type_accuracy: case_control_type_accuracy,\n          ObsStudies.cohort_and_case_control_type: cohort_and_case_control_type", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n      </div>\n    </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isPrognostic : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.program(9, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <div class=\"edit-input-block form-group row mt-10\" data-property=\"designStudies\">\n        <div>\n          "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "designStudies", ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.name : stack1), "RandTrials: randomised_trials, ObsStudies: observational_studies", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n        </div>\n        <div class=\"sup\"></div>\n        <div class=\"observational-studies-list\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.name : stack1), "ObsStudies", {"name":"is","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n      </div>\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.prognosticObsStudiesSelection, '            ', 'prognosticObsStudiesSelection', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "      <div class=\"edit-input-block form-group row mt-10\" data-property=\"designStudies\">\n        <div>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "time_to_event", {"name":"is","hash":{},"fn":this.program(10, data),"inverse":this.program(12, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </div>\n        <div class=\"sup\"></div>\n        <div class=\"observational-studies-list\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.name : stack1), "ObsStudies", {"name":"is","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </div>\n        <div class=\"robins-i-toggle\">\n";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "ObsStudies, NonRandStudy", ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.name : stack1), {"name":"inStringArray","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n      </div>\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "designStudies", ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.name : stack1), "RandTrials: randomised_trials, NonRandStudy: non_randomised_studies", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n";
},"12":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "designStudies", ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.name : stack1), "RandTrials: randomised_trials, ObsStudies: observational_studies", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n";
},"14":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.obsStudiesSelection, '            ', 'obsStudiesSelection', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.robinsIToggle, '            ', 'robinsIToggle', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "designStudies", "study_design", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}