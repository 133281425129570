Spinner = require 'components/common/spinner'
LoginForm = require './login_form'
PasswordResetForm = require './password_reset_form'
RegistrationForm = require './registration_form'
SubscriptionsScreen = require './subscriptions_screen'
CopyrightNotice = require './copyright_notice'
LanguageSelector = require './language_selector'
Translation = require 'components/mixins/translation'
Exceptions = require 'lib/exceptions'
mediator = require 'mediator'

AuthenticationScreen = createReactClass
  displayName: 'AuthenticationScreen'

  mixins: [Translation('registration')]

  propTypes:
    service: PropTypes.shape
      login: PropTypes.func.isRequired
      getLocalAccountInformation: PropTypes.func.isRequired
      logout: PropTypes.func.isRequired
    modal: PropTypes.bool.isRequired
    intent: PropTypes.string
    preselection: PropTypes.shape
      licence: PropTypes.string
      term: PropTypes.string
      currency: PropTypes.string

  getInitialState: ->
    if @props.intent is 'register'
      email: ''
      password: ''
      action: 'register'
    else if @props.modal
      email: mediator.user.getEmail()
      password: ''
      action: 'login'
    else
      @props.service.getLocalAccountInformation().then (accounts) =>
        @setState(action: if accounts.length > 0 then 'login' else 'register')
      email: ''
      password: ''
      action: 'loading' # any of: 'loading', 'register', 'login', 'password_reset', 'subscriptions'

  emailChanged: (e) -> @setState(email: e.target.value)
  passwordChanged: (e) -> @setState(password: e.target.value)
  switchAction: (action) -> (e) => @setState({action})

  switchUser: ->
    # for now it will completely logout user, and redirect him to login page
    @props.service.logout().then -> mediator.publish '!reloadApplication'

  onNoSubscription: (error) ->
    @setState(action: 'subscriptions', previousSubscription: error.previousSubscription)

  onLogin: (loginData) ->
    # TODO: good place to launch domain migration
    mediator.publish 'login', loginData.login, loginData.status

  onInit: (message) =>
    if message.action is 'initialization'
      @setState action: 'loading', message: @i18n('db_initialization.start')
    else if @state.message?
      @setState action: 'loading', message: @i18n("db_initialization.#{message}")

  login: ->
    @props.service.login(@state.email, @state.password).then @onLogin, (error) =>
      if error instanceof Exceptions.authentication.no_active_subscription
        @onNoSubscription(error)
      else
        throw error
    , @onInit

  renderAction: ->
    switch @state.action
      when 'loading'
        <form>
          <div className="info">{@state.message}</div>
          <Spinner />
        </form>
      when 'register'
        <RegistrationForm email={@state.email} onEmailChange={@emailChanged}
          password={@state.password} onPasswordChange={@passwordChanged}
          forgotPassword={@switchAction('password_reset')} service={@props.service}
          onLogin={@onLogin} onInit={@onInit} onNoSubscription={@onNoSubscription} />
      when 'login'
        <LoginForm email={@state.email} onEmailChange={@emailChanged} emailDisabled={@props.modal}
          password={@state.password} onPasswordChange={@passwordChanged}
          forgotPassword={@switchAction('password_reset')} service={@props.service}
          onLogin={@onLogin} onInit={@onInit} onNoSubscription={@onNoSubscription} />
      when 'password_reset'
        <PasswordResetForm email={@state.email} onEmailChange={@emailChanged}
          service={@props.service} />
      when 'subscriptions'
        <SubscriptionsScreen subscriptionService={mediator.services.subscription}
          previousSubscription={@state.previousSubscription}
          preselection={@props.preselection}
          onSuccess={@login} onAuthError={@switchUser} />

  renderActionSwitch: ->
    actionSwitch = switch @state.action
      when 'register'
        <button className="login-switch" onClick={@switchAction('login')}>
          {@i18n('already_account')}
        </button>
      when 'login'
        if @props.modal
          <button className="user-switch" onClick={@switchUser}>{@i18n('change_user')}</button>
        else
          <div>
            <div className="new-to-gdt">{@i18n('new_to_gdt')}&nbsp;</div>
            <button className="registration-view-switch" onClick={@switchAction('register')}>
              {@i18n('new_account')}
            </button>
          </div>
      when 'password_reset'
        <button className="back-to-login-switch" onClick={@switchAction('login')}>
          {@i18n('log_in')}
        </button>
    if actionSwitch?
      <div className="authentication-form__action-switch">
        {actionSwitch}
      </div>
    else
      null

  render: ->
    innerDivClassName = classNames 'authentication-form',
      'subscriptions': @state.action is 'subscriptions'

    <div className="authentication-view">
      <div className="authentication">
        <div className={innerDivClassName}>
          {@renderAction()}
          {@renderActionSwitch()}
        </div>
        {not @props.modal && @state.action isnt 'subscriptions' &&
          <div className="footer-language-selector">
            <span>{@i18n 'change_language'}</span>
            <LanguageSelector />
          </div>
        }
      </div>
      {if @props.modal
        <div className="overlay"></div>
      else
        <CopyrightNotice />
      }
    </div>

module.exports = AuthenticationScreen
