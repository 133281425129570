var DbDoc, chapterDocShape, chaptersView,
  __slice = [].slice;

DbDoc = require('lib/db_docs/db_doc');

chapterDocShape = require('lib/db_docs/doc_shapes/document_sections_v2_chapter_doc_shape');

chaptersView = {
  map: function(doc) {
    if (doc.type === 'document_sections_chapter') {
      return emit(doc.mdaTopicId, doc);
    }
  }
};

module.exports = DbDoc(chapterDocShape, {
  dbSetup: {
    dbView: {
      name: 'chaptersV2',
      view: chaptersView
    }
  },
  methods: {
    setProperty: function(propKey, propValue) {
      return this.setAt(propKey)(propValue);
    },
    addSubchapter: function(subchapterId) {
      return this.updateAt('subchapters')(function(subchaptersIds) {
        return subchaptersIds.concat(subchapterId);
      });
    },
    deleteSubchapter: function(deletableSubchapterId) {
      return this.updateAt('subchapters')(function(subchaptersIds) {
        return subchaptersIds.filter(function(subchapterId) {
          return subchapterId !== deletableSubchapterId;
        });
      });
    },
    addSections: function(newSectionsIds, prevSectionId) {
      return this.updateAt('sections')(function(sectionsIds) {
        if (prevSectionId) {
          return __slice.call(sectionsIds.slice(0, sectionsIds.indexOf(prevSectionId) + 1)).concat(__slice.call(newSectionsIds), __slice.call(sectionsIds.slice(sectionsIds.indexOf(prevSectionId) + 1)));
        } else {
          return sectionsIds.concat(newSectionsIds);
        }
      });
    },
    deleteSection: function(deletableSectionId) {
      return this.updateAt('sections')(function(sectionsIds) {
        return sectionsIds.filter(function(sectionId) {
          return sectionId !== deletableSectionId;
        });
      });
    }
  }
});
