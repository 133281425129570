AcademicConfirmationModal = require './academic_confirmation_modal'
Translation = require 'components/mixins/translation'
Exceptions = require 'lib/exceptions'
DateUtils = require 'lib/date_utils'
mediator = require 'mediator'
{ contact_email, license_url, prices } = require 'lib/constants'

SubscriptionsScreen = createReactClass
  displayName: 'SubscriptionsScreen'
  mixins: [Translation('subscriptions')]

  propTypes:
    hideAcademic: PropTypes.bool
    onSuccess: PropTypes.func
    onAuthError: PropTypes.func
    subscriptionService: PropTypes.shape
      checkoutStandard: PropTypes.func.isRequired
    preselection: PropTypes.shape
      licence: PropTypes.string
      term: PropTypes.string
      currency: PropTypes.string

  getInitialState: ->
    if @props.preselection?.licence is 'standard' and
       @props.preselection.term in ['monthly', 'annual'] and
       @props.preselection.currency in ['usd']
      @standardChosen(@props.preselection.term, @props.preselection.currency)()

    academicModalOpen: @props.preselection?.licence is 'academic'
    checkingOut: false

  # TODO: handle switching currency to EUR
  standardChosen: (term, currency) -> =>
    @setState(checkingOut: true)
    @props.subscriptionService.checkoutStandard(term, currency)
    .finally => @setState(checkingOut: false)
    .then (success) =>
      @props.onSuccess() if success
    , @onError

  onError: (error) ->
    if error instanceof Exceptions.authentication.forbidden
      @props.onAuthError()
    else
      mediator.dialogs.fatalError error

  academicChosen: ->
    @setState(academicModalOpen: true)
  hideAcademicModal: ->
    @setState(academicModalOpen: false)

  renderPriceValue: (value) ->
    # background-clip: text is non-standard and is not supported by IE; this is a workaround
    <svg height='50px' width='100%'>
      <defs>
        <linearGradient id='grad1' x1='0%' y1='0%' x2='100%' y2='0%'>
          <stop offset='0%' style={stopColor: '#008dd4'} />
          <stop offset='100%' style={stopColor: '#3e4e97'} />
        </linearGradient>
      </defs>
      <text fill='url(#grad1)' x='50%' y='48px' textAnchor='middle' fontSize='48px'
        fontWeight='bold'>
        {value}
      </text>
    </svg>

  render: ->
    <form className={classNames('licences', 'no-academic': @props.hideAcademic)}>
      <header>
        <h1>{@i18n('choose_licence')}</h1>
        {@props.previousSubscription and
          <h3 className='previous-subscription'>{@i18n('previous_subscription',
            type: @i18n('/account.subscription_information.type_' +
              @props.previousSubscription.subscriptionType)
            expired: DateUtils.format(@props.previousSubscription.expires)
          )}</h3>
        }
      </header>
      <div className='subscription-type standard'>
        <div className='top'>
          <h2>{@i18n('standard.title')}</h2>
          <hr/>
        </div>
        <div className='subscription-content'>
          <div className='col'>
            <div className="flex-grow">
              <div className='bold'>{@i18n('limited_sharing')}</div>
              <div className='bold'>{@i18n('limited_questions')}</div>
            </div>
            <div className="flex-grow">
              <div>{@i18n('standard.includes')}</div>
              <div>{@i18n('standard.email_support')}</div>
            </div>
            <div className="flex-grow">
              <div className='bold'>{@i18n('standard.requirement')}</div>
            </div>
          </div>
          <div className='col'>
            <div className='more-info'>
              <div className="bold">{@i18n('standard.more_info.need_more')}</div>
              <div className="bold">{@i18n('standard.more_info.check_out')}</div>
              <div dangerouslySetInnerHTML={{__html:
                @i18n('standard.more_info.contact_us', {
                  contact_email, license_url
                })
              }} />
            </div>
            <div className='buttons'>
              <div className='disclaimer'>{@i18n('standard.disclaimer')}</div>
              <button type='button' onClick={@standardChosen('annual', 'usd')}
                disabled={@state.checkingOut}
                className='btn btn-alternative btn-block'>
                {@i18n('standard.buy_annual', {
                  yearly_price: prices.yearly,
                  save_yearly: (12 * prices.monthly - prices.yearly)
                })}
              </button>
              <button type='button' onClick={@standardChosen('monthly', 'usd')}
                disabled={@state.checkingOut}
                className='btn btn-block monthly'>
                {@i18n('standard.buy_monthly', { monthly_price: prices.monthly })}
              </button>
            </div>
          </div>
        </div>
      </div>
      {not @props.hideAcademic and
        <div className='subscription-type academic'>
          <div className='subscription-content'>
            <div className='col'>
              <h2>{@i18n('academic.free')}</h2>
              <div className="flex-grow">
                <div className='bold'>{@i18n('limited_sharing')}</div>
                <div className='bold'>{@i18n('limited_questions')}</div>
              </div>

              <div className='includes'>{@i18n('academic.includes')}</div>
            </div>
            <div className='col'>
              <div className='requirement'>{@i18n('academic.requirement')}</div>
              <div key='buttons-academic' className='buttons'>
                <button type='button' onClick={@academicChosen}
                  className='btn btn-alternative btn-block'>
                  {@i18n('academic.choose')}
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      <AcademicConfirmationModal
        subscriptionService={@props.subscriptionService}
        isOpen={@state.academicModalOpen}
        onActivated={@props.onSuccess}
        onError={@onError}
        onClose={@hideAcademicModal} />
    </form>

module.exports = SubscriptionsScreen
