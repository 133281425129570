AdolopmentSummaryOfJudgements = require 'components/etd/adolopment_summary_of_judgements'
AssessmentSection = require 'components/etd/assessment_section'
AssessmentTableHeader = require 'components/etd/assessment_table_header'
AssessmentSectionRowWithVoting = require 'components/etd/assessment_section_row_with_voting_data'
AssessmentCollapseButton = require 'components/etd/panel_voice_etd/assessment_collapse_button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdPart = require 'components/etd/etd_part'
EtdVotingMixin = require 'components/mixins/etd_voting_mixin'
EtdAssessmentMixin = require 'components/mixins/etd_assessment_mixin'
PrintoutColGroup = require 'components/etd/printout_colgroup'
SummaryOfJudgements = require 'components/etd/summary_of_judgements'
TableViewOptions = require 'components/etd/table_view_options'
TogglePanel = require 'components/common/toggle_panel'
Translation = require 'components/mixins/translation'
mediator = require 'mediator'
{ CellWithTitle } = ReactComponents


Assessment = createReactClass
  displayName: 'AssessmentSectionsContainer'

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
    EtdVotingMixin
  ]

  propTypes:
    adolopmentData: PropTypes.instanceOf(Immutable.Map)
    adolopments: PropTypes.instanceOf(Immutable.Map)
    collapsedAssessmentSections: PropTypes.instanceOf(Immutable.Map),
    etdViewSettings: PropTypes.instanceOf(Immutable.Map),
    hiddenSojSections: PropTypes.instanceOf(Immutable.Map)
    qualityIndicators: PropTypes.instanceOf(Immutable.List)
    editable: PropTypes.bool
    templateId: PropTypes.string.isRequired
    type: PropTypes.oneOf(['overarching', 'keyMessages', 'regular', 'qualityIndicators'])

  getDefaultProps: ->
    adolopmentData: Immutable.Map()
    adolopments: Immutable.Map()
    collapsedAssessmentSections: Immutable.Map()
    etdViewSettings: Immutable.Map()
    hiddenSojSections: Immutable.Map()
    qualityIndicators: Immutable.List()
    type: 'regular'
    editable: true
    withSoj: true

  _isVotingTab: -> @_hasVotingStarted() and @props.activeTab is 'votingResults'

  _isConsensus: -> @props.activeTab? and @props.activeTab isnt 'draftJudgement'

  _getAssessmentSections: ->
    {
      activeTab
      additionalConsiderations
      additionalConsiderationsVisible
      adolopmentData
      adolopments
      assessmentSections
      attachments
      collapsedAssessmentSections
      criterions
      criticalOutcomes
      editable
      etdId
      etdViewSettings
      outcomes
      overarchingQuestionData
      qualityIndicators
      questionType
      renderMode
      researchEvidences
      templateId
      type
      withSoj
    } = @props

    isVotingTab = @_isVotingTab()
    activeTab = activeTab

    editable = if editable? then editable else @_inEditMode?()
    assessmentSections.mapEntries ([sectionId, sectionData], index) =>
      expanded = not collapsedAssessmentSections.get(sectionId, false)
      tbodyClassess = classNames 'expanded': expanded
      displayContent = expanded or renderMode is 'printout'

      additionalConsideration = additionalConsiderations.get(
        sectionData.get('additionalConsiderationId'))

      criterion = criterions.get(sectionData.get('criterionId'))
      researchEvidence = researchEvidences.get(sectionData.get('researchEvidenceId'))

      sectionName = sectionData.get('name')

      parentSectionsData = sectionData.get('parentSections', Immutable.List()).map (parentId) -> 
        assessmentSections.get(parentId, Immutable.Map()).merge criterions.get(parentId)

      section = if isVotingTab
        votingProps = @_getVotingPropsForSection sectionId
        <AssessmentSectionRowWithVoting
          activeTab={activeTab}
          additionalConsiderations={additionalConsideration}
          additionalConsiderationsVisible={additionalConsiderationsVisible}
          adolopmentData={adolopmentData.get(sectionId, Immutable.Map())}
          adolopments={adolopments}
          attachments={attachments}
          className={tbodyClassess}
          criterion={criterion}
          criticalOutcomes={criticalOutcomes}
          displayContent={displayContent}
          editable={editable}
          etdId={etdId}
          etdViewSettings={etdViewSettings}
          expanded={expanded}
          index={index + 1}
          key={sectionId}
          outcomes={outcomes}
          questionType={questionType}
          parentSectionsData={parentSectionsData}
          renderMode={renderMode}
          researchEvidence={researchEvidence}
          sectionData={sectionData}
          sectionId={sectionId}
          sectionName={sectionName}
          templateId={templateId}
          {...votingProps}
        />
      else
        <AssessmentSection
          activeTab={activeTab}
          additionalConsiderations={additionalConsideration}
          additionalConsiderationsVisible={additionalConsiderationsVisible}
          adolopmentData={adolopmentData.get(sectionId, Immutable.Map())}
          adolopments={adolopments}
          attachments={attachments}
          criterion={criterion}
          criticalOutcomes={criticalOutcomes}
          displayContent={displayContent}
          editable={editable}
          etdId={etdId}
          etdViewSettings={etdViewSettings}
          expanded={expanded}
          index={index + 1}
          key={sectionId}
          outcomes={outcomes}
          overarchingQuestionData={overarchingQuestionData}
          parentSectionsData={parentSectionsData}
          qualityIndicators={qualityIndicators}
          questionType={questionType}
          renderMode={renderMode}
          researchEvidence={researchEvidence}
          sectionData={sectionData}
          sectionId={sectionId}
          sectionName={sectionName}
          templateId={templateId}
          type={type}
        />

      [sectionId, section]
    .toList()

  render: ->
    {
      activeTab
      additionalConsiderationsVisible
      adolopmentData
      adolopments
      assessmentSections
      collapsedAssessmentSections
      comparison
      conclusionsSections
      criterions
      etdId
      etdViewSettings
      hiddenSojSections
      intervention
      overarchingQuestionData
      qualityIndicators
      questionType
      renderMode
      switchVotingTab
      templateId
      type
      withSoj
    } = @props

    if assessmentSections
      currentTab = @i18n(_.str.underscored activeTab).toLowerCase()
      togglePanelTitle = if @_hasVotingStarted()
        "#{@i18n 'summary_of_judgements'} (#{currentTab})"
      else
        @i18n 'summary_of_judgements'
      <div>
        <EtdPart titleKey='assessment'
          className="assessment-section"
          renderMode={renderMode}
          activeTab={activeTab}
          onTabClick={switchVotingTab}
        >
          {renderMode isnt 'printout' and
            <div className="assessment-toolbar">
              <TableViewOptions
                adolopmentOptions={not adolopments.isEmpty()}
                assessmentSections={assessmentSections}
                conclusionsSections={conclusionsSections}
                etdId={etdId}
                etdViewSettings={etdViewSettings}
              />
              <AssessmentCollapseButton
                assessmentSections={assessmentSections}
                collapsedAssessmentSections={collapsedAssessmentSections}
                etdId={etdId}
              />
            </div>
          }
          <table className="standard-table judgement-table" key="2">
            <PrintoutColGroup
              renderMode={renderMode}
              withAdditionalConsiderations={additionalConsiderationsVisible}
            />
            {@_getAssessmentSections()}
          </table>
        </EtdPart>
        {withSoj and adolopments.isEmpty() and
          <SummaryOfJudgements
            comparison={comparison}
            criterions={criterions}
            editable={@_inEditMode()}
            etdId={etdId}
            hiddenSections={hiddenSojSections}
            intervention={intervention}
            isConsensus={@_isConsensus()}
            qualityIndicators={qualityIndicators}
            overarchingQuestionData={overarchingQuestionData}
            templateId={templateId}
            type={type}
            renderMode={renderMode}
            sections={assessmentSections}
          />
        }
        {withSoj and not adolopments.isEmpty() and
          <AdolopmentSummaryOfJudgements
            adolopmentData={adolopmentData}
            adolopments={adolopments}
            criterions={criterions}
            editable={@_inEditMode()}
            etdId={etdId}
            etdViewSettings={etdViewSettings}
            hiddenSections={hiddenSojSections}
            i18n={@i18n}
            isConsensus={@_isConsensus()}
            renderMode={renderMode}
            sections={assessmentSections}
          />
        }
      </div>
    else
      null

module.exports = Assessment
