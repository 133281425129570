AdolopmentRecommendationDecisionSection =
  require 'components/etd/adolopment_recommendation_decision_section'
AdolopmentSectionSeparator = require 'components/etd/adolopment_section_separator'
ConclusionsSectionTitleRow =  require 'components/etd/conclusions_section_title_row'
ConclusionsTextSection = require 'components/etd/conclusions_text_section'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
RelatedRecommendations = require 'components/etd/related_recommendations'
Translation = require 'components/mixins/translation'
UserProjectDataActions = require 'actions/user_project_data_actions'

{ getConsensusBasedField } = require 'lib/etd_helper'

toggleOriginalSection = (etdId, sectionId) -> ->
  UserProjectDataActions.toggleOriginalDataSection etdId, sectionId

toggleAdolopmentSection = (etdId, sectionId) -> ->
  UserProjectDataActions.toggleAdolopmentDataSection etdId, sectionId


RecommendationDecisionSection = createReactClass

  displayName: "RecommendationDecisionSection"
  mixins: [CustomRenderMixin, Translation('es:recommendations.table')]

  propTypes:
    adolopmentData: PropTypes.instanceOf(Immutable.Map).isRequired
    adolopments: PropTypes.instanceOf(Immutable.Map).isRequired
    contentColSpan: PropTypes.number,
    etdId: PropTypes.string.isRequired,
    isConsensus: PropTypes.bool,
    readOnly: PropTypes.bool
    renderMode: PropTypes.string
    etdViewSettings: PropTypes.instanceOf(Immutable.Map).isRequired
    section: PropTypes.instanceOf(Immutable.Map).isRequired

  render: ->
    # apply 'content' class to all text sectionections. here also checking 'options'
    # property because type of recommendation section has both 'content' and 'options'
    # props, while we shouldn't consider it a 'content' cell section (rather optionsTypeSection)

    {
      adolopmentData
      adolopments
      attachments
      contentColSpan
      editable
      etdId
      etdViewSettings
      highlightToPanelMembers
      isConsensus
      onEditClick
      readOnly
      renderMode
      section
      sectionId
      withAdolopment
    } = @props

    contentCellClass = classNames
      content: section.get('content')? and not section.get 'options'
      highlighted: highlightToPanelMembers

    sectionToBeDisplayedAsCurrent = if adolopments.isEmpty()
      section
    else if withAdolopment
      section
    else
      adolopment = adolopments.last()
      adolopment.getIn ['templateData', 'conclusions', 'sections', sectionId]

    originalSectionCollapsed = etdViewSettings.getIn(
      ['collapsedOriginalSections', sectionId], false)

    adolopmentSectionCollapsed = etdViewSettings.getIn(
      ['collapsedAdolopmentSections', sectionId], false)

    editable = editable and (adolopments.isEmpty() or withAdolopment)

    sectionKey = if not adolopments.isEmpty() and withAdolopment
      'adolopment'
    else
      'original'

    readOnly = if adolopments.isEmpty()
      readOnly
    else if withAdolopment
      readOnly
    else
      true

    <table className="standard-table recommendation-table-static">
      <tbody>
        <ConclusionsSectionTitleRow
          title={section.get('description')}
          colSpan={contentColSpan}
          renderMode={renderMode}
        />
      </tbody>
      {if not adolopments.isEmpty() and withAdolopment
        [
          <tbody key={1}>
            <AdolopmentSectionSeparator
              i18n={@i18n}
              section="original"
              type="conclusions"
              onClick={toggleOriginalSection(etdId, sectionId)}
            />
          </tbody>
          <AdolopmentRecommendationDecisionSection
            adolopments={adolopments}
            attachments={attachments}
            contentCellClass={contentCellClass}
            i18n={@i18n}
            isConsensus={isConsensus}
            key={"#{sectionId}-adolopments"}
            section={section}
            sectionId={sectionId}
          />
          <tbody key={3}>
            <AdolopmentSectionSeparator
              i18n={@i18n}
              section="adolopment"
              type="conclusions"
              onClick={toggleAdolopmentSection(etdId, sectionId)}
            />
          </tbody>
        ]
      }
      {not adolopmentSectionCollapsed and
        <tbody onClick={onEditClick unless readOnly}>
          <tr>
            <td
              className={contentCellClass}
              colSpan={contentColSpan}
            >
              {if sectionToBeDisplayedAsCurrent.has 'content'
                <ConclusionsTextSection
                  isConsensus={isConsensus}
                  attachments={attachments}
                  editable={editable}
                  content={getConsensusBasedField(
                    sectionToBeDisplayedAsCurrent, 'content', isConsensus) or null}
                  cellId={"#{sectionId}#conclusions"}
                  renderMode={renderMode}
                />
              else
                throw new Error 'Unsupported conclusions section'
              }
            </td>
          </tr>
          <tr>
            <td colSpan={contentColSpan}>
              <RelatedRecommendations
                editable={editable}
                renderMode={renderMode}
              />
            </td>
          </tr>
        </tbody>
      }
    </table>


module.exports = RecommendationDecisionSection
